//todo добавить ссылки
export const LINKS = {
  vk: '',
  politics: '/privacy',
  telegram: 'https://t.me/otorgax',
  telegramTag: '@otorgax',
  tariffs: '/tariffs',
  audit1: '/docs/audit1.pdf',
  audit2: '/docs/audit2.pdf',
  agree: '/agree',
  terms: '/terms-of-service-and-tariffs',
};
