import { RobotAction } from 'components/Robot';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

export const RobotLotAction = observer(() => {
  const { filters } = useLotListStore();

  const activeFilters = useMemo(() => {
    const data = {
      floor: {
        range: {
          to: filters.floor.to.value,
          from: filters.floor.from.value,
        },
        options: null,
      },
      amount_of_floors: {
        to: filters.amountFloors.to.value,
        from: filters.amountFloors.from.value,
      },
      object_type: filters.objectType.selectedOptionsIds,
      auction_type: filters.auctionType.selectedOptionsIds,
      square: {
        to: filters.area.valueMax.value,
        from: filters.area.valueMin.value,
      },
      price: {
        to: filters.price.valueMax.value,
        from: filters.price.valueMin.value,
      },
      form_type: filters.formType.selectedOptionsIds,
      auction_organizer: filters.organizer.selectedOptionsIds,
      sections: filters.sections.selectedOptionsIds,
      source: filters.areas.selectedOptionsIds,
      cadastral_number: filters.cadastralNumber.value,
      city: filters.city.selectedOptionsIds,
      region: filters.region.selectedOptionsIds,
      geography: filters.geography.selectedValue.value,
      aggregated_type:
        filters.aggregatedType.selectedValue.value === 'all' ? null : filters.aggregatedType.selectedValue.value,
    };
    return data;
  }, [
    filters.area.valueMax.value,
    filters.area.valueMin.value,
    filters.price.valueMax.value,
    filters.price.valueMin.value,
    filters.formType.selectedOptionsIds,
    filters.auctionType.selectedOptionsIds,
    filters.objectType.selectedOptionsIds,
    filters.formType.selectedOptionsIds,
    filters.organizer.selectedOptionsIds,
    filters.city.selectedOptionsIds,
    filters.region.selectedOptionsIds,
    filters.aggregatedType.selectedValue.value,
    filters.geography.selectedValue.value,
    filters.floor.from.value,
    filters.floor.to.value,
    filters.amountFloors.from.value,
    filters.amountFloors.to.value,
    filters.floorOptions.value,
    filters.sections.selectedOptionsIds,
    filters.cadastralNumber.value,
    filters.areas.selectedOptionsIds,
  ]);

  return <RobotAction filters={activeFilters} />;
});
