import { PageContainer } from 'components/PageContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import s from '../StaticPages.module.scss';
import { Typography } from 'antd';

const CookiesPage = observer(() => {
  return (
    <PageContainer>
      <div className={s.center__text}>
        <Typography.Title level={3}>Политика использования cookie-файлов</Typography.Title>
        <Typography.Title level={3} className={s.title__agree}>
          на сайте https://kupilot.ru
        </Typography.Title>
      </div>

      <div className={s.text__container}>
        <Typography.Text>
          <span className={s.text__padding__left}></span>
          Настоящим я, в соответствии со статьей 9 Федерального закона от 27.07.2006 № 152- ФЗ «О персональных данных»,
          продолжая работу на сайте https://kupilot.ru (далее – Сайт), выражаю согласие ООО «КупиЛот» (далее –
          Компания), на автоматизированную обработку, а именно: сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение (далее – Обработка), моих персональных данных в следующем
          составе: технические данные, которые автоматически передаются устройством, с помощью которого используется
          Сайт, в том числе: технические характеристики устройства, IP-адрес, информация, сохраненная в файлах
          «cookies», информация о браузере, дате и времени доступа к Сайту, длительность пребывания на Сайте, сведения о
          поведении и активности на Сайте в целях улучшения работы Сайта, совершенствования продуктов и услуг Компании,
          а также определения предпочтений пользователей, в том числе с использованием метрической программы
          Яндекс.Метрика.
        </Typography.Text>
        <Typography.Text>
          <span className={s.text__padding__left}></span>В случае отказа от обработки персональных данных метрическими
          программами я проинформирован(а) о необходимости прекратить использование Сайта или отключить файлы «cookies»
          в настройках браузера.
        </Typography.Text>
      </div>
    </PageContainer>
  );
});

export default CookiesPage;
