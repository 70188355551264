import { IReactionDisposer, computed, makeObservable, reaction } from 'mobx';
import qs from 'query-string';

import { apiUrls } from 'config/apiUrls';
import {
  FilterOption,
  LotFiltersResponse,
  LotProfileFiltersResponse,
  LotProfileHistoryFiltersResponse,
} from 'entities/lotFilters';
import { FilterModel } from 'models/FilterModel';
import { LotListCommonFiltersModel } from 'stores/LotListCommonFiltersModel';
import { RouterStore } from 'stores/RootStore/RouterStore';
import { BaseResponse } from 'types/meta';

import { DEFAULT_LIST_TYPE } from './config';
import { LotProfileHistoryQueryParams } from './types';
import { apiCustom } from 'utils/api';
import { ObjectTypeValue } from 'stores/LotListCommonFiltersModel/types';

type LotListProfileFiltersModelParams = {
  params: URLSearchParams;
  routerStore: RouterStore;
};

export class LotListProfileFiltersModel extends LotListCommonFiltersModel<LotProfileHistoryQueryParams> {
  readonly aggregatedType = new FilterModel<FilterOption<string>, ObjectTypeValue<string>>(DEFAULT_LIST_TYPE.id);
  private readonly disposers: IReactionDisposer[] = [];
  private signalAbortController?: AbortController;
  private api = apiCustom;

  constructor({ params, routerStore }: LotListProfileFiltersModelParams) {
    const initParams = LotListProfileFiltersModel.normalizeQueryParams(params);
    super({
      params: initParams,
      routerStore,
    });

    makeObservable(this, {
      isEmptySideFilters: computed,
      isEmptySideFiltersOptions: computed,
    });
    this.disposers.push(
      reaction(
        () => this.aggregatedType.selectedValue.value,
        () => {
          this.setParam('offset', 0);
          this.offsetModel.change(0);
        },
      ),
    );
    const paramsOffset = params.get('offset');
    this.offsetModel.change(paramsOffset ? parseInt(paramsOffset) : 0);
  }

  get isEmptySideFilters(): boolean {
    // TODO после добавления фильтра по субъекту — добавить условие сюда
    return this.auctionType.isEmpty && this.status.isEmpty && this.formType.isEmpty;
  }

  get isEmptySideFiltersOptions(): boolean {
    // TODO после добавления фильтра по субъекту — добавить условие сюда
    return this.auctionType.isEmptyOptions && this.status.isEmptyOptions && this.formType.isEmptyOptions;
  }

  setListType = (value: string): void => {
    this.aggregatedType.selectedValue.change(value);
    this.clearSideFilters();
  };

  setSideFilters = (): void => {
    this.setParams({
      status: this.status.selectedValue.value,
      form_type: this.formType.selectedValue.value,
      aggregated_type: this.aggregatedType.selectedValue.value || 'all',
    });
    this.resetOffset();
  };

  clearSideFilters = (): void => {
    this.auctionType.selectedValue.change([]);
    this.status.selectedValue.change([]);
    this.formType.selectedValue.change([]);
    this.objectType.selectedValue.change([]);
    this.offsetModel.change(0);
    this.setSideFilters();
  };

  init(): void {
    this.fetchProfileFiltersOptions();
  }

  protected async fetchProfileFiltersOptions(): Promise<BaseResponse> {
    let request = (this.signalAbortController = new AbortController());
    if (this.loadingStage.isLoading && this.signalAbortController) {
      this.signalAbortController.abort();
    }

    this.loadingStage.loading();

    const response = await this.api<LotProfileHistoryFiltersResponse>({
      method: 'GET',
      url: apiUrls.lotListFilters,
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this.setFilterOptions(response.data.filters);
    this.loadingStage.success();

    return {
      isError: false,
    };
  }

  protected setFilterOptions(filters: LotProfileHistoryFiltersResponse['filters']): void {
    const aggregatedTypeOptions: { id: string; title: string }[] = [
      DEFAULT_LIST_TYPE,
      ...filters.aggregated_type.map((option) => ({
        id: option.slug || option.title,
        title: option.title,
      })),
    ];

    super.setFilterOptions(filters);
    this.aggregatedType.setOptions(aggregatedTypeOptions, this.params.aggregated_type ?? DEFAULT_LIST_TYPE.id);
  }

  static normalizeQueryParams(params: URLSearchParams): LotProfileHistoryQueryParams {
    const paramsObj = qs.parse(params.toString(), {
      parseNumbers: true,
    }) as unknown as Partial<LotProfileHistoryQueryParams>;

    const normalizedCommonParams = LotListCommonFiltersModel.normalizeCommonQueryParams(paramsObj);

    return {
      ...normalizedCommonParams,
      aggregated_type: paramsObj.aggregated_type ? paramsObj.aggregated_type : DEFAULT_LIST_TYPE.id,
    };
  }

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
