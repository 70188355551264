import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FavoriteButton } from 'components/favoriteButtons/FavoriteButton';
import SubscribeButton from 'components/subscribeButtons/SubscribeButton';
import { ElementId } from 'config/ids';
import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';

import s from './Footer.module.scss';
import { ViewsCount } from 'components/ViewsCount';

type FooterProps = {
  viewsCount: number | null;
  dateAdded: string;
  className?: string;
};

const Footer = ({ dateAdded, className, viewsCount }: FooterProps): React.ReactElement<FooterProps> => {
  return (
    <div className={cn(s.footer, className)}>
      <ViewsCount className={s['header__views-count']} viewsCount={viewsCount ?? 0} />
      <Typography.Text className={s['footer__date']}>{dateAdded}</Typography.Text>
    </div>
  );
};

export default observer(Footer);
