import { TabsProps } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';

import { FilterOption } from 'entities/lotFilters';
import { ValueModel } from 'models/ValueModel';
import { Validator } from 'types/validator';

export class FilterModel<O extends FilterOption<string | number>, V> {
  options: O[];
  readonly selectedValue: ValueModel<V>;

  constructor(value: V, options: O[] = [], validators: Validator<V, any>[] = []) {
    makeObservable(this, {
      options: observable.ref,
      tabsOptions: computed,
      setOptions: action,
    });

    this.options = options;
    this.selectedValue = new ValueModel<V>(value, validators);
  }

  get tabsOptions(): TabsProps['items'] {
    return this.options.map((option) => ({
      key: String(option.id),
      label: option.title,
    }));
  }

  setOptions = (options: O[], initValue?: V) => {
    this.options = options;

    if (
      initValue !== undefined &&
      initValue !== null &&
      options.find((option) => option.id === initValue) !== undefined
    ) {
      this.selectedValue.change(initValue);
    }
  };
}
