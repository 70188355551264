import { observer, useLocalStore } from 'mobx-react-lite';
import * as React from 'react';

import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';

import { FinalSuccessModal } from './FinalSuccessModal';
import { FormModal } from './FormModal';
import { BugReportFormStore } from 'stores/BugReportFormStore';
import { FinalErrorModal } from './FinalErrorModal';

type BugReportModalProps = {
  opened: boolean;
  close: () => void;
  className?: string;
};

const BugReportModal = ({ opened, close, className }: BugReportModalProps): React.ReactElement<BugReportModalProps> => {
  const rootStore = useRootStore();
  const supportFormStore = useLocalStore(() => new BugReportFormStore({ rootStore }));

  const { fields, submitStage, submitForm } = supportFormStore;
  const { name, email, description, hasErrors, isValidateOnChange, maxDescriptionLength, file } = fields;

  const {
    open: openFinalSuccessModal,
    close: closeFinalSuccessModal,
    opened: openedFinalSuccessModal,
  } = useToggleState();
  const { open: openFinaErrorModal, close: closeFinalErrorModal, opened: openedFinalErrorModal } = useToggleState();

  const handleFormSubmit = React.useCallback(async () => {
    const response = await submitForm();

    if (!response.isError) {
      close();
      openFinalSuccessModal();
      fields.resetAfterSubmission();
    } else {
      close();
      openFinaErrorModal();
      fields.resetAfterSubmission();
    }
  }, [close, openFinalSuccessModal, fields, openFinaErrorModal]);

  return (
    <div className={className}>
      <FormModal
        opened={opened}
        close={close}
        onSubmit={handleFormSubmit}
        name={name}
        file={file}
        email={email}
        description={description}
        maxDescriptionLength={maxDescriptionLength}
        submitStage={submitStage}
        hasErrors={hasErrors}
        isValidateOnChange={isValidateOnChange}
      />
      <FinalSuccessModal opened={openedFinalSuccessModal} close={closeFinalSuccessModal} />
      <FinalErrorModal opened={openedFinalErrorModal} close={closeFinalErrorModal} />
    </div>
  );
};

export default observer(BugReportModal);
