import React from 'react';
import { ReactNode } from 'react';
import s from './LayoutGridAside.module.scss';

export const LayoutGridAside = ({
  aside,
  children,
  clxs,
}: {
  children: ReactNode;
  aside: ReactNode;
  clxs?: { main?: string; aside?: string };
}) => {
  return (
    <div className={s.content__grid}>
      <aside className={`${s.aside} ${clxs?.aside || ''}`}>{aside}</aside>
      <main className={clxs?.main || ''}>{children}</main>
    </div>
  );
};
