import { Menu } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useMenuKeys } from 'hooks/useMenuKeys';
import { useRootStore } from 'stores/RootStore';

import { MENU_ITEMS } from './config';

import s from './HeaderMenu.module.scss';

type HeaderMenuProps = {
  className?: string;
};

const HeaderMenu = ({ className }: HeaderMenuProps): React.ReactElement<HeaderMenuProps> => {
  const { authStore, userStore } = useRootStore();
  const selectedKeys = useMenuKeys();

  const handleMenuClick = (e: React.MouseEvent, item: (typeof MENU_ITEMS)[number]) => {
    if (item.isAuthRequired && !userStore.authorized) {
      e.preventDefault();
      e.stopPropagation();
      authStore.popupController.open();
      authStore.mode.change('login');
    }
  };

  const items = MENU_ITEMS.map((item) => ({
    key: item.url,
    label: (
      <Link to={item.url} onClick={(e) => handleMenuClick(e, item)}>
        {item.title}
      </Link>
    ),
  }));

  return (
    <Menu className={cn(s['header-menu'], className)} selectedKeys={selectedKeys} mode="horizontal" items={items} />
  );
};

export default React.memo(HeaderMenu);
