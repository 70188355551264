import { Collapse, CollapseProps, Space, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Wrapper } from 'components/Wrapper';
import { ElementId } from 'config/ids';
import { useLotPageStore } from 'stores/LotPageStore';
import { useRootStore } from 'stores/RootStore';

import CommonSection from './CommonSection';
import DocsSection from './DocsSection';
import Navigation from './Navigation';
import PropertySection from './PropertySection';

import s from './CommonInfo.module.scss';

type CommonInfoProps = {
  className?: string;
};

const CommonInfo: React.FC<CommonInfoProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { onboardingStore } = useRootStore();
  const refOne = React.useRef<null | HTMLDivElement>(null);
  const refTwo = React.useRef<null | HTMLDivElement>(null);
  const refThree = React.useRef<null | HTMLDivElement>(null);
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Общие сведения',
      children: <CommonSection lot={lot.value} id={ElementId.lotPageCommonMobile} />,
    },
    {
      key: '2',
      label: 'Характеристики лота',
      children: <PropertySection lot={lot.value} id={ElementId.lotPagePropertyCharacteristicsMobile} />,
    },
  ];

  if (lot.value.documents.length > 0) {
    items.push({
      key: '3',
      label: 'Документы лота',
      children: <DocsSection lot={lot.value} id={ElementId.lotPageDocsMobile} />,
    });
  }

  // Для онбординга необходимо раскрыть секцию со сведениями о лоте
  const defaultActiveKey = !onboardingStore.isOnboardingPassedModel.value ? ['1'] : undefined;

  const onScroll = React.useCallback(
    (id: ElementId) => {
      switch (id) {
        case ElementId.lotPageCommonDesktop:
          if (refOne?.current) {
            refOne.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
          break;
        case ElementId.lotPagePropertyCharacteristicsDesktop:
          if (refTwo?.current) {
            refTwo.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }
          break;
        case ElementId.lotPageDocsDesktop:
          if (refThree?.current) {
            refThree.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }
          break;
      }
    },
    [lot.value.id],
  );

  return (
    <Wrapper id={ElementId.lotPage} className={cn(className, s.info)}>
      <Navigation onScroll={onScroll} />
      <Space className={s.info__content} direction="vertical" size={0}>
        <div ref={refOne}>
          <CommonSection lot={lot.value} id={ElementId.lotPageCommonDesktop} />
          <div style={{ paddingTop: 70 }}></div>
        </div>
        <div>
          <PropertySection lot={lot.value} id={ElementId.lotPagePropertyCharacteristicsDesktop} />
          <div style={{ paddingTop: 70 }} ref={refTwo}></div>
        </div>
        <div>
          <DocsSection lot={lot.value} id={ElementId.lotPageDocsDesktop} />
          <div ref={refThree} style={{ paddingTop: 70 }}></div>
        </div>
      </Space>
      <div className={s.collapse__wrap}>
        <Typography.Title className={s.collapse__title} level={3}>
          Сведения о лоте
        </Typography.Title>
        <Collapse
          className={s['info__mobile-content']}
          defaultActiveKey={defaultActiveKey}
          items={items}
          bordered={false}
        />
      </div>
    </Wrapper>
  );
};

export default observer(CommonInfo);
