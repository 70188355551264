import React from 'react';

import s from './PremiumAdvantagesGrid.module.scss';

interface PremiumAdvantagesGridProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
}

const PremiumAdvantagesGrid = ({
  title,
  children,
}: PremiumAdvantagesGridProps): React.ReactElement<PremiumAdvantagesGridProps> => {
  return (
    <div className={s.advantages__grid}>
      <h2 className={s.advantages__grid__title}>{title}</h2>
      <div className={s.advantages__grid__content}>
        {React.Children.map(children, (child, idx) => {
          return {
            ...child,
            props: {
              ...child.props,
              className: child.props.className + ' ' + s[`advantages__grid__item_${idx}`],
            },
          };
        })}
      </div>
    </div>
  );
};

export default React.memo(PremiumAdvantagesGrid);
