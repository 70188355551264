import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, isCancel, Method } from 'axios';
import query, { StringifyOptions } from 'query-string';

import { ApiCallConfig, ApiCallErrorRaw, ApiCallResponse, ApiCallResponseRaw, BaseResponse } from 'types/meta';

export const stringifyParams = (params: Record<string, any>, options: StringifyOptions = {}): string => {
  return query.stringify(params, {
    arrayFormat: 'none',
    skipEmptyString: true,
    skipNull: true,
    ...options,
  });
};

export const callApi = async <R, E>(
  url: string,
  method: Method = 'get',
  config: AxiosRequestConfig = {},
): Promise<BaseResponse<AxiosResponse<R>, AxiosError<E>>> => {
  try {
    const response = await axios<R>({
      method,
      url,
      paramsSerializer: {
        serialize: (params) => {
          return stringifyParams(params, { arrayFormat: 'comma' });
        },
      },
      ...config,
    });

    return {
      isError: false,
      data: response,
    };
  } catch (error) {
    return {
      isError: true,
      data: error as AxiosError<E>,
    };
  }
};

export const configApiCall = (
  methodType: Method,
  data: Record<string, any>,
  config: AxiosRequestConfig,
  multipartFormData: boolean,
): AxiosRequestConfig => {
  config.headers = config.headers || {};

  if (multipartFormData) {
    config.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });

    data = formData;
  }

  if (methodType === 'GET' || methodType === 'get') {
    config.params = data;
  } else {
    if (config.data === null || config.data === undefined) {
      config.data = data;
    }
  }

  return config;
};

export const api = async <
  ResponseData extends Record<string, any> = Record<string, any>,
  ErrorCode extends string = string,
  ErrorData extends Record<string, any> = Record<string, any>,
>({
  url,
  method = 'get',
  data = {},
  config = {},
  multipartFormData = false,
  dangerouslyUsingMock,
}: ApiCallConfig<ResponseData>): Promise<ApiCallResponse<ResponseData, ErrorCode, ErrorData>> => {
  if (dangerouslyUsingMock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          isError: false,
          status: 200,
          data: dangerouslyUsingMock.data,
          rawData: dangerouslyUsingMock.data,
        });
      }, dangerouslyUsingMock.timeout || 300);
    });
  }

  const actualConfig = configApiCall(method, data, config, multipartFormData);

  const response = await callApi<ApiCallResponseRaw<ResponseData>, ApiCallErrorRaw<ErrorCode, ErrorData>>(
    url,
    method,
    actualConfig,
  );

  if (!response.isError) {
    return {
      isError: false,
      status: response.data.status,
      data: response.data.data.data,
      rawData: response.data.data,
    };
  }

  return {
    isError: true,
    isCancelled: isCancel(response.data),
    data: response.data.response
      ? {
          ...response.data.response.data,
          responseCode: response.data.response.status,
        }
      : null,
  };
};

export const apiCustom = async <
  ResponseData extends Record<string, any> = Record<string, any>,
  ErrorCode extends string = string,
  ErrorData extends Record<string, any> = Record<string, any>,
>({
  url,
  method = 'get',
  data = {},
  config = {},
  multipartFormData = false,
  dangerouslyUsingMock,
}: ApiCallConfig<ResponseData>): Promise<ApiCallResponse<ResponseData, ErrorCode, ErrorData>> => {
  if (dangerouslyUsingMock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          isError: false,
          status: 200,
          data: dangerouslyUsingMock.data,
          rawData: dangerouslyUsingMock.data,
        });
      }, dangerouslyUsingMock.timeout || 300);
    });
  }

  const actualConfig = configApiCall(method, data, config, multipartFormData);

  const response = await callApi<ResponseData, ApiCallErrorRaw<ErrorCode, ErrorData>>(url, method, actualConfig);

  if (!response.isError) {
    return {
      isError: false,
      status: response.data.status,
      data: response.data.data,
      rawData: response.data.data,
    };
  }

  return {
    isError: true,
    isCancelled: isCancel(response.data),
    data: response.data.response
      ? {
          ...response.data.response.data,
          responseCode: response.data.response.status,
        }
      : null,
  };
};

export const apiErrorToString = <ErrorData extends Record<string, any> = Record<string, any>>(
  error: ErrorData | null,
): string => {
  if (error === null) {
    return 'Request error unknown';
  }

  let result = '';
  const err = { ...error };
  if ('responseCode' in err) {
    delete err['responseCode'];
  }

  Object.values(err).forEach((item) => {
    if (typeof item === 'string') {
      result += item + '. ';
    } else if (Array.isArray(item)) {
      result += item.join('. ') + '. ';
    } else {
      result += JSON.stringify(item);
    }
  });

  return result;
};
