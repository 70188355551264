import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useCallback, ReactElement, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Wrapper } from 'components/Wrapper';
import LogoIcon from 'components/icons/Logo';
import NotificationIcon from 'components/icons/NotificationIcon';
import { ProfileNotificationMenu } from 'components/profile/ProfileNotification/ProfileNotification';
import { RouteFullPath, routerUrls } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import { useWidth } from 'hooks/useWidth';
import { useRootStore, useThemeStore } from 'stores/RootStore/context';

import HeaderDropdownMenu from './HeaderDropdown/HeaderDropdown';
import { HeaderMenu } from './HeaderMenu';
import { HeaderProfile } from './HeaderProfile';

import s from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

const Header = ({ className }: HeaderProps): ReactElement<HeaderProps> => {
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const scrollPrev = useRef(0);

  const { notificationStore, userStore, authStore, analyticsStore } = useRootStore();
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const { width = window.innerWidth } = useWidth();

  const handleLoginButtonClick = useCallback(() => {
    authStore.popupController.open();
    authStore.mode.change('login');
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const component = useMemo(() => {
    return userStore.authorized ? (
      <ProfileNotificationMenu
        menu
        onClick={() => {
          navigate(RouteFullPath.notification);
        }}
      />
    ) : (
      <span onClick={handleLoginButtonClick} role="button" className={s.header__notification}>
        <NotificationIcon />
      </span>
    );
  }, [notificationStore.currentTotalItems, width]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollCurrent = window.scrollY;
      if (scrollCurrent > scrollPrev.current && scrollCurrent > 0) {
        setIsMenuHidden(true);
      } else {
        setIsMenuHidden(false);
      }
      scrollPrev.current = scrollCurrent;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={cn(s.header, className, theme.value === 'dark' && s.header__dark, isMenuHidden && s.header__hide)}>
      <Wrapper className={s.header__inner}>
        <Link className={s.header__logo} to={routerUrls.lotsList.create()} aria-label="КупиЛот">
          <LogoIcon />
        </Link>
        <HeaderMenu className={s.header__menu} />
        <div className={s.header__menu__mobile}>
          {component}
          <HeaderDropdownMenu />
        </div>
        {width > 768 && <HeaderProfile className={s.header__menu__hide__mobile} />}
      </Wrapper>
    </div>
  );
};

export default observer(Header);
