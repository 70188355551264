import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { RangeFilter } from 'components/RangeFilter';
import { useLotListProfileRobotStore } from 'stores/LotListProfileRobotStore/context';

type AreaProps = {
  className?: string;
};

const Area = ({ className }: AreaProps): React.ReactElement<AreaProps> => {
  const { filters } = useLotListProfileRobotStore();

  return (
    <BaseInput className={className} title="Площадь">
      <RangeFilter
        from={filters.area.from.value}
        to={filters.area.to.value}
        valueMin={filters.area.valueMin.value}
        valueMax={filters.area.valueMax.value}
        onChangeMin={filters.area.valueMin.change}
        onChangeMax={filters.area.valueMax.change}
        placeholderPostfix="м²"
      />
    </BaseInput>
  );
};

export default observer(Area);
