import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ElementId } from 'config/ids';
import { useLotPageStore } from 'stores/LotPageStore';

type LotImagesCarouselProps = {
  className?: string;
  withoutThumbnails?: boolean;
};

const LotImagesCarousel: React.FC<LotImagesCarouselProps> = ({ className, withoutThumbnails }) => {
  const { lot } = useLotPageStore();

  return (
    <ImgCarousel
      id={ElementId.lotPageImageCarouselDesktop}
      className={className}
      images={lot.value.images || []}
      imgSize={CarouselImageSize.l}
      withoutThumbnails={withoutThumbnails}
    />
  );
};

export default React.memo(LotImagesCarousel);
