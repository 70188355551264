import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Sort as SortBase } from 'components/Sort';
import { useLotListProfileHistoryStore } from 'stores/LotListProfileHistoryStore/context';

type SortProps = {
  className?: string;
};

const Sort = ({ className }: SortProps): React.ReactElement<SortProps> | null => {
  const store = useLotListProfileHistoryStore();
  const { filters } = store;
  const { options, selectedValue } = filters.sortOption;

  return (
    <SortBase
      className={className}
      options={options}
      selected={selectedValue.value}
      sortDirection={filters.sortDirection.value}
      onClick={filters.setSortValue}
    />
  );
};

export default observer(Sort);
