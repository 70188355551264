import * as React from 'react';

type CollapsedType = {
  open: boolean;
  onToggle: (value: boolean) => void;
};

const CollapsedContext = React.createContext<CollapsedType | null>(null);

export const CollapsedProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <CollapsedContext.Provider
      value={{
        open,
        onToggle: (value) => setOpen(value),
      }}
    >
      {children}
    </CollapsedContext.Provider>
  );
};

export const useCollapsed = () => {
  const context = React.useContext(CollapsedContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
