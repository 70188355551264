import { action, computed, makeObservable, observable } from 'mobx';
import { ValueModel } from 'models/ValueModel';
import { numberMinMaxValidator, stringLengthValidator, stringMaskValidator } from 'utils/validator';

export class ProfileSettingsCardBankModel {
  readonly number: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 16, maxLength: 16, replace: ' ' }),
    stringMaskValidator(),
  ]);
  readonly name: ValueModel<string> = new ValueModel<string>('');
  readonly month: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 2, maxLength: 2 }),
    stringMaskValidator(),
    numberMinMaxValidator({
      min: 1,
      max: 12,
      printValue: true,
    }),
  ]);
  readonly year: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 2, maxLength: 2 }),
    stringMaskValidator(),
  ]);
  readonly cvs: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 3, maxLength: 3 }),
    stringMaskValidator(),
  ]);

  isValidateOnChange: boolean = false;

  constructor() {
    makeObservable(this, {
      isValidateOnChange: observable,

      hasErrors: computed,

      validate: action.bound,
      setIsValidateOnChange: action.bound,
    });
  }

  get hasErrors(): boolean {
    return this.number.isError || this.cvs.isError || this.month.isError || this.year.isError;
  }

  validate(): boolean {
    const numberError = this.number.validate();
    const cvsError = this.cvs.validate();
    const monthError = this.month.validate();
    const yearError = this.year.validate();
    return numberError || cvsError || monthError || yearError;
  }

  setIsValidateOnChange(value: boolean): void {
    this.isValidateOnChange = value;
  }

  resetFull(): void {
    this.year.reset();
    this.month.reset();
    this.number.reset();
    this.name.reset();
    this.cvs.reset();
  }
}
