import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProfileListType } from 'entities/lotFilters';
import { DEFAULT_EMPTY_TEXT } from 'pages/LotsListPage/LotsList/LotsList';
import { LotsList } from '../LotsList';
import { emptyListPlaceholderOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { Pagination, Switch } from 'antd';
import s from './ListWithFilters.module.scss';
import { getCurrentPage } from 'utils/page';
import { useLotListProfileRobotStore } from 'stores/LotListProfileRobotStore/context';
import { Collapsed } from 'components/Сollapsed/Сollapsed';
import { useCollapsed } from 'components/Сollapsed/Сollapsed.context';
import { SideFilters } from '../SideFilters';
import { ProfileWithFiltersRobot } from 'components/profile/ProfileWithFiltersRobot';

type ListProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const RobotList = ({ className }: ListProps): React.ReactElement<ListProps> => {
  const refWrap = React.useRef<HTMLDivElement | null>(null);
  const { lots, filters } = useLotListProfileRobotStore();
  const isEmpty = lots.isEmpty && filters.isEmptySideFilters && !lots.loadingStage.isLoading;
  const emptyText = isEmpty ? emptyListPlaceholderOptions[ProfileListType.favorites] : DEFAULT_EMPTY_TEXT;
  const { onToggle, open } = useCollapsed();
  return (
    <div>
      <Collapsed
        header={
          <div className={s.row}>
            <ProfileWithFiltersRobot className={s.colLeft} />
            <div className={s.colRight} role="button" onClick={() => onToggle(!open)}>
              Фильтр
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={open ? s.active : s.unactive}
              >
                <path
                  d="M12.8123 3H11.6405C11.5608 3 11.4858 3.03906 11.4389 3.10313L6.99984 9.22188L2.56077 3.10313C2.5139 3.03906 2.4389 3 2.35921 3H1.18734C1.08577 3 1.0264 3.11563 1.08577 3.19844L6.59515 10.7937C6.79515 11.0687 7.20452 11.0687 7.40296 10.7937L12.9123 3.19844C12.9733 3.11563 12.9139 3 12.8123 3Z"
                  fill="black"
                  fillOpacity="0.25"
                />
              </svg>
            </div>
          </div>
        }
      >
        <SideFilters onFiltersClose={() => onToggle(false)} />
      </Collapsed>
      <div className={s.actions}>
        <div className={s.swith}>
          <span className={s.swith__label}>Все</span>
          <Switch defaultChecked onChange={() => {}} />
          <span className={s.swith__label}>Только новые</span>
        </div>
      </div>

      <LotsList lots={lots} emptyText={emptyText} />
    </div>
  );
};

export default observer(RobotList);
