import { apiUrls } from 'config/apiUrls';
import { IReactionDisposer } from 'mobx';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { LocalStore } from 'stores/LocalStore';
import { apiCustom } from 'utils/api';

export class ServicesStore extends LocalStore {
  private api = apiCustom;
  private readonly disposers: IReactionDisposer[] = [];
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  constructor() {
    super();
  }

  sendOrder = async (values: {
    cost: string;
    document?: string | null;
    user: number;
    service: number;
    user_tariff: number;
    lot: number | null;
    slug: string;
  }): Promise<boolean> => {
    try {
      this.loadingStage.loading();
      const response = await this.api<object>({
        url: `${apiUrls.additionalServicesRequests}`,
        method: 'POST',
        data: {
          status: 'received',
          ...values,
        },
      });

      if (response.isError) {
        throw new Error('Req Error');
      }
      this.loadingStage.success();
      return true;
    } catch (error) {
      this.loadingStage.error();
      return false;
    }
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
