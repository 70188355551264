import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ListWithFilters as ListWithFiltersBase } from 'components/ListWithFilters';
import { ProfileListType } from 'entities/lotFilters';
import { DEFAULT_EMPTY_TEXT } from 'pages/LotsListPage/LotsList/LotsList';
import { LotsList } from 'pages/Profile/LotsList';
import { emptyListPlaceholderOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

import { SideFilters } from '../../SideFilters';
import { Sort } from '../../Sort';

type BidsListProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const BidsList = ({ isFiltersCollapsed, className }: BidsListProps): React.ReactElement<BidsListProps> => {
  const { bids, filters } = useLotListProfileStore();
  const isEmpty = bids.isEmpty && filters.isEmptySideFilters && !bids.loadingStage.isLoading;
  const emptyText = isEmpty ? emptyListPlaceholderOptions[ProfileListType.bids] : DEFAULT_EMPTY_TEXT;

  return (
    <ListWithFiltersBase
      className={className}
      SideFilters={SideFilters}
      sortComponent={<Sort />}
      listComponent={<LotsList lots={bids} emptyText={emptyText} />}
      isListEmpty={bids.isEmpty}
      isListTotalEmpty={bids.totalPure.value === 0 && !bids.isInitialLoading.value}
      isFilterOptionsEmpty={filters.isEmptySideFiltersOptions}
      isFiltersCollapsed={isFiltersCollapsed}
      isProfile
    />
  );
};

export default observer(BidsList);
