import React from 'react';
import cn from 'classnames';
import { PageContainer } from 'components/PageContainer';
import { observer } from 'mobx-react-lite';
import bannerImg from 'assets/services/bn-escort.png';
import s from './EscortPage.module.scss';
import { BannerPage } from 'pages/ServicesInfoPage/components/Banner/BannerPage';
import { SliderStep } from 'pages/ServicesInfoPage/components/SliderStep/SliderStep';
import { Typography } from 'antd';
import { CardStep } from 'pages/ServicesInfoPage/components/CardStep/CardStep';
import img1 from 'assets/services/sv1.png';
import img2 from 'assets/services/sv2.png';
import img3 from 'assets/services/sv3.png';
import img4 from 'assets/services/sv4.png';

import { FaqItem } from 'pages/ServicesInfoPage/components/FaqItem/FaqItem';
import { ServicesStoreProvider } from 'stores/ServicesStore/context';
import { routerUrls } from 'config/routes';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ServicesSlugStatic } from 'entities/services/services';

const BREAKPOINTS = {
  0: {
    spaceBetween: 10,
    slidesPerView: 1.1,
  },
  420: {
    spaceBetween: 18,
    slidesPerView: 1.4,
  },
  575: {
    spaceBetween: 5,
    slidesPerView: 1.8,
  },
  768: {
    spaceBetween: 10,
    slidesPerView: 2.8,
  },
  850: {
    spaceBetween: 10,
    slidesPerView: 3.1,
  },
  1280: {
    spaceBetween: 18,
    slidesPerView: 4,
  },
};

const ServisesInfoEscortPage = () => {
  let [searchParams] = useSearchParams();
  const lotId = searchParams.get('lot');

  if (lotId === null) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }

  return (
    <ServicesStoreProvider>
      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])}>
        <BannerPage
          bannerImg={bannerImg}
          title={`Cопровождение сделки\nна торгах`}
          description={`Мы поможем вам пройти все этапы участия в торгах:\nот подготовки документов до подписания договора`}
          price={'1% от суммы сделки, но не более 100 000 ₽'}
          serviceSlug={ServicesSlugStatic.escort}
          printLabelPrice
        />
      </PageContainer>
      <PageContainer className={cn(s['lot-page'])}>
        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Этапы сопровождения
          </Typography.Title>

          <SliderStep
            slidesPerView={4}
            spaceBetween={18}
            breakpoints={BREAKPOINTS}
            slideClass={s.slide}
            slides={[
              <CardStep
                item={{
                  title: 'Анализ торгов',
                  step: 1,
                  image: img1,
                  description:
                    'Мы проводим глубокий анализ объектов, оцениваем их потенциал, риски и рыночную стоимость, чтобы вы приняли обоснованное решение об участии',
                }}
                vertical
              />,
              <CardStep
                item={{
                  title: 'Подготовка документов',
                  step: 2,
                  image: img2,
                  description:
                    'Оформляем и проверяем все необходимые документы для участия в торгах, получаем ЭЦП, аккредитацию на ЭТП, чтобы избежать ошибок и нарушений на всех этапах',
                }}
                vertical
              />,
              <CardStep
                item={{
                  title: 'Участие в торгах',
                  step: 3,
                  image: img3,
                  description: 'Мы контролируем ход торгов и используем стратегию для достижения наилучшего результата',
                }}
                vertical
              />,
              <CardStep
                item={{
                  title: 'Контроль подписания договора и исполнения обязательств',
                  step: 4,
                  image: img4,
                  description:
                    'После успешного завершения торгов мы обеспечиваем правильное и своевременное подписание всех юридических документов и контроль исполнения обязательств',
                }}
                vertical
              />,
            ]}
          />
        </div>

        <div className={s.section}>
          <Typography.Title className={s.title} level={2}>
            Частые вопросы (FAQ):
          </Typography.Title>
          <div className={s.padding}>
            <FaqItem
              title="Что такое сопровождение сделки на торгах?"
              description="Это услуга, при которой наши специалисты помогают вам пройти все этапы участия в электронных торгах — от подачи заявки до подписания договора с победителем.​"
            />
            <FaqItem
              title="Как подать заявку на сопровождение?"
              description={`Нажмите кнопку "Оставить заявку", заполните форму с контактной информацией, и мы свяжемся с вами в ближайшее время.`}
            />
            <FaqItem
              title="Какие этапы включает услуга?"
              description={`Наша услуга включает: анализ объекта, подготовку документов, участие в торгах, контроль подписания договора и завершение сделки`}
            />
            <FaqItem
              title="Какие торги вы сопровождаете?"
              description="Мы работаем с торгами на государственных и коммерческих площадках, таких как ЕИС, РТС-тендер и другие"
            />
            <FaqItem
              title="Как мне быть уверенным, что я выиграю торги?"
              description="Мы не можем гарантировать победу, но обеспечиваем максимальные шансы за счёт правильной подготовки и стратегического участия"
            />
            <FaqItem
              title="Что делать, если сделка не состоится?"
              description="Мы предлагаем защиту интересов клиента на всех этапах и проконсультируем по возможным действиям в случае проблем"
            />
            <FaqItem
              title="Как я могу оплатить услугу?"
              description="Оплата возможна через онлайн-платеж. Мы также предоставляем счета для юридических лиц"
            />
            <FaqItem
              title="Каковы сроки оказания услуги?"
              description="Время выполнения услуги зависит от конкретного аукциона и стадии сделки, на которой подключаемся."
            />
            <FaqItem
              title="Нужно ли мне лично участвовать в торгах?"
              description="Мы можем полностью сопровождать вас в дистанционном формате. Если требуется ваше участие, мы заранее обговорим все детали"
            />
            <FaqItem
              title="Какие гарантии вы предоставляете?"
              description="Мы гарантируем профессиональный подход на всех этапах и юридическую защиту ваших интересов"
            />
          </div>
        </div>
      </PageContainer>
    </ServicesStoreProvider>
  );
};

export default observer(ServisesInfoEscortPage);
