import cn from 'classnames';
import * as React from 'react';

import { PageContainer } from 'components/PageContainer';
import { AnalyticsEvent } from 'entities/analytics';
import useToggleState from 'hooks/useToggleState';
import { useWidth } from 'hooks/useWidth';
import { LotListStoreProvider } from 'stores/LotListCatalogStore/context';
import { useRootStore } from 'stores/RootStore';

import { ListWithFilters } from './ListWithFilters';
import { SearchBar } from './SearchBar';

import s from './LotsListPage.module.scss';
import { BannerLots } from './Banner/BannerLots';

type Props = {
  className?: string;
};

const LotsListPage: React.FC<Props> = ({ className }) => {
  const { analyticsStore } = useRootStore();
  const { opened } = useToggleState();
  const { isPhone } = useWidth();

  const isFiltersCollapsed = isPhone ? opened : false;

  React.useEffect(() => {
    analyticsStore.trackEvent({
      event: AnalyticsEvent.lotsListPageViewed,
    });
  }, []);

  return (
    <LotListStoreProvider>
      <PageContainer withoutBottomPadding className={cn(s['lots-list-page'], className)}>
        <BannerLots />
        {!isFiltersCollapsed && (
          <>
            <SearchBar />
          </>
        )}
      </PageContainer>
      <ListWithFilters isFiltersCollapsed={isFiltersCollapsed} />
    </LotListStoreProvider>
  );
};

export default LotsListPage;
