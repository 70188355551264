import { IReactionDisposer, computed, makeObservable, reaction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { LotServer } from 'entities/lot';
import { ProfileListHistoryPayload } from 'entities/lotFilters';
import { ListModel } from 'models/ListModel';
import { LotModel } from 'models/LotModel';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { apiCustom } from 'utils/api';

import { LotListProfileFiltersModel } from './LotListProfileFiltersModel';
import { ResponseListApi } from 'entities';

type LotListProfileStoreParams = {
  queryParams: URLSearchParams;
  rootStore: IRootStore;
};

export class LotListProfileHistoryStore extends LocalStore {
  readonly lots: ListModel<LotModel, number> = new ListModel<LotModel, number>();
  readonly filters: LotListProfileFiltersModel;
  private api = apiCustom;
  private abortController?: AbortController;

  private readonly rootStore: IRootStore;

  private readonly disposers: IReactionDisposer[] = [];

  constructor({ queryParams, rootStore }: LotListProfileStoreParams) {
    super();

    makeObservable<this>(this, {
      isListInitialLoading: computed,
      currentTotalItems: computed,
    });

    this.filters = new LotListProfileFiltersModel({ params: queryParams, routerStore: rootStore.routerStore });
    this.filters.init();
    this.rootStore = rootStore;

    this.disposers.push(
      reaction(
        () => this.filters.offset,
        () => this.fetchHistoryLotItems({ replace: true }),
      ),
      reaction(
        () => this.filters.params,
        () => {
          this.fetchHistoryLotItems({ replace: true });
        },
      ),
      reaction(
        () => this.filters.isEmptySideFilters,
        (isEmptySideFilters) => {
          // Ресетим фильтры, если все теги были удалены не через кнопку очистить фильтры
          if (isEmptySideFilters) {
            this.filters.setSideFilters();
          }
        },
      ),
    );
  }

  get isListInitialLoading(): boolean {
    return this.lots.isInitialLoading.value;
  }

  get currentTotalItems(): number {
    return this.lots.total.value ?? 0;
  }

  private preparePayload = (): ProfileListHistoryPayload => {
    const data: { [key in keyof ProfileListHistoryPayload]: any } = {
      params: {
        limit: this.filters.limit,
        offset: this.filters.offset,
        order: this.filters.params.order,
        order_direction: this.filters.params.order_direction,
      },
      list_type: 'history',
      filters: {
        only_visited: true,
        auction_type: this.filters.params.auction_type,
        form_type: this.filters.params.form_type,
        status: this.filters.params.status,
        aggregated_type: this.filters.aggregatedType.selectedValue.value,
      },
    };

    if (
      this.filters.aggregatedType.selectedValue.value === 'all' ||
      this.filters.aggregatedType.selectedValue.value === null
    ) {
      delete data.filters['aggregated_type'];
    }

    return data;
  };

  deleteFetchHistoryItem = async (id: number) => {
    try {
      const response = await this.api<ResponseListApi<LotServer>>({
        url: `${apiUrls.lotHistoryDelete(id)}`,
        method: 'POST',
      });

      if (response.isError) {
        this.lots.loadingStage.error();
        return;
      }

      this.fetchHistoryLotItems({ replace: true });

      return {
        isError: false,
        data: 'success',
      };
    } catch (e) {
      return {
        isError: true,
        data: JSON.stringify(e),
      };
    }
  };

  fetchHistoryLotItems = async ({ replace = false }: { replace: boolean }): Promise<void> => {
    if (replace) {
      this.lots.setIsAllLoaded(false);
      this.lots.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.lots.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.lots.loadingStage.loading();

    const data = this.preparePayload();
    const response = await this.api<ResponseListApi<LotServer>>({
      url: `${apiUrls.lotList}?limit=${data.params.limit}&offset=${data.params.offset}`,
      method: 'POST',
      data: data,
      config: {
        signal: request.signal,
      },
    });
    if (response.isError) {
      this.lots.loadingStage.error();
      return;
    }

    this.lots.fillByRawData<LotServer>(
      response.data.results,
      (raw) => {
        const model = LotModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );
    this.lots.isInitialLoading.change(false);
    this.lots.isReplaceLoading.change(false);
    this.lots.total.change(response.data.count);
    this.lots.totalPure.change(response.data.count);
    this.lots.setIsAllLoaded(response.data.results.length < this.filters.limit);
    this.lots.loadingStage.success();
  };

  destroy(): void {
    super.destroy();
    this.filters.destroy();
  }
}
