import { ProfileFavoriteListType, ProfileListType, RouteSegment } from './constants';

export const routerUrls = {
  lotsList: {
    mask: `/${RouteSegment.lots}`,
    create: () => `/${RouteSegment.lots}`,
  },
  lotDetail: {
    mask: `/${RouteSegment.lots}/:id`,
    create: (id: number) => `/${RouteSegment.lots}/${id}`,
  },
  profile: {
    mask: `/${RouteSegment.profile}`,
    create: (listType = ProfileListType.favorites) => `/${RouteSegment.profile}?list_type=${listType}`,
  },
  favorites: {
    mask: `/${RouteSegment.profile}/${RouteSegment.favorites}`,
    create: (listType = ProfileFavoriteListType.favorites) =>
      `/${RouteSegment.profile}/${RouteSegment.favorites}?list_type=${listType}`,
  },
  profileRobot: {
    mask: `/${RouteSegment.profile}/${RouteSegment.robots}`,
    create: () => `/${RouteSegment.profile}/${RouteSegment.robots}`,
  },
  profileNotification: {
    mask: `/${RouteSegment.profile}/${RouteSegment.notification}`,
    create: () => `/${RouteSegment.profile}/${RouteSegment.notification}`,
  },
  tariffs: {
    mask: `/${RouteSegment.tariffs}`,
    create: () => `/${RouteSegment.tariffs}`,
  },
  tariffsPremium: {
    mask: `/${RouteSegment.tariffs}/${RouteSegment.premium}`,
    create: () => `/${RouteSegment.tariffs}/${RouteSegment.premium}`,
  },
  subscriptions: {
    mask: `/${RouteSegment.profile}/${RouteSegment.subscriptions}`,
    create: () => `/${RouteSegment.profile}/${RouteSegment.subscriptions}`,
  },
  profileSettings: {
    mask: `/${RouteSegment.profile}/${RouteSegment.settings}`,
    create: () => `/${RouteSegment.profile}/${RouteSegment.settings}`,
  },
  history: {
    mask: `/${RouteSegment.profile}/${RouteSegment.history}`,
    create: (listType: string) => `/${RouteSegment.profile}/${RouteSegment.history}?list_type=${listType}`,
  },
  profileServices: {
    mask: `/${RouteSegment.profile}/${RouteSegment.services}`,
    create: (hash: string) => `/${RouteSegment.profile}/${RouteSegment.services}#${hash}`,
  },
  servicesOnline: {
    mask: `/${RouteSegment.services_online}`,
    create: () => `/${RouteSegment.services_online}`,
  },
  servicesEscort: {
    mask: `/${RouteSegment.services_escort}`,
    create: () => `/${RouteSegment.services_escort}`,
  },
  servicesOrder: {
    mask: `/${RouteSegment.services_audit_legal}`,
    create: () => `/${RouteSegment.services_audit_legal}`,
  },
  staticPolicy: {
    mask: `/${RouteSegment.policy}`,
    create: () => `/${RouteSegment.policy}`,
  },
  staticAgree: {
    mask: `/${RouteSegment.agree}`,
    create: () => `/${RouteSegment.agree}`,
  },
  staticTermsOfService: {
    mask: `/${RouteSegment.terms_of_service}`,
    create: () => `/${RouteSegment.terms_of_service}`,
  },
  staticCookies: {
    mask: `/${RouteSegment.cookies}`,
    create: () => `/${RouteSegment.cookies}`,
  },
};
