import { Spin } from 'antd';
import Breadcrumb, { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';

import s from './ProfileSettingsPage.module.scss';
import { ProfileAsideMenu } from 'components/profile/ProfileAsideMenu/ProfileAsideMenu';
import { LayoutGridAside } from 'components/LayoutGridAside/LayoutGridAside';
import { ProfileSettingsStoreProvider } from 'stores/ProfileSettingsStore/context';
import { FormPersonalData } from './FormPersonalData/FormPersonalData';
import FormBankCard from './FormBankCard/FormBankCard';
import { FormNotification } from './FormNotification/FormNotification';

type ProfileProps = {
  className?: string;
};

const ProfileSettingsPage = ({ className }: ProfileProps): React.ReactElement<ProfileProps> => {
  const rootStore = useRootStore();

  const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
    { title: 'Личный кабинет', href: routerUrls.profileSettings.mask },
    { title: 'Настройки' },
  ];

  if (!rootStore.authStore.authorizingStage.isFinished) {
    return (
      <div className={s.profile__spinner}>
        <Spin size="large" />
      </div>
    );
  }

  if (!rootStore.userStore.authorized) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }

  return (
    <PageContainer withoutBottomPadding className={cn(s.profile, className)}>
      <Breadcrumb className={s.profile__breadcrumbs} itemRender={breadcrumbItemsRender} items={BREADCRUMB_ITEMS} />
      <PageListTitle className={s.profile__title}>Настройки</PageListTitle>
      <ProfileSettingsStoreProvider>
        <LayoutGridAside aside={<ProfileAsideMenu />}>
          <FormPersonalData />
          {/* <FormBankCard /> */}
          <FormNotification />
        </LayoutGridAside>
      </ProfileSettingsStoreProvider>
    </PageContainer>
  );
};

export default observer(ProfileSettingsPage);
