import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { Status } from 'entities/lot';
import useToggleState from 'hooks/useToggleState';
import { LotModel } from 'models/LotModel';

import { ImageGallery } from '../ImageGallery';
import Layout from '../Layout';
import { Meta } from '../Meta';

import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';

import s from './LotCard.module.scss';

export type LotCardProps = {
  item: LotModel;
  additionalTags?: Status[];
  style?: React.CSSProperties;
  className?: string;
  mobileHover?: boolean;
};

export const LotCardIntersection = observer((props: LotCardProps & { onView?: (id: number | number[]) => void }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const refStop = React.useRef(false);

  React.useEffect(() => {
    if (inView && !refStop.current && props.onView) {
      refStop.current = true;
      props.onView(props.item.id);
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <LotCard {...props} />
    </div>
  );
});

const LotCard = ({
  item,
  className,
  style,
  additionalTags,
  mobileHover,
}: LotCardProps): React.ReactElement<LotCardProps> => {
  const { open, close } = useToggleState();
  const handleFavoriteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      item.startToggleFavorite();
    },
    [item],
  );

  const handleSubscribeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      item.startToggleSubscription();
    },
    [item],
  );

  const images = item.images ? item.images.slice(0, 4) : [];

  return (
    <Layout
      item={item}
      style={style}
      className={className}
      handleMouseEnter={open}
      handleMouseLeave={close}
      mobileHover={mobileHover}
    >
      <ImageGallery images={images} className={s.image + ' lot-card-image'} showBullets />
      <div className={s['lot-card__main-content']}>
        <Header
          status={item.status}
          auctionFormat={item.auctionFormat}
          auctionType={item.auctionType}
          objectType={item.objectType}
          priceMin={item.priceMinFormatted}
          totalArea={item.totalAreaFormatted}
          isFavorite={item.isInFavorite}
          handleFavoriteClick={handleFavoriteClick}
          isSubscribed={item.isInSubscribed}
          handleSubscribeClick={handleSubscribeClick}
          additionalTags={additionalTags}
          isDowngradeAuction={item.isDowngradeAuction}
        />
        <Main address={item.lotCardAddress} source={item.source} column />
        <Meta floor={item.floor} totalFloors={item.amountOfFloors} entranceCount={item.entrance} />
        <Footer dateAdded={item.dateAdded} viewsCount={item.viewsCount} />
      </div>
    </Layout>
  );
};

export default observer(LotCard);
