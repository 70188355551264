import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useWidth } from 'hooks/useWidth';
import { useThemeStore } from 'stores/RootStore/context';

import PremiumAdvantagesCard from './PremiumAdvantagesCard/PremiumAdvantagesCard';
import PremiumAdvantagesGrid from './PremiumAdvantagesGrid/PremiumAdvantagesGrid';
import PremiumBanner from './PremiumBanner/PremiumBanner';
import PremiumPriceCards from './PremiumPriceCards/PremiumPriceCards';
import PremiumSlider from './PremiumSlider/PremiumSlider';

import s from './TariffsPremiumPage.module.scss';

const TariffsPremiumPage = (): React.ReactElement => {
  const themeStore = useThemeStore();
  const { isPhone } = useWidth();

  useEffect(() => {
    themeStore.setTheme('dark');

    return () => {
      themeStore.setTheme('light');
    };
  }, []);

  return (
    <div className={s.premium__page}>
      <div className={s.premium__page__container}>
        <PremiumBanner />
        {isPhone ? (
          <PremiumSlider title="Что вы получите с премиум подпиской?">
            <PremiumAdvantagesCard
              title="Робот-помощник"
              description="Настройка и использование интеллектуального помощника для поиска объектов и мониторинга"
              backgroundImage="/assets/premium/robot.png"
              className={s.premium__card__robot}
            />
            <PremiumAdvantagesCard
              title="2 бесплатных онлайн-показа объектов"
              description="Ежемесячно вы можете бесплатно получить показ двух объектов онлайн"
              backgroundImage="/assets/premium/door.png"
            />
            <PremiumAdvantagesCard
              title="Приоритетная служба поддержки"
              description="Доступ к службе поддержки с высоким приоритетом."
              backgroundImage="/assets/premium/headphone.png"
            />
            <PremiumAdvantagesCard
              title="Информация по объектам"
              description="Возможность просматривать и скачивать документы по объектам, переходить на электронные торговые площадки (ЭТП)."
              backgroundImage="/assets/premium/glass.png"
            />
            <PremiumAdvantagesCard
              title="Подключение до двух устройств"
              description="Использование премиум аккаунта на двух устройствах одновременно."
              backgroundImage="/assets/premium/phone.png"
            />
            <PremiumAdvantagesCard
              title="Юридический аудит со скидкой 60%"
              description="Услуга юридического аудита по сниженной цене."
              backgroundImage="/assets/premium/volume.png"
            />
            <PremiumAdvantagesCard
              title="Доступ к просмотру объектов"
              description="Полный доступ ко всем объектам на платформе."
              backgroundImage="/assets/premium/door2.png"
            />
          </PremiumSlider>
        ) : (
          <PremiumAdvantagesGrid title="Что вы получите с премиум подпиской?">
            <PremiumAdvantagesCard
              title="Робот-помощник"
              description="Настройка и использование интеллектуального помощника для поиска объектов и мониторинга"
              backgroundImage="/assets/premium/robot.png"
              className={s.premium__card__robot}
            />
            <PremiumAdvantagesCard
              title="2 бесплатных онлайн-показа объектов"
              description="Ежемесячно вы можете бесплатно получить показ двух объектов онлайн"
              backgroundImage=""
            />
            <PremiumAdvantagesCard
              title="Приоритетная служба поддержки"
              description="Доступ к службе поддержки с высоким приоритетом."
              backgroundImage="/assets/premium/headphoneBanner.png"
            />
            <PremiumAdvantagesCard
              title="Информация по объектам"
              description="Возможность просматривать и скачивать документы по объектам, переходить на электронные торговые площадки (ЭТП)."
              backgroundImage="/assets/premium/glassBanner.png"
            />
            <PremiumAdvantagesCard
              title="Подключение до двух устройств"
              description="Использование премиум аккаунта на двух устройствах одновременно."
              backgroundImage="/assets/premium/phone.png"
            />
            <PremiumAdvantagesCard
              title="Юридический аудит со скидкой 60%"
              description="Услуга юридического аудита по сниженной цене."
              backgroundImage="/assets/premium/volume.png"
            />
            <PremiumAdvantagesCard
              title="Доступ к просмотру объектов"
              description="Полный доступ ко всем объектам на платформе."
              backgroundImage="/assets/premium/door2.png"
            />
          </PremiumAdvantagesGrid>
        )}
        <PremiumPriceCards isPhone={isPhone} />
        {/* <div className={s.premium__footer}>
          <h2 className={s.premium__footer__title}>Выберите подходящий тарифный план для себя</h2>
          <Button className={s.premium__footer__button}>Посмотреть все подписки</Button>
        </div> */}
      </div>
    </div>
  );
};

export default observer(TariffsPremiumPage);
