import * as React from 'react';

import { ElementId } from 'config/ids';
import { LotModel } from 'models/LotModel';

import Section from '../Section';
import SectionBlock from '../SectionBlock';

type PropertySectionProps = {
  lot: LotModel;
  id?: ElementId;
};

const PropertySection: React.FC<PropertySectionProps> = ({ lot, id }) => {
  return (
    <Section id={id} title="Характеристики имущества" className={'section-navigation ' + id}>
      {lot.totalAreaFormatted && <SectionBlock title="Площадь объекта" description={`${lot.totalAreaFormatted} м²`} />}
      {lot.floor && <SectionBlock title="Этаж" description={lot.floor} />}
      <SectionBlock title="Тип объекта" description={lot.objectType} />
    </Section>
  );
};

export default React.memo(PropertySection);
