import React, { useMemo } from 'react';
import SubscriptionsCards from '../SubscriptionsCards/SubscriptionsCards';
import SubscriptionsTabs from '../SubscriptionsTabbar/SubscriptionsTabbar';
import s from './SubscriptionTariffs.module.scss';
import { observer } from 'mobx-react-lite';
import { notification, Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import { useTariffsStore } from 'stores/TariffsStore/context';
import { TariffPlanType } from 'entities/tariffs';
import { useRootStore } from 'stores/RootStore';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { TARIFFS_MOCK } from './SubscriptionTariffs.mock';
import { TariffModel } from 'models/TariffModel';

const key = 'updatable';

export const SubscriptionTariffs = observer(() => {
  const { userStore } = useRootStore();
  const [listType, setListType] = React.useState<TariffPlanType>(TariffPlanType.personal);
  const { tariffs, subscriptionTariff, loadingSubscriptionStage } = useTariffsStore();

  const activeTariff = useMemo(() => {
    if (userStore.activeUserTariff) {
      const tariffInfo = tariffs.items.find((item) => item.id === userStore.activeUserTariff?.tariff_id);
      return {
        ...userStore.activeUserTariff,
        tariffInfo: tariffInfo,
      };
    }
    return null;
  }, [tariffs.items, userStore.activeUserTariff]);

  const tabItems = useMemo(() => {
    const _tariffs = [...TARIFFS_MOCK];
    _tariffs.forEach((item, index) => {
      const tariff = tariffs.items.find((tariffItem) => tariffItem.name === item.name);
      if (tariff) {
        _tariffs[index] = new TariffModel({
          ...item,
          pricing_options: tariff.pricing_options,
          id: tariff.id,
        });
      }
    });
    return _tariffs.filter((item) => item.plan_type === listType && item.is_free === false);
  }, [tariffs.items, listType]);

  const [api, contextHolder] = notification.useNotification();

  const notificationShow = (title: string, description: string, isError?: boolean) => {
    api.info({
      message: title,
      description: description,
      placement: 'topRight',
      icon: isError ? <WarningFilled style={{ color: 'red' }} /> : <CheckCircleFilled style={{ color: 'green' }} />,
      key,
    });
  };

  const onSubscriptionTariff = (tariff: number, option: number) => {
    if (userStore.user?.userId)
      subscriptionTariff({
        tariff,
        plan_option: option,
        user: userStore.user?.userId,
      }).then((res) => {
        if (!res.isError) {
          window.location.href = res.data.payment_url;
        } else {
          notificationShow('Ошибка!', res?.data || 'Error 500', true);
        }
      });
  };

  if (tariffs.loadingStage.isFinished && tariffs.loadingStage.isError) {
    return <Navigate to={'/'} />;
  }

  return (
    <div>
      {tariffs.loadingStage.isLoading ? (
        <div className={s.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {contextHolder}
          <SubscriptionsTabs setListType={setListType} listType={listType} />
          <SubscriptionsCards
            listType={listType}
            className={s.cards}
            items={tabItems}
            onSubscriptionTariff={onSubscriptionTariff}
            isLoading={loadingSubscriptionStage.isLoading}
            active={activeTariff?.tariffInfo?.id || undefined}
          />
        </>
      )}
    </div>
  );
});
