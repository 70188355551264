import { ValueModel } from 'models/ValueModel';

export interface INotification {
  id: number;
  type: NotificationType;
  url: string;
  text: string;
  created_at: string;
  is_read: boolean;
  user: number;
}

export interface INotificationSubscribe {
  id: number;
  notification_type: NotificationType;
  name: string;
  description: null | string;
  site_notification: boolean;
  telegram_notification: boolean;
  email_notification: boolean;
  user: number;
}

export interface INotivicationSubscribeUpdateBody {
  site_notification: true;
  telegram_notification: true;
  email_notification: true;
}

export enum NotificationType {
  update_lot = 'update_lot',
  search_lot = 'search_lot',
  service = 'service',
  all = 'all',
  news = 'news',
  sub = 'sub',
}
