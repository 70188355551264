import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProfileListType } from 'entities/lotFilters';
import { DEFAULT_EMPTY_TEXT } from 'pages/LotsListPage/LotsList/LotsList';
import { LotsList } from '../../LotsList';
import { emptyListPlaceholderOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { Pagination } from 'antd';
import s from '../../FavoritesPage.module.scss';
import { getCurrentPage } from 'utils/page';
import { useLotListProfileFavoriteStore } from 'stores/LotListProfileFavoriteStore/context';

type FavoritesListProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const FavoritesList = ({ className }: FavoritesListProps): React.ReactElement<FavoritesListProps> => {
  const refWrap = React.useRef<HTMLDivElement | null>(null);
  const { favoriteLots, filters } = useLotListProfileFavoriteStore();
  const isEmpty = favoriteLots.isEmpty && filters.isEmptySideFilters && !favoriteLots.loadingStage.isLoading;
  const emptyText = isEmpty ? emptyListPlaceholderOptions[ProfileListType.favorites] : DEFAULT_EMPTY_TEXT;

  return (
    <div ref={refWrap} className={className}>
      <LotsList lots={favoriteLots} emptyText={emptyText} />
      {favoriteLots.total.value && favoriteLots.total.value > 10 ? (
        <div className={s.pagination}>
          <Pagination
            current={getCurrentPage(favoriteLots.total.value, filters.offset)}
            total={favoriteLots.total.value || 0}
            onChange={(page) => {
              filters.setPage(page - 1);
              if (refWrap && refWrap.current) window.scrollTo({ behavior: 'smooth', top: refWrap.current.offsetTop });
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(FavoritesList);
