import { Button, Space, Typography } from 'antd';
import * as React from 'react';

import { routerUrls } from 'config/routes';

import s from './Card.module.scss';

type CardProps = {
  title: string;
  description: string;
  isApplicationSend?: boolean;
  isLoading?: boolean;
  onClick?: VoidFunction;
  backgroundImage?: string;
  className?: string;
  onClickMore?: () => void;
};

const Card: React.FC<CardProps> = ({
  title,
  description,
  isApplicationSend,
  onClick,
  isLoading,
  backgroundImage,
  className = '',
  onClickMore,
}) => {
  return (
    <Space
      direction="vertical"
      className={s.card + ' ' + className}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div>
        <Typography.Title className={s.card__title} level={3}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography.Title>
        <Typography.Text className={s.card__description} type="secondary">
          {description}
        </Typography.Text>
      </div>
      {
        <div className={s.card__buttons}>
          {!isApplicationSend ? (
            <Button className={s.card__button} onClick={onClick} type="primary" size="large" loading={isLoading}>
              Оставить заявку
            </Button>
          ) : (
            <Button
              className={s.card__button}
              href={routerUrls.profileServices.create('audit')}
              size="large"
              loading={isLoading}
            >
              Мои заявки
            </Button>
          )}
          <Button
            className={s.card__button + ' ' + s.card__button__more}
            onClick={onClickMore}
            size="large"
            loading={isLoading}
          >
            Узнать подробнее
          </Button>
        </div>
      }
    </Space>
  );
};

export default React.memo(Card);
