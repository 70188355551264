import * as React from 'react';
import { useLocalStore } from 'hooks/useLocalStore';

import { ServicesStore } from './ServicesStore';

type ServicesStoreContextValue = ServicesStore;

const ServicesStoreContextValueContext = React.createContext<ServicesStoreContextValue | null>(null);

export const ServicesStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const store = useLocalStore(() => new ServicesStore());
  return (
    <ServicesStoreContextValueContext.Provider value={store}>{children}</ServicesStoreContextValueContext.Provider>
  );
};

export const useServicesStore = () => {
  const context = React.useContext(ServicesStoreContextValueContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
