import Slider from 'components/Slider';
import React, { ReactNode } from 'react';
import { Pagination } from 'swiper/modules';
import s from './SliderStep.module.scss';
import { SwiperOptions } from 'swiper/types';
import cn from 'classnames';

export const SliderStep = ({
  breakpoints,
  slidesPerView,
  spaceBetween,
  className,
  slideClass,
  slides,
}: {
  className?: string;
  slideClass?: string;
  spaceBetween: number;
  slidesPerView: number;
  breakpoints: SwiperOptions['breakpoints'];
  slides: ReactNode[];
}) => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
  };

  return (
    <div>
      <Slider
        breakpoints={breakpoints}
        className={cn(s.swiper, className)}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        slideClass={cn(s.slide, slideClass)}
        pagination={pagination}
        modules={[Pagination]}
        slides={slides}
        onSlideChange={() => void 0}
      />
    </div>
  );
};
