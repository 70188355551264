import { Spin } from 'antd';
import Breadcrumb, { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';
import { ListWithFilters } from './ListWithFilters';

import s from './ProfileRobotPage.module.scss';
import { LotListProfileRobotStoreProvider } from 'stores/LotListProfileRobotStore/context';
import { ProfileAsideMenu } from 'components/profile/ProfileAsideMenu/ProfileAsideMenu';
import { LayoutGridAside } from 'components/LayoutGridAside/LayoutGridAside';
import { CollapsedProvider } from 'components/Сollapsed/Сollapsed.context';

type ProfileProps = {
  className?: string;
};

const ProfileRobotPage = ({ className }: ProfileProps): React.ReactElement<ProfileProps> => {
  const rootStore = useRootStore();

  const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
    { title: 'Личный кабинет', href: routerUrls.profileSettings.mask },
    { title: 'Робот/Мои фильтры' },
  ];

  if (!rootStore.authStore.authorizingStage.isFinished) {
    return (
      <div className={s.profile__spinner}>
        <Spin size="large" />
      </div>
    );
  }

  if (!rootStore.userStore.authorized) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }

  return (
    <PageContainer withoutBottomPadding className={cn(s.profile, className)}>
      <Breadcrumb className={s.profile__breadcrumbs} itemRender={breadcrumbItemsRender} items={BREADCRUMB_ITEMS} />
      <PageListTitle className={s.profile__title}>Мои фильтры</PageListTitle>
      <CollapsedProvider>
        <LotListProfileRobotStoreProvider>
          <LayoutGridAside aside={<ProfileAsideMenu />}>
            <ListWithFilters />
          </LayoutGridAside>
        </LotListProfileRobotStoreProvider>
      </CollapsedProvider>
    </PageContainer>
  );
};

export default observer(ProfileRobotPage);
