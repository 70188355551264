import { FilterOption, ProfileListType } from 'entities/lotFilters';
import { capitalize } from 'utils/capitalize';
import { pluralizePreselectedWithoutCount } from 'utils/pluralizer';

export const listTypeOptions: FilterOption<string>[] = [
  {
    id: 'all',
    title: 'Все типы',
  },
];

export const DEFAULT_LIST_TYPE = listTypeOptions[0];

export const totalItemsPrefixOptions: Record<ProfileListType, (n: number) => string> = {
  [ProfileListType.favorites]: (n) => capitalize(pluralizePreselectedWithoutCount(n, 'отложено')),
  [ProfileListType.bids]: () => 'Оставлены заявки на',
  [ProfileListType.subs]: () => 'Подписаны на',
};

export const emptyListPlaceholderOptions: Record<ProfileListType, string> = {
  [ProfileListType.favorites]: 'Здесь будет храниться избранное',
  [ProfileListType.bids]: 'Здесь будут храниться заявки',
  [ProfileListType.subs]: 'Здесь будут храниться подписки',
};
