import * as React from 'react';
import { BaseIconProps } from '../commonProps';

const NotificationIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        d="M12.75 12.0001H12.375V6.68762C12.375 4.48293 10.7453 2.6595 8.625 2.35637V1.75012C8.625 1.40481 8.34531 1.12512 8 1.12512C7.65469 1.12512 7.375 1.40481 7.375 1.75012V2.35637C5.25469 2.6595 3.625 4.48293 3.625 6.68762V12.0001H3.25C2.97344 12.0001 2.75 12.2236 2.75 12.5001V13.0001C2.75 13.0689 2.80625 13.1251 2.875 13.1251H6.25C6.25 14.0907 7.03438 14.8751 8 14.8751C8.96562 14.8751 9.75 14.0907 9.75 13.1251H13.125C13.1938 13.1251 13.25 13.0689 13.25 13.0001V12.5001C13.25 12.2236 13.0266 12.0001 12.75 12.0001ZM8 13.8751C7.58594 13.8751 7.25 13.5392 7.25 13.1251H8.75C8.75 13.5392 8.41406 13.8751 8 13.8751Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NotificationIcon;
