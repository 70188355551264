import { IReactionDisposer, computed, makeObservable, reaction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { LotServer } from 'entities/lot';
import { ProfileFavoriteListType, ProfileListFavoritePayload } from 'entities/lotFilters';
import { ListModel } from 'models/ListModel';
import { LotModel } from 'models/LotModel';
import { LocalStore } from 'stores/LocalStore';
import { IRootStore } from 'stores/RootStore';
import { AppEvent } from 'types/appEvent';
import { apiCustom } from 'utils/api';

import { LotListProfileFiltersModel } from './LotListProfileFiltersModel';
import { ResponseListApi } from 'entities';

type LotListProfileStoreParams = {
  queryParams: URLSearchParams;
  rootStore: IRootStore;
};

export class LotListProfileFavoriteStore extends LocalStore {
  readonly favoriteLots: ListModel<LotModel, number> = new ListModel<LotModel, number>();
  readonly filters: LotListProfileFiltersModel;
  private api = apiCustom;
  private abortController?: AbortController;

  private readonly rootStore: IRootStore;

  private readonly disposers: IReactionDisposer[] = [];

  constructor({ queryParams, rootStore }: LotListProfileStoreParams) {
    super();

    makeObservable<this>(this, {
      isListInitialLoading: computed,
      currentTotalItems: computed,
    });

    this.filters = new LotListProfileFiltersModel({ params: queryParams, routerStore: rootStore.routerStore });
    this.rootStore = rootStore;

    this.subscribe(AppEvent.lotRemoveFromFavorites, ({ lotId }) => {
      this.favoriteLots.removeEntity(lotId);
      const updatedTotal = this.favoriteLots.total.value ? this.favoriteLots.total.value - 1 : 0;

      this.favoriteLots.total.change(updatedTotal);
      this.favoriteLots.totalPure.change(updatedTotal);
    });

    this.disposers.push(
      reaction(
        () => this.filters.offset,
        () => this.fetchFavoriteLotItems({ replace: true }),
      ),
      reaction(
        () => this.filters.params,
        () => {
          this.fetchFavoriteLotItems({ replace: true });
        },
      ),
      reaction(
        () => this.filters.isEmptySideFilters,
        (isEmptySideFilters) => {
          // Ресетим фильтры, если все теги были удалены не через кнопку очистить фильтры
          if (isEmptySideFilters) {
            this.filters.setSideFilters();
          }
        },
      ),
    );
  }

  get isListInitialLoading(): boolean {
    return this.favoriteLots.isInitialLoading.value;
  }

  get currentTotalItems(): number {
    return this.favoriteLots.total.value ?? 0;
  }

  private preparePayload = (): ProfileListFavoritePayload => {
    let isSubscribed = null;

    if (this.filters.listType.selectedValue.value === ProfileFavoriteListType.favorites_notif) {
      isSubscribed = true;
    } else if (this.filters.listType.selectedValue.value === ProfileFavoriteListType.favorites_unnotif) {
      isSubscribed = false;
    }

    return {
      params: {
        limit: this.filters.limit,
        offset: this.filters.offset,
        order: this.filters.params.order,
        order_direction: this.filters.params.order_direction,
      },
      filters: {
        auction_type: this.filters.params.auction_type,
        form_type: this.filters.params.form_type,
        status: this.filters.params.status,
      },
      favorites: true,
      subscribed: isSubscribed,
    };
  };

  fetchFavoriteLotItems = async ({ replace = false }: { replace: boolean }): Promise<void> => {
    if (replace) {
      this.favoriteLots.setIsAllLoaded(false);
      this.favoriteLots.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.favoriteLots.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.favoriteLots.loadingStage.loading();

    const data = this.preparePayload();
    const response = await this.api<ResponseListApi<LotServer>>({
      url: `${apiUrls.lotListProfile}?limit=${data.params.limit}&offset=${data.params.offset}`,
      method: 'POST',
      data: data,
      config: {
        signal: request.signal,
      },
    });
    if (response.isError) {
      this.favoriteLots.loadingStage.error();
      return;
    }

    this.favoriteLots.fillByRawData<LotServer>(
      response.data.results,
      (raw) => {
        const model = LotModel.fromJson(raw, this.rootStore);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );
    this.favoriteLots.isInitialLoading.change(false);
    this.favoriteLots.isReplaceLoading.change(false);
    this.favoriteLots.total.change(response.data.count);
    this.favoriteLots.totalPure.change(response.data.count);
    this.favoriteLots.setIsAllLoaded(response.data.results.length < this.filters.limit);
    this.favoriteLots.loadingStage.success();
  };

  destroy(): void {
    super.destroy();
    this.filters.destroy();
  }
}
