import { FilterOption, ProfileFavoriteListType, ProfileListType } from 'entities/lotFilters';
import { capitalize } from 'utils/capitalize';
import { pluralizePreselectedWithoutCount } from 'utils/pluralizer';

export const DEFAULT_LIST_TYPE = ProfileFavoriteListType.favorites;

export const listTypeOptions: FilterOption<ProfileFavoriteListType>[] = [
  {
    id: ProfileFavoriteListType.favorites,
    title: 'Все типы',
  },
  {
    id: ProfileFavoriteListType.favorites_unnotif,
    title: 'Без уведомлений',
  },
  {
    id: ProfileFavoriteListType.favorites_notif,
    title: 'С уведомлениями',
  },
];

export const totalItemsPrefixOptions: Record<ProfileListType, (n: number) => string> = {
  [ProfileListType.favorites]: (n) => capitalize(pluralizePreselectedWithoutCount(n, 'отложено')),
  [ProfileListType.bids]: () => 'Оставлены заявки на',
  [ProfileListType.subs]: () => 'Подписаны на',
};

export const emptyListPlaceholderOptions: Record<ProfileListType, string> = {
  [ProfileListType.favorites]: 'Здесь будет храниться избранное',
  [ProfileListType.bids]: 'Здесь будут храниться заявки',
  [ProfileListType.subs]: 'Здесь будут храниться подписки',
};
