import React from 'react';
import s from './ProfileNotificationListItem.module.scss';
import { Checkbox } from 'antd';
import { formatDate } from 'utils/getFormattedDate';
import { DateFormat } from 'entities/dateFormat';
import { NotificationModel } from 'models/NotificationModel';
import { observer } from 'mobx-react-lite';

export const ProfileNotificationListItem = observer(
  ({
    notification,
    onToggleSelect,
    checked,
    onSetRead,
  }: {
    checked: boolean;
    notification: NotificationModel;
    onToggleSelect: (id: string | number, value: boolean) => void;
    onSetRead: (id: number) => void;
  }) => {
    const onClickLink = () => {
      if (notification.url) {
        onSetRead(notification.id);
        window?.open(notification.url, '_blank');
      }
    };

    return (
      <div className={s.item}>
        <div className={s.row}>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              onToggleSelect(notification.id, e.target.checked);
            }}
          />
          <span className={`${s.link} ${notification.is_read === false ? s.link__unread : ''}`} onClick={onClickLink}>
            {notification.text}
          </span>
        </div>
        <time className={s.time} dateTime={notification.created_at}>
          {formatDate(notification.created_at, DateFormat.timeDate)}
        </time>
      </div>
    );
  },
);
