import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { useLocalStore } from 'hooks/useLocalStore';
import { useRootStore } from 'stores/RootStore';

import { LotListProfileFavoriteStore } from './LotListProfileFavoriteStore';

type LotListProfileFavoriteStoreContextValue = LotListProfileFavoriteStore;

const LotListProfileFavoriteStoreContext = React.createContext<LotListProfileFavoriteStoreContextValue | null>(null);

export const LotListProfileFavoriteStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [queryParams] = useSearchParams();
  const rootStore = useRootStore();

  const store = useLocalStore(() => new LotListProfileFavoriteStore({ queryParams, rootStore }));

  React.useEffect(() => {
    store.fetchFavoriteLotItems({ replace: true });
  }, []);

  useKeyboardEvent('Enter', store.filters.setSideFilters);

  return (
    <LotListProfileFavoriteStoreContext.Provider value={store}>{children}</LotListProfileFavoriteStoreContext.Provider>
  );
};

export const useLotListProfileFavoriteStore = () => {
  const context = React.useContext(LotListProfileFavoriteStoreContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
