import { Empty } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ElementId } from 'config/ids';
import { BidModel } from 'models/BidModel';
import { LotModel } from 'models/LotModel';

import { LotCardSkeleton } from '../LotCardSkeleton';
import { LotsListProps } from '../types';

import s from './LotsList.module.scss';

const LotsListPagination = <T extends LotModel | BidModel>({
  lots,
  CardComponent,
  emptyText,
  className,
  grid,
  onScroll,
}: LotsListProps<T>): React.ReactElement<LotsListProps<T>> => {
  if (lots.isInitialLoading.value) {
    return (
      <div className={cn(s['lots-list'], grid && s['lots-grid'], className)} id={ElementId.lotsList}>
        <LotCardSkeleton column={grid} />
        <LotCardSkeleton column={grid} />
        <LotCardSkeleton column={grid} />
        <LotCardSkeleton column={grid} />
        <LotCardSkeleton column={grid} />
        <LotCardSkeleton column={grid} />
      </div>
    );
  }

  if (lots.items.length === 0 && !lots.isInitialLoading.value && !lots.isReplaceLoading.value) {
    return <Empty className={s['lots-list__empty']} description={emptyText} />;
  }

  return (
    <div className={cn(s['lots-list'], className)} id={ElementId.lotsList}>
      <div className={s['lots-list__inner']}>
        <div>
          <div className={cn(s['lots-list__items'], grid && s['lots-grid'])} onScroll={onScroll}>
            {lots.items.map((item, index) => {
              return (
                <div key={item.id} data-index={index} className={s['lots-list__card']}>
                  <CardComponent item={item} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LotsListPagination);
