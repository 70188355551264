import { Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useCookiesContext } from './context';
import s from './Cookies.module.scss';
import { Link } from 'react-router-dom';

export const Cookies = () => {
  const { show, onAgree } = useCookiesContext();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (show) {
        body.style.width = '100%';
        body.style.overflowY = 'auto';
      } else {
        body.style.width = '';
        body.style.overflowY = '';
      }
    }
    return () => {
      if (body) {
        body.style.width = '';
        body.style.overflowY = '';
      }
    };
  }, [show]);

  return (
    <Modal
      open={show}
      onClose={() => {}}
      onCancel={() => {}}
      footer={null}
      className={s.cookies__modal}
      closeIcon={<div></div>}
    >
      <div className={s.cookies}>
        <div className={s.cookies__container}>
          <h5 className={s.cookies__title}>На сайте используются файлы cookie</h5>
          <p className={s.cookies__desc}>
            Продолжая использовать данный сайт, вы соглашаетесь с{' '}
            <Link to="/cookies">Политикой использования cookie-файлов </Link>. Если вы не согласны с каким-либо условием
            Политики использования cookie-файлов, просим вас прекратить использование сайта
          </p>
          <Button className={s.cookies__btn} onClick={onAgree} type="primary">
            Принимаю
          </Button>
        </div>
      </div>
    </Modal>
  );
};
