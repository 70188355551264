import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { RangeFilter } from 'components/RangeFilter';
import { useLotListProfileRobotStore } from 'stores/LotListProfileRobotStore/context';

type PriceProps = {
  className?: string;
};

const Price = ({}: PriceProps): React.ReactElement<PriceProps> => {
  const { filters } = useLotListProfileRobotStore();

  return (
    <BaseInput title="Цена">
      <RangeFilter
        from={filters.price.from.value}
        to={filters.price.to.value}
        valueMin={filters.price.valueMin.value}
        valueMax={filters.price.valueMax.value}
        onChangeMin={filters.price.valueMin.change}
        onChangeMax={filters.price.valueMax.change}
        placeholderPostfix="₽"
      />
    </BaseInput>
  );
};

export default observer(Price);
