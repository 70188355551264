import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Joyride from 'react-joyride';

import { ElementId } from 'config/ids';
import { useObserveHeight } from 'hooks/useObserveHeight';
import { useWidth } from 'hooks/useWidth';
import { useRootStore } from 'stores/RootStore';
import { scrollToSelector } from 'utils/scrollToSelector';

import { Tooltip } from './Tooltip';

const Onboarding = (): React.ReactElement | null => {
  const {
    onboardingStore: { isOnboardingRunning, desktopSteps, mobileSteps, stepIndex, handleOnboardingStep },
  } = useRootStore();

  const { isDesktop } = useWidth();
  const steps = isDesktop ? desktopSteps : mobileSteps;

  React.useEffect(() => {
    /**
     * Компонент Joyride некорректно скролит к нужным элементам.
     * Поэтому отключаем скролл через disableScrolling и скролим вручную, когда происходит переход на следующий шаг.
     */
    if (!isOnboardingRunning) {
      scrollToSelector({ elSelector: `#${ElementId.root}` });
    } else if (steps[stepIndex.value]?.scrollTarget) {
      scrollToSelector({ elSelector: steps[stepIndex.value].scrollTarget as string, verticalOffset: -20 });
    }
  }, [stepIndex.value, isOnboardingRunning]);

  useObserveHeight(document.body, isOnboardingRunning);

  return (
    <Joyride
      steps={steps}
      run={isOnboardingRunning}
      stepIndex={stepIndex.value}
      callback={handleOnboardingStep}
      tooltipComponent={Tooltip}
      floaterProps={{
        styles: {
          arrow: {
            length: 7,
            spread: 14,
          },
        },
      }}
      styles={{
        overlay: {
          height: document.body.scrollHeight,
        },
      }}
      continuous
      hideCloseButton
      showSkipButton
      disableScrolling
    />
  );
};

export default observer(Onboarding);
