import { RobotHeader } from 'components/Robot';
import { useWidth } from 'hooks/useWidth';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLotListStore } from 'stores/LotListCatalogStore/context';

export const ListWithFiltersRobot = observer(({ className = '' }: { className?: string }) => {
  const { width } = useWidth();
  const { setFilters } = useLotListStore();

  const titleFilter = React.useMemo(() => {
    return width >= 768 ? 'Вы можете создать автоматизированный поиск по параметрам' : 'Создать автоматический поиск';
  }, [width]);
  return <RobotHeader className={className} title={titleFilter} onClickFilter={setFilters} />;
});
