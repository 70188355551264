import cn from 'classnames';
import React from 'react';

import s from './PremiumAdvantagesCard.module.scss';

interface PremiumAdvantagesCardProps {
  className?: string;
  title: string;
  description: string;
  backgroundImage?: string;
}

const PremiumAdvantagesCard = ({
  className,
  title,
  description,
  backgroundImage,
}: PremiumAdvantagesCardProps): React.ReactElement<PremiumAdvantagesCardProps> => {
  return (
    <div className={cn(s.advantages__card, className)} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={s.advantages__card__content}>
        <h3 className={s.advantages__card__content__title}>{title}</h3>
        <p className={s.advantages__card__content__description}>{description}</p>
      </div>

      {backgroundImage && <div className={s.advantages__card__image} />}
    </div>
  );
};

export default React.memo(PremiumAdvantagesCard);
