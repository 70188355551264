import { Typography } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';

import s from './SubscriptionsBanner.module.scss';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/RootStore';
import { DateFormat } from 'entities/dateFormat';
import { formatDate } from 'utils/getFormattedDate';

type SubscriptionsBannerProps = {
  className?: string;
};

const SubscriptionsBanner = observer(
  ({ className }: SubscriptionsBannerProps): React.ReactElement<SubscriptionsBannerProps> => {
    const { tariffsStore, userStore } = useRootStore();

    const activeTariff = useMemo(() => {
      if (userStore.activeUserTariff) {
        const tariffInfo = tariffsStore.tariffs.items.find((item) => item.id === userStore.activeUserTariff?.tariff_id);
        console.log(tariffsStore.tariffs.items, userStore.activeUserTariff);
        return {
          ...userStore.activeUserTariff,
          tariffInfo: tariffInfo,
        };
      }
      return null;
    }, [tariffsStore.tariffs.items, userStore.activeUserTariff]);

    return activeTariff ? (
      <div className={cn(s.banner, className)}>
        <Typography.Text className={s.banner__subtitle}>Профессиональный игрок рынка</Typography.Text>
        <Typography.Title className={s.banner__title}>{activeTariff.tariffInfo?.name || ''}</Typography.Title>
        {activeTariff.tariffInfo && activeTariff.tariffInfo?.name !== 'Basic' && (
          <Typography.Text className={s.banner__description}>
            с {formatDate(activeTariff.modified_at, DateFormat.date)} до{' '}
            {formatDate(activeTariff.expired_at, DateFormat.date)}
          </Typography.Text>
        )}
      </div>
    ) : (
      <div />
    );
  },
);

export default SubscriptionsBanner;
