import * as React from 'react';

import { TariffsStore } from './TariffsStore';
import { useRootStore } from 'stores/RootStore';

type TariffsStoreContextValue = TariffsStore;

const TariffsStoreContextValueContext = React.createContext<TariffsStoreContextValue | null>(null);

export const TariffsStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const store = useRootStore();
  return (
    <TariffsStoreContextValueContext.Provider value={store.tariffsStore}>
      {children}
    </TariffsStoreContextValueContext.Provider>
  );
};

export const useTariffsStore = () => {
  const context = React.useContext(TariffsStoreContextValueContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
