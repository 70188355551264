import { Button, Input as AntInput, Space, TabsProps, Typography } from 'antd';
import cn from 'classnames';
import { E164Number } from 'libphonenumber-js/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import Input from 'react-phone-number-input/input';

import { Tabbar } from 'components/Tabbar';
import { GoogleIcon } from 'components/icons/GoogleIcon';
import { TelegramOutlinedIcon } from 'components/icons/TelegramOutlinedIcon';
import { VkOutlinedIcon } from 'components/icons/VkOutlinedIcon';
import { YandexIcon } from 'components/icons/YandexIcon';
import { LINKS } from 'config/links';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { AuthStep } from 'stores/AuthStore';
import { useRootStore } from 'stores/RootStore';

import 'react-phone-number-input/style.css';
import RegisterForm from '../RegisterForm/RegisterForm';

import s from './InitialStep.module.scss';

const InitialStep = () => {
  const { authStore } = useRootStore();
  const { phone, email, authorize, registerStage, register, authStep, mode, authType, checkCode, popupController } =
    authStore;
  const [error, setError] = React.useState<string | null>(null);
  const isInitialStep = authStep.value === AuthStep.initial;

  const handlePhoneChange = React.useCallback(
    (value?: E164Number | undefined) => {
      if (value) {
        phone.change(parsePhoneNumber(value)?.number || null);
      }
    },
    [phone],
  );

  const handleEmailChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      email.change(e.target.value);
    },
    [email],
  );

  const handleFormSubmit = React.useCallback(() => {
    if (mode.value === 'register') {
      return register().then((res) => {
        if (res.isError) {
          setError(res.data);
        }
      });
    }

    return authorize().then((res) => {
      if (res.isError) {
        setError(res.data);
      }
    });
  }, [mode.value, authorize, register]);

  useKeyboardEvent('Enter', handleFormSubmit, popupController.value);

  React.useEffect(() => {
    setError(null);
  }, [authStep.value, authType.value, mode.value]);

  const options: TabsProps['items'] = [
    {
      key: 'phone',
      label: 'Телефон',
      disabled: authType.value === 'email' && !isInitialStep,
      children:
        mode.value === 'register' ? (
          <RegisterForm />
        ) : (
          <Space
            direction="vertical"
            size={8}
            classNames={{ item: s.initial__step__space__item }}
            className={s.initial__step__space}
          >
            <div className={s.initial__step__phone__wrap}>
              <Input
                className={cn(s.initial__step__phone, phone.error && s.initial__step__phone_error)}
                international
                withCountryCallingCode
                countryCallingCodeEditable={false}
                onChange={handlePhoneChange}
                value={phone.value || undefined}
                country="RU"
                size="large"
                disabled={!isInitialStep}
              />
            </div>
            <Typography.Text type="danger" className={s.initial__step__error}>
              {phone.error}
            </Typography.Text>
          </Space>
        ),
    },
    {
      key: 'email',
      label: 'Почта',
      disabled: authType.value === 'phone' && !isInitialStep,
      children:
        mode.value === 'register' ? (
          <RegisterForm />
        ) : (
          <Space
            direction="vertical"
            size={8}
            classNames={{ item: s.initial__step__space__item }}
            className={s.initial__step__space}
          >
            <AntInput
              className={cn(s['initial__step__input'], s.initial__step__email)}
              status={email.isError ? 'error' : undefined}
              value={email.value || undefined}
              onChange={handleEmailChange}
              size="large"
              placeholder="Email"
              disabled={!isInitialStep}
            />
            <Typography.Text type="danger" className={s.initial__step__error}>
              {email.error}
            </Typography.Text>
          </Space>
        ),
    },
  ];

  return (
    <Space className={s.initial__step} direction="vertical" size={24}>
      <Typography.Title className={s.initial__step__title} level={4}>
        {mode.value === 'register' ? 'Регистрация ' : 'Войти '}в КупиЛот
      </Typography.Title>
      <Tabbar
        className={s.initial__step__tabbar}
        selected={authType.value}
        tabsOptions={options}
        onChange={(key) => authType.change(key === 'phone' ? 'phone' : 'email')}
        size="middle"
      />
      {error && (
        <Typography.Text type="danger" className={s.initial__step__error + '' + s.red}>
          {error}
        </Typography.Text>
      )}
      <Button
        type="primary"
        onClick={handleFormSubmit}
        className={s.initial__step__button}
        loading={registerStage.isLoading}
      >
        Получить код
      </Button>
      {/* <Typography.Text type="secondary">или</Typography.Text>
      <Space className={s.initial__step__social} direction="vertical" size={6}>
        <Button
          type="primary"
          className={cn(s.initial__step__button, s.initial__step__social__google)}
          onClick={() => {}}
          icon={<GoogleIcon className={cn(s.initial__step__social__icon, s.initial__step__social__icon_google)} />}
        >
          Войти через Google
        </Button>
        <Button
          type="primary"
          className={cn(s.initial__step__button, s.initial__step__social__yandex)}
          onClick={() => {}}
          icon={<YandexIcon className={cn(s.initial__step__social__icon, s.initial__step__social__icon_yandex)} />}
        >
          Войти через Yandex
        </Button>
        <Button
          type="primary"
          className={cn(s.initial__step__button, s.initial__step__social__vk)}
          onClick={() => {}}
          icon={<VkOutlinedIcon className={cn(s.initial__step__social__icon, s.initial__step__social__icon_vk)} />}
        >
          Войти через VK
        </Button>
        <Button
          type="primary"
          className={cn(s.initial__step__button, s.initial__step__social__telegram)}
          onClick={() => {}}
          icon={
            <TelegramOutlinedIcon
              className={cn(s.initial__step__social__icon, s.initial__step__social__icon_telegram)}
            />
          }
        >
          Войти через Telegram
        </Button>
      </Space> */}
      <Button
        type="link"
        className={s.initial__step__link}
        onClick={() => {
          mode.change(mode.value === 'register' ? 'login' : 'register');
        }}
      >
        {mode.value === 'register' ? 'Войти' : 'Зарегистрироваться'}
      </Button>
      <Typography.Text type="secondary">
        Нажимая на кнопку «Получить код» вы соглашаетесь с{' '}
        <Typography.Link className={s['initial__step__link']} href={LINKS.politics} target="_blank">
          политикой обработки персональных данных
        </Typography.Link>
      </Typography.Text>
    </Space>
  );
};

export default observer(InitialStep);
