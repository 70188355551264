import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { Input } from 'antd';
import s from './CadastralNumber.module.scss';

type CadastralNumberProps = {
  className?: string;
};

const CadastralNumber = ({}: CadastralNumberProps): React.ReactElement<CadastralNumberProps> => {
  const { filters } = useLotListStore();

  return (
    <BaseInput title="Кадастровый номер">
      <Input
        className={s['input']}
        placeholder={'Не выбрано'}
        value={filters.cadastralNumber.value}
        onChange={(e) => {
          filters.cadastralNumber.change(e.target.value);
        }}
      />
    </BaseInput>
  );
};

export default observer(CadastralNumber);
