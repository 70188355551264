import { TabsProps } from 'antd';
import cn from 'classnames';
import React from 'react';

import { Tabbar } from 'components/Tabbar';

import s from './SubscriptionsTabbar.module.scss';
import { TariffPlanType } from 'entities/tariffs';

type SubscriptionsTabbarProps = {
  className?: string;
  listType: TariffPlanType;
  setListType: (value: TariffPlanType) => void;
};

const SubscriptionsTabbar = ({ className, listType, setListType }: SubscriptionsTabbarProps): React.ReactElement => {
  const options: TabsProps['items'] = [
    {
      key: TariffPlanType.personal,
      label: 'Основные',
      children: null,
    },
    {
      key: TariffPlanType.corporate,
      label: 'Корпоративные',
      children: null,
    },
  ];

  return (
    <Tabbar
      className={cn(className, s.tabbar)}
      selected={listType}
      tabsOptions={options}
      onChange={(key: string) => setListType(key as TariffPlanType)}
      size="middle"
    />
  );
};

export default SubscriptionsTabbar;
