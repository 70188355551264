import { apiUrls } from 'config/apiUrls';
import { ResponseListApi } from 'entities';
import { IRobotModel, RobotCreateFilterModel } from 'entities/robot';
import { IReactionDisposer, computed, makeObservable, action } from 'mobx';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { RobotModel } from 'models/RobotModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { IUserStore } from 'stores/UserStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';
import { filterSerialize } from 'utils/filters';

type RobotStoreParams = {
  userStore: IUserStore;
};

export class RobotStore extends LocalStore {
  readonly robotFilters: ListModel<RobotModel, number> = new ListModel<RobotModel, number>();
  readonly robotName: ValueModel<string> = new ValueModel('');
  readonly robotItem: ValueModel<IRobotModel | null> = new ValueModel<IRobotModel | null>(null);
  readonly userStore: IUserStore;
  readonly robotViewIds: ValueModel<number[]> = new ValueModel<number[]>([]);

  private api = apiCustom;
  private abortController?: AbortController;
  private readonly disposers: IReactionDisposer[] = [];
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingAddViewStage: LoadingStageModel = new LoadingStageModel();

  constructor(params: RobotStoreParams) {
    super();

    this.userStore = params.userStore;

    makeObservable<this>(this, {
      isListInitialLoading: computed,
      currentTotalItems: computed,
      isLoadingRobot: computed,
      activeItemRobot: computed,
      fetchRobotItems: action,
      postRobotItemFetch: action,
      setRobotItem: action.bound,
    });
  }

  get activeItemRobot() {
    return this.robotItem.value;
  }

  get isListInitialLoading(): boolean {
    return this.robotFilters.isInitialLoading.value;
  }

  get currentTotalItems(): number {
    return this.robotFilters.total.value ?? 0;
  }

  get isLoadingRobot(): boolean {
    return this.loadingStage.isLoading;
  }

  setRobotItem(item: IRobotModel | null) {
    this.robotItem.change(item);
  }
  updateRobotItem(item: RobotModel) {
    this.robotFilters.removeEntity(item.id);
    this.robotFilters.addEntity({ entity: item, key: item.id });
  }
  putRobotItemFetch = async (
    id: number,
    filters: RobotCreateFilterModel,
    name: string,
  ): Promise<BaseResponse<boolean>> => {
    this.loadingStage.loading();
    try {
      const response = await this.api<IRobotModel>({
        url: `${apiUrls.lotListProfileFilters}${id}/`,
        method: 'PUT',
        data: {
          filters,
          name,
        },
      });

      if (response.isError) {
        throw new Error(response?.data ? JSON.stringify(response.data) : 'Delete robot item error');
      }

      this.loadingStage.success();
      this.updateRobotItem(new RobotModel(response.data));
      return { isError: false, data: true };
    } catch (e) {
      this.loadingStage.error();
      return { isError: true };
    }
  };

  deleteRobotItem(id: number) {
    this.robotFilters.removeEntity(id);
  }

  deleteRobotItemFetch = async (id: number): Promise<BaseResponse<boolean>> => {
    this.loadingStage.loading();
    try {
      const response = await this.api<ResponseListApi<IRobotModel>>({
        url: `${apiUrls.lotListProfileFilters}${id}/`,
        method: 'DELETE',
      });

      if (response.isError) {
        throw new Error(response?.data ? JSON.stringify(response.data) : 'Delete robot item error');
      }

      this.loadingStage.success();
      this.deleteRobotItem(id);
      return { isError: false, data: true };
    } catch (e) {
      this.loadingStage.error();
      return { isError: true };
    }
  };

  addRobotItem(item: RobotModel) {
    this.robotFilters.addEntity({ entity: item, key: item.id });
  }

  postRobotItemFetch = async (filters: RobotCreateFilterModel): Promise<BaseResponse<boolean>> => {
    this.loadingStage.loading();
    try {
      const response = await this.api<IRobotModel>({
        url: `${apiUrls.lotListProfileFilters}`,
        method: 'POST',
        data: {
          filters: filterSerialize(filters),
          name: this.robotName.value,
        },
      });

      if (response.isError) {
        throw new Error(response?.data ? JSON.stringify(response.data) : 'Create robot error');
      }

      this.loadingStage.success();
      this.addRobotItem(new RobotModel(response.data));
      return { isError: false, data: true };
    } catch (e) {
      this.loadingStage.error();
      return { isError: true };
    }
  };

  updateRobotItemNewLotsCount(item: RobotModel) {
    this.robotFilters.updateEntity({ entity: item });
    this.robotItem.change({ ...item });
  }

  addViewItemsRobot = async (id: number, lotsIdS: number[]): Promise<BaseResponse<RobotModel>> => {
    this.loadingAddViewStage.loading();
    try {
      const response = await this.api<RobotModel>({
        url: `${apiUrls.lotViewsAdd(id)}`,
        method: 'POST',
        data: {
          lots: lotsIdS,
        },
      });

      if (response.isError) {
        throw new Error(response?.data ? JSON.stringify(response.data) : 'Create robot error');
      }

      this.loadingAddViewStage.success();
      this.robotViewIds.change([...this.robotViewIds.value, ...lotsIdS]);
      this.updateRobotItemNewLotsCount(new RobotModel(response.data));
      return { isError: false, data: response.data };
    } catch (e) {
      this.loadingAddViewStage.error();
      return { isError: true };
    }
  };

  fetchRobotItems = async ({
    replace = false,
  }: {
    replace: boolean;
  }): Promise<BaseResponse<ListModel<RobotModel, number>>> => {
    if (replace) {
      this.robotFilters.setIsAllLoaded(false);
      this.robotFilters.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.robotFilters.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.robotFilters.loadingStage.loading();

    const response = await this.api<ResponseListApi<IRobotModel>>({
      url: `${apiUrls.lotListProfileFilters}?limit=10&offset=0`,
      method: 'GET',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.robotFilters.loadingStage.error();

      return { isError: true };
    }

    this.robotFilters.fillByRawData<IRobotModel>(
      response.data.results,
      (raw) => {
        const model = RobotModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.robotFilters.isInitialLoading.change(false);
    this.robotFilters.isReplaceLoading.change(false);
    this.robotFilters.total.change(response.data.results.length);
    this.robotFilters.setIsAllLoaded(true);
    this.robotFilters.loadingStage.success();

    return { isError: false, data: this.robotFilters };
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
