export const DEFAULT_INVEST_TEXT = 'Хотите инвестировать в объект? Получите оценку инвестиционной привлекательности.';
export const APPLICATION_SEND_INVEST_TEXT =
  'Заявка отправлена! Ваш запрос оценки инвестиционной привлекательности объекта находится в обработке. Скоро мы свяжемся с вами для уточнения деталей.';

export const DEFAULT_FOLLOW_TEXT =
  'Не хотите сами разбираться в деталях торгов? Закажите сопровождение сделки от экспертов по аукционам.';
export const APPLICATION_SEND_FOLLOW_TEXT =
  'Заявка отправлена! Мы уже работаем над вашей заявкой на сопровождение сделки. Ожидайте, скоро мы свяжемся с вами для уточнения деталей.';

import bgImageAppOne from 'assets/a1.png';
import bgImageAppTwo from 'assets/a2.png';
import bgImageAppThree from 'assets/a3.png';

export const BG_APP_IMAGES = [bgImageAppOne, bgImageAppTwo, bgImageAppThree];
