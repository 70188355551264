import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import s from './ProfileAsideMenu.module.scss';
import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';

type LinkType = {
  title: string;
  href: string;
};

const links = [
  {
    title: 'Избранное',
    href: `/profile/favorites`,
  },
  {
    title: 'Робот-помощник',
    href: `/profile/robots`,
  },
  {
    title: 'Пакеты услуг',
    href: '/profile/subscriptions',
  },
  {
    title: 'История просмотров',
    href: `/profile/history`,
  },
  {
    title: 'Настройки',
    href: `/profile/settings`,
  },
  {
    title: 'Мои услуги',
    activeKey: 'services',
    links: [
      {
        title: 'Сопровождение сделки',
        href: `/profile/services#escort`,
      },
      {
        title: 'Онлайн показ',
        href: `/profile/services#online`,
      },
      {
        title: 'Юридический аудит',
        href: `/profile/services#audit`,
      },
    ],
  },
  {
    title: 'Уведомления',
    href: `/profile/notification`,
  },
];

const ListItemDrop = ({
  title,
  links,
  activeKey = 'unset',
}: {
  title: string;
  links: LinkType[];
  activeKey?: string;
}) => {
  const { opened: isFiltersShown, toggle } = useToggleState();
  const ulRef = React.useRef<HTMLDivElement | null>(null);
  const { pathname, hash } = useLocation();

  React.useEffect(() => {
    if (ulRef && ulRef.current) {
      const isOpen = ulRef.current?.getAttribute('aria-hidden') || 'true';
      if (isOpen === 'false') {
        ulRef.current?.setAttribute('aria-hidden', 'true');
      } else {
        ulRef.current?.setAttribute('aria-hidden', 'false');
      }
    }
  }, [isFiltersShown]);

  return (
    <li>
      <div
        aria-hidden={isFiltersShown}
        className={`${s.aside__menu__list__item} ${s.aside__menu__list__item__action} ${pathname.includes(activeKey) ? s.active : ''}`}
        role="button"
        onClick={toggle}
      >
        <span className={`${s.aside__menu__link}`}>{title}</span>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.99975 4.35903L2.41427 6.94452C2.02374 7.33504 1.46972 7.41419 1.17683 7.1213C0.883935 6.8284 0.963081 6.27438 1.35361 5.88386L4.18203 3.05543C4.42532 2.81214 4.73207 2.6897 4.9996 2.70277C5.2672 2.6896 5.57408 2.81204 5.81746 3.05542L8.64589 5.88385C9.03641 6.27437 9.11556 6.82839 8.82266 7.12128C8.52977 7.41418 7.97575 7.33503 7.58523 6.94451L4.99975 4.35903Z"
            fill="black"
            fillOpacity="0.85"
          />
        </svg>
      </div>
      <div className={s.aside__menu__list__wrap__drop} aria-hidden={isFiltersShown} ref={ulRef}>
        <ul className={s.aside__menu__list__drop}>
          {links.map((link, index) => (
            <li
              className={`${s.aside__menu__list__item__drop} ${link.href === pathname + hash ? s.active : ''}`}
              key={index + '-' + link.href}
            >
              <Link to={link.href} className={`${s.aside__menu__link__drop}`} key={index + '-' + link.href}>
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export const ProfileAsideMenu = () => {
  const { pathname } = useLocation();
  const { authStore } = useRootStore();
  return (
    <ul className={s.aside__menu__list}>
      {links.map((item, index) =>
        item.href ? (
          <li className={`${s.aside__menu__list__item} ${pathname === item.href ? s.active : ''}`} key={item.href}>
            <Link to={item.href} className={`${s.aside__menu__link}`}>
              {item.title}
            </Link>
          </li>
        ) : (
          <ListItemDrop title={item.title} links={item.links || []} key={'link-' + index} activeKey={item.activeKey} />
        ),
      )}
      <li className={s.aside__menu__list__item}>
        <span className={s.aside__menu__link} role="button" onClick={authStore.logout}>
          Выйти
        </span>
      </li>
    </ul>
  );
};
