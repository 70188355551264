import React, { useMemo } from 'react';
import s from './ProfileNotification.module.scss';
import { observer } from 'mobx-react-lite';
import { Badge, Dropdown } from 'antd';
import cn from 'classnames';
import NotificationIcon from 'components/icons/NotificationIcon';
import { BankFilled, FileFilled, NotificationFilled } from '@ant-design/icons';
import { useRootStore } from 'stores/RootStore';
import { NotificationType } from 'entities/notification';

const ICONS = {
  [NotificationType.all]: <FileFilled />,
  [NotificationType.update_lot]: <BankFilled />,
  [NotificationType.service]: <FileFilled />,
  [NotificationType.sub]: <FileFilled />,
  [NotificationType.news]: <NotificationFilled />,
};

export const Notification = observer(({ onClick, count }: { onClick?: () => void; count: number }) => {
  return (
    <div className={cn(s.notifWrap)} onClick={onClick} role="button">
      <NotificationIcon />
      <Badge classNames={{ root: s.badge }} count={count} />
    </div>
  );
});

export const ProfileNotificationMenu = ({ menu, onClick }: { menu?: boolean; onClick?: () => void }) => {
  const { notificationStore } = useRootStore();

  const prerviews = useMemo(() => {
    const items = [];
    for (let i = 0; i < notificationStore.notifications.items.length; i++) {
      if (i > 5) {
        break;
      }
      items.push(notificationStore.notifications.items[i]);
    }

    return items;
  }, [notificationStore.notifications.items, notificationStore.currentTotalItems]);

  return menu ? (
    <Dropdown
      overlayClassName={cn(s['profile-notification-menu'])}
      menu={{
        items: [
          ...prerviews.map((item, index) => ({
            onClick: () => {
              onClick && onClick();
            },
            key: index,
            label: (
              <div className={cn(s.menuItem)}>
                {ICONS[item.type]}
                <span className={cn(s.menuItemText, item.is_read && s.unactive)}>{item.text}</span>
              </div>
            ),
          })),
          {
            key: 'all',
            onClick: () => {
              onClick && onClick();
            },
            label: (
              <div className={cn(s.menuItem, s.menuItemCenter)}>
                <span className={cn(s.menuItemText)}>Показать все</span>
              </div>
            ),
          },
        ],
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      aria-label="Меню"
    >
      <Notification onClick={undefined} count={notificationStore.currentTotalItems} />
    </Dropdown>
  ) : (
    <Notification onClick={onClick} count={notificationStore.currentTotalItems} />
  );
};

export default observer(ProfileNotificationMenu);
