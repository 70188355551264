import React, { useEffect, useMemo, useRef, useState } from 'react';

import LotCardSmall from 'components/lot/LotCardSmall/LotCardSmall';
import s from './LotsRecommended.module.scss';
import Slider from 'components/Slider';
import { Wrapper } from 'components/Wrapper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { observer } from 'mobx-react-lite';
import { useLotPageStore } from 'stores/LotPageStore';

export const LotsRecommended = observer(({ className = '', id }: { className?: string; id: number }) => {
  const { similarLots, fetchSimilarLotItems } = useLotPageStore();
  const [stopSwipe, setStopSwipe] = useState(true);
  const refInit = useRef(false);
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  const onTouchEnd = () => {
    setStopSwipe(true);
  };

  const onTouchStart = () => {
    setStopSwipe(false);
  };

  useEffect(() => {
    refInit.current = true;
  }, []);

  useEffect(() => {
    if (refInit.current) {
      fetchSimilarLotItems({ lotId: id, replace: true });
    }
  }, [id, refInit.current]);

  const slides = useMemo(() => {
    return similarLots.items.map((item, index) => (
      <LotCardSmall
        item={item}
        key={index}
        onTouchEndImageGallery={onTouchEnd}
        onTouchStartImageGallery={onTouchStart}
        similar
      />
    ));
  }, [similarLots.items, id, onTouchEnd, onTouchStart]);

  return !similarLots.loadingStage.isLoading && slides.length > 0 ? (
    <Wrapper className={s.lotsRecommendedWrap}>
      <h3 className={s.title}>Похожие лоты</h3>
      <div className={`${className} ${s.lotsRecommended}`}>
        <div className={s.navigation}>
          <button className={s.navigation__btn + ' ' + s.navigation__btn__prev + ' navigation__btn__prev'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_15_234)">
                <path
                  d="M14.4443 7.05871L7.85506 11.8266C7.82768 11.8463 7.80538 11.8722 7.79 11.9023C7.77462 11.9323 7.7666 11.9656 7.7666 11.9993C7.7666 12.0331 7.77462 12.0663 7.79 12.0964C7.80538 12.1264 7.82768 12.1524 7.85506 12.1721L14.4443 16.94C14.5863 17.0417 14.7845 16.94 14.7845 16.7659V15.5096C14.7845 15.2364 14.6533 14.9766 14.431 14.8158L10.5363 12.0007L14.431 9.18281C14.6533 9.0221 14.7845 8.76496 14.7845 8.48906V7.23281C14.7845 7.05871 14.5863 6.95692 14.4443 7.05871Z"
                  fill="currentColor"
                />
                <path
                  d="M12 0.00012207C5.37321 0.00012207 0 5.37334 0 12.0001C0 18.6269 5.37321 24.0001 12 24.0001C18.6268 24.0001 24 18.6269 24 12.0001C24 5.37334 18.6268 0.00012207 12 0.00012207ZM12 21.9644C6.49821 21.9644 2.03571 17.5019 2.03571 12.0001C2.03571 6.49834 6.49821 2.03584 12 2.03584C17.5018 2.03584 21.9643 6.49834 21.9643 12.0001C21.9643 17.5019 17.5018 21.9644 12 21.9644Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_15_234">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button className={s.navigation__btn + ' ' + s.navigation__btn__next + ' navigation__btn__next'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_189_14237)">
                <rect width="24" height="24" fill="white" fill-opacity="0.01" />
                <g clip-path="url(#clip1_189_14237)">
                  <path
                    d="M16.1433 11.8255L9.55405 7.05761C9.52203 7.03436 9.48419 7.02043 9.44473 7.01737C9.40528 7.0143 9.36575 7.02223 9.33052 7.04026C9.2953 7.05829 9.26575 7.08572 9.24516 7.11951C9.22457 7.15331 9.21374 7.19214 9.21387 7.23171V8.48796C9.21387 8.76118 9.34512 9.021 9.56744 9.18171L13.4621 11.9996L9.56744 14.8174C9.34512 14.9781 9.21387 15.2353 9.21387 15.5112V16.7674C9.21387 16.9415 9.41208 17.0433 9.55405 16.9415L16.1433 12.1737C16.2612 12.088 16.2612 11.9112 16.1433 11.8255Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12 0.00012207C5.37321 0.00012207 0 5.37334 0 12.0001C0 18.6269 5.37321 24.0001 12 24.0001C18.6268 24.0001 24 18.6269 24 12.0001C24 5.37334 18.6268 0.00012207 12 0.00012207ZM12 21.9644C6.49821 21.9644 2.03571 17.5019 2.03571 12.0001C2.03571 6.49834 6.49821 2.03584 12 2.03584C17.5018 2.03584 21.9643 6.49834 21.9643 12.0001C21.9643 17.5019 17.5018 21.9644 12 21.9644Z"
                    fill="currentColor"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_189_14237">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
                <clipPath id="clip1_189_14237">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <Slider
          className="swiper-custom-pagination"
          slides={slides}
          onSlideChange={() => {}}
          slidesPerView={2.3}
          spaceBetween={17}
          modules={[Navigation, Pagination]}
          pagination={pagination}
          allowTouchMove={stopSwipe}
          allowSlideNext={stopSwipe}
          allowSlidePrev={stopSwipe}
          navigation={{
            nextEl: '.navigation__btn__next',
            prevEl: '.navigation__btn__prev',
            disabledClass: 'swifalseper-elipse-button-disabled',
          }}
          breakpoints={{
            0: {
              spaceBetween: 0,
              slidesPerView: 1,
            },
            575: {
              spaceBetween: 8,
              slidesPerView: 1.3,
            },
            768: {
              spaceBetween: 8,
              slidesPerView: 2.2,
            },
            860: {
              spaceBetween: 8,
              slidesPerView: 2.5,
            },
            1280: {
              spaceBetween: 17,
              slidesPerView: 2.3,
              pagination: false,
            },
          }}
        />
      </div>
    </Wrapper>
  ) : (
    <div></div>
  );
});
