import { SearchOutlined } from '@ant-design/icons';
import { Input, Select, Spin, Typography } from 'antd';
import * as React from 'react';

import { FilterOption } from 'entities/lotFilters';
import { pluralizePreselected } from 'utils/pluralizer';

import { MultiSelectItem } from './MultiSelectItem';
import { SelectedItem } from './SelectedItem';

import s from './MultiSelect.module.scss';
import { observer } from 'mobx-react-lite';

type MultiSelectProps<T = number> = {
  options: FilterOption<T>[];
  selectedOptions: FilterOption<T>[];
  value: FilterOption<T>['id'][];
  onChange: (id: T, data?: FilterOption<T>) => void;
  isOptionsLoading: boolean;
  withSearch?: boolean;
  onSearch?: (value: string) => void;
  searchValue?: string;
  className?: string;
  placeholder?: string;
  hideSelectedList?: boolean;
  withActions?: boolean;
  dropdownStyle?: React.CSSProperties;
  dropdownMulti?: boolean;
  onSelectedAllCustom?: () => void;
  onClearSelectedCustom?: () => void;
};

const MultiSelect = <T,>({
  options,
  selectedOptions,
  value,
  onChange,
  isOptionsLoading,
  withSearch = true,
  onSearch,
  searchValue,
  className,
  placeholder = 'Не выбрано',
  hideSelectedList = false,
  withActions,
  dropdownStyle = {},
  dropdownMulti = false,
  onClearSelectedCustom,
  onSelectedAllCustom,
}: MultiSelectProps<T>): React.ReactElement<MultiSelectProps<T>> => {
  const keyId = React.useId();
  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearch?.(e.target.value);
    },
    [onSearch],
  );
  const onSelectedAll = () => {
    if (onSelectedAllCustom) {
      onSelectedAllCustom();
    } else {
      if (!isOptionsLoading) {
        options.forEach((item) => {
          onChange(item.id);
        });
      }
    }
  };
  const onClearSelected = () => {
    if (onClearSelectedCustom) {
      onClearSelectedCustom();
    } else {
      if (!isOptionsLoading) {
        options.forEach((item) => {
          if (value.includes(item.id)) {
            onChange(item.id);
          }
        });
      }
    }
  };
  return (
    <div className={className}>
      <Select
        className={s['multi-select__select']}
        dropdownStyle={{
          borderRadius: '8px',
          ...dropdownStyle,
        }}
        dropdownRender={() => (
          <>
            {withSearch && (
              <Input
                className={s['multi-select__search']}
                placeholder="Поиск"
                value={searchValue}
                onChange={handleSearch}
                suffix={<SearchOutlined className={s['multi-select__search-icon']} />}
                allowClear
              />
            )}
            {withActions && (
              <div className={s.actions}>
                <button className={s.btn} onClick={onSelectedAll}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3_82257)">
                      <path
                        d="M9.92183 4.51514H9.18902C9.02964 4.51514 8.87808 4.5917 8.78433 4.72295L6.32808 8.1292L5.21558 6.58545C5.12183 6.45576 4.97183 6.37764 4.81089 6.37764H4.07808C3.97652 6.37764 3.91714 6.49326 3.97652 6.57607L5.92339 9.27607C5.96938 9.34027 6.03001 9.39257 6.10026 9.42866C6.1705 9.46474 6.24833 9.48356 6.3273 9.48356C6.40627 9.48356 6.4841 9.46474 6.55434 9.42866C6.62458 9.39257 6.68521 9.34027 6.7312 9.27607L10.0218 4.71357C10.0828 4.63076 10.0234 4.51514 9.92183 4.51514Z"
                        fill="currentColor"
                        fillOpacity="0.85"
                      />
                      <path
                        d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z"
                        fill="currentColor"
                        fillOpacity="0.85"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_82257">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Выбрать все
                </button>
                <button className={s.btn} onClick={onClearSelected}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.80913 6.9993L11.9107 2.11024C11.9794 2.02899 11.9216 1.90555 11.8154 1.90555H10.5685C10.4951 1.90555 10.4248 1.93836 10.3763 1.99461L6.99351 6.02742L3.6107 1.99461C3.56382 1.93836 3.49351 1.90555 3.41851 1.90555H2.17163C2.06538 1.90555 2.00757 2.02899 2.07632 2.11024L6.17788 6.9993L2.07632 11.8884C2.06092 11.9065 2.05104 11.9286 2.04785 11.9522C2.04467 11.9757 2.04831 11.9997 2.05834 12.0213C2.06838 12.0428 2.08439 12.061 2.10447 12.0738C2.12455 12.0865 2.14786 12.0932 2.17163 12.093H3.41851C3.49195 12.093 3.56226 12.0602 3.6107 12.004L6.99351 7.97117L10.3763 12.004C10.4232 12.0602 10.4935 12.093 10.5685 12.093H11.8154C11.9216 12.093 11.9794 11.9696 11.9107 11.8884L7.80913 6.9993Z"
                      fill="currentColor"
                      fillOpacity="0.85"
                    />
                  </svg>
                  Сбросить
                </button>
              </div>
            )}
            <div className={`${s['multi-select__items-wrapper']} ${dropdownMulti ? s.left : ''}`}>
              {isOptionsLoading && <Spin size="small" />}
              {!isOptionsLoading && options.length === 0 && (
                <Typography.Text className={s['multi-select__item_placeholder']}>Нет доступных опций</Typography.Text>
              )}
              {!isOptionsLoading &&
                options.map((option) => (
                  <MultiSelectItem
                    key={option.id + '-' + keyId}
                    option={option}
                    onChange={onChange}
                    checked={value.includes(option.id)}
                    selected={value}
                    className={s['multi-select__item']}
                    dropdownMulti={dropdownMulti}
                  />
                ))}
            </div>
          </>
        )}
        placeholder={placeholder}
        value={
          selectedOptions.length > 0 ? `Выбрано ${pluralizePreselected(selectedOptions.length, 'значение')}` : null
        }
      />

      {selectedOptions.length > 0 && !hideSelectedList && (
        <div className={s['multi-select__selected-items-wrapper']}>
          {selectedOptions.map((option) => (
            <SelectedItem key={option.id as any} option={option} onChangeItem={onChange as any} />
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(MultiSelect);
