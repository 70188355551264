import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListProfileRobotStore } from 'stores/LotListProfileRobotStore/context';

type AuctionTypeProps = {
  className?: string;
};

const AuctionType = ({ className }: AuctionTypeProps): React.ReactElement<AuctionTypeProps> => {
  const { filters } = useLotListProfileRobotStore();

  return (
    <BaseInput className={className} title="Вид торгов">
      <MultiSelect
        options={filters.auctionType.options}
        selectedOptions={filters.auctionType.selectedOptions}
        value={filters.auctionType.selectedValue.value}
        onChange={filters.auctionType.toggleValue}
        onSearch={filters.auctionType.handleSearch}
        searchValue={filters.auctionType.searchValue.value}
        isOptionsLoading={filters.loadingStage.isLoading}
      />
    </BaseInput>
  );
};

export default observer(AuctionType);
