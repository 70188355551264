import { CheckCircleFilled } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import cn from 'classnames';
import * as React from 'react';

import { LINKS } from 'config/links';

import s from './FinalSuccessModal.module.scss';

type FinalSuccessModalProps = {
  opened: boolean;
  close: () => void;
  className?: string;
};

const FinalSuccessModal = ({
  opened,
  close,
  className,
}: FinalSuccessModalProps): React.ReactElement<FinalSuccessModalProps> => {
  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={cn(s.popup, className)}
      open={opened}
      footer={null}
      onCancel={close}
      width={362}
      centered
    >
      <Typography.Title className={s.popup__title} level={2}>
        Ваше сообщение отправлено.
        <br />
        Мы свяжемся с вами в ближайшее время
      </Typography.Title>
    </Modal>
  );
};

export default FinalSuccessModal;
