import { Spin } from 'antd';
import Breadcrumb, { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';

import s from './ProfileServicesPage.module.scss';
import { ProfileAsideMenu } from 'components/profile/ProfileAsideMenu/ProfileAsideMenu';
import { LayoutGridAside } from 'components/LayoutGridAside/LayoutGridAside';
import { TableEscort, TableAudit, TableOnline } from './tabels';
import { ProfileServicesStoreProvider } from 'stores/ProfileServicesStore/context';

type ProfileProps = {
  className?: string;
};

const ProfileServicesPage = ({ className }: ProfileProps): React.ReactElement<ProfileProps> => {
  const rootStore = useRootStore();
  const { hash } = useLocation();
  const refOne = React.useRef<HTMLDivElement | null>(null);
  const refTwo = React.useRef<HTMLDivElement | null>(null);
  const refThree = React.useRef<HTMLDivElement | null>(null);

  const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
    { title: 'Личный кабинет', href: routerUrls.profileSettings.mask },
    { title: 'Сопровождение сделки' },
  ];

  React.useEffect(() => {
    if (refOne.current && refThree.current && refTwo.current) {
      switch (hash) {
        case '#escort':
          refOne.current.scrollIntoView();
          break;
        case '#online':
          refTwo.current.scrollIntoView();
          break;
        case '#audit':
          refThree.current.scrollIntoView();
          break;
      }
    }
  }, [hash]);

  if (!rootStore.authStore.authorizingStage.isFinished) {
    return (
      <div className={s.profile__spinner}>
        <Spin size="large" />
      </div>
    );
  }

  if (!rootStore.userStore.authorized) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }

  return (
    <PageContainer withoutBottomPadding className={cn(s.profile, className)}>
      <Breadcrumb className={s.profile__breadcrumbs} itemRender={breadcrumbItemsRender} items={BREADCRUMB_ITEMS} />
      <ProfileServicesStoreProvider>
        <PageListTitle className={s.profile__title}>Анализ локации</PageListTitle>
        <LayoutGridAside aside={<ProfileAsideMenu />}>
          <div ref={refOne} id="escort">
            <TableEscort className={s.padding_zero} />
          </div>
          <div ref={refTwo} id="online">
            <TableOnline />
          </div>
          <div ref={refThree} id="audit">
            <TableAudit />
          </div>
        </LayoutGridAside>
      </ProfileServicesStoreProvider>
    </PageContainer>
  );
};

export default observer(ProfileServicesPage);
