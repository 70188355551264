import React from 'react';

import PremiumSlider from '../PremiumSlider/PremiumSlider';

import PremiumPriceCard from './PremiumPriceCard/PremiumPriceCard';

import s from './PremiumPriceCards.module.scss';
import { useRootStore } from 'stores/RootStore';
import { notification } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { TariffPricingBillingCycle } from 'entities/tariffs';

interface PremiumPriceCardsProps {
  isPhone: boolean;
}

const key = 'PremiumPriceCards';

const PremiumPriceCards = ({ isPhone }: PremiumPriceCardsProps) => {
  const { tariffsStore, userStore } = useRootStore();

  const [api, contextHolder] = notification.useNotification();

  const notificationShow = (title: string, description: string, isError?: boolean) => {
    api.info({
      message: title,
      description: description,
      placement: 'topRight',
      icon: isError ? <WarningFilled style={{ color: 'red' }} /> : <CheckCircleFilled style={{ color: 'green' }} />,
      key,
    });
  };

  const onSubscription = (option_type: TariffPricingBillingCycle) => {
    const premium = tariffsStore.tariffs.items.find((item) => item.name === 'Premium');
    const option = (premium?.pricing_options || []).find((item) => item.billing_cycle === option_type);
    if (userStore.user?.userId && premium && option) {
      tariffsStore
        .subscriptionTariff({
          user: userStore.user?.userId,
          plan_option: option.id,
          tariff: premium.id,
        })
        .then((res) => {
          if (!res.isError) {
            window.location.href = res.data.payment_url;
          } else {
            notificationShow('Ошибка!', res?.data || 'Error 500', true);
          }
        });
    }
  };
  return isPhone ? (
    <div>
      {contextHolder}
      <div className={s.price__wrapper}>
        <div className={s.price__info}>
          <h2 className={s.price__title}>Стоимость подписки</h2>
          <p className={s.price__subtitle}>Экономия 36%</p>
          <p className={s.price__subtitle}>Вы экономите 28 000 ₽</p>
        </div>
        <div className={s.price__cost}>
          <h3 className={s.price__cost__item}>Месяц: 6 500 ₽</h3>
          <p className={s.price__cost__item}>Год: 50 000 ₽ (скидка 36% при оплате за год)</p>
        </div>
      </div>
      <PremiumSlider>
        <PremiumPriceCard
          title="Оформить подписку на месяц"
          description="Откройте для себя все возможности премиум аккаунта уже сейчас!"
          actionText="Купить за 6 500 ₽/месяц"
          action={() => onSubscription(TariffPricingBillingCycle.month)}
        />
        <PremiumPriceCard
          title="Оформить подписку на год"
          description="Год доступа к премиум привилегиям со скидкой 36%!"
          actionText="Купить за 50 000 ₽/год (скидка 36%)"
          action={() => onSubscription(TariffPricingBillingCycle.year)}
        />
      </PremiumSlider>
    </div>
  ) : (
    <div className={s.price}>
      {contextHolder}
      <div className={s.price__wrapper}>
        <div className={s.price__info}>
          <h2 className={s.price__title}>Стоимость подписки</h2>
          <p className={s.price__subtitle}>Экономия 36%</p>
          <p className={s.price__subtitle}>Вы экономите 28 000 ₽</p>
        </div>
        <div className={s.price__cost}>
          <h3 className={s.price__cost__item}>Месяц: 6 500 ₽</h3>
          <p className={s.price__cost__item}>Год: 50 000 ₽ (скидка 36% при оплате за год)</p>
        </div>
      </div>
      <div className={s.price__cards}>
        <PremiumPriceCard
          title="Оформить подписку на месяц"
          description="Откройте для себя все возможности премиум аккаунта уже сейчас!"
          actionText="Купить за 6 500 ₽/месяц"
          action={() => onSubscription(TariffPricingBillingCycle.month)}
        />
        <PremiumPriceCard
          title="Оформить подписку на год"
          description="Год доступа к премиум привилегиям со скидкой 36%!"
          actionText="Купить за 50 000 ₽/год (скидка 36%)"
          action={() => onSubscription(TariffPricingBillingCycle.year)}
        />
      </div>
    </div>
  );
};

export default PremiumPriceCards;
