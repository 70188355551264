import * as React from 'react';

import { BaseIconProps } from '../commonProps';

const CloseRoundIcon = (props: BaseIconProps): React.ReactElement<BaseIconProps> => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none" viewBox="0 0 11 11" {...props}>
      <g clipPath="url(#clip0_2028_10717)">
        <path
          d="M5.26185 1.0188C2.87744 1.0188 0.944092 2.95215 0.944092 5.33656C0.944092 7.72096 2.87744 9.65431 5.26185 9.65431C7.64625 9.65431 9.57961 7.72096 9.57961 5.33656C9.57961 2.95215 7.64625 1.0188 5.26185 1.0188ZM6.85595 6.97692L6.21985 6.97403L5.26185 5.83194L4.30481 6.97306L3.66775 6.97595C3.62534 6.97595 3.59065 6.94222 3.59065 6.89885C3.59065 6.88054 3.59739 6.86319 3.60896 6.84873L4.86284 5.35487L3.60896 3.86196C3.59731 3.84784 3.59085 3.83015 3.59065 3.81185C3.59065 3.76944 3.62534 3.73474 3.66775 3.73474L4.30481 3.73764L5.26185 4.87972L6.21889 3.7386L6.85499 3.73571C6.89739 3.73571 6.93209 3.76944 6.93209 3.81281C6.93209 3.83112 6.92534 3.84847 6.91378 3.86293L5.66182 5.35583L6.91474 6.8497C6.92631 6.86416 6.93305 6.8815 6.93305 6.89982C6.93305 6.94222 6.89836 6.97692 6.85595 6.97692Z"
          fill="black"
          fillOpacity="0.25"
        />
      </g>
      <defs>
        <clipPath id="clip0_2028_10717">
          <rect width="9.86916" height="9.86916" fill="white" transform="translate(0.327148 0.401855)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseRoundIcon;
