import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ListWithFilters as ListWithFiltersBase } from 'components/ListWithFilters';
import { ProfileListType } from 'config/routes';
import { DEFAULT_EMPTY_TEXT } from 'pages/LotsListPage/LotsList/LotsList';
import { LotsList } from 'pages/Profile/LotsList';
import { emptyListPlaceholderOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

import { SideFilters } from '../../SideFilters';
import { Sort } from '../../Sort';

type SubscriptionsListProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const SubscriptionsList = ({
  isFiltersCollapsed,
  className,
}: SubscriptionsListProps): React.ReactElement<SubscriptionsListProps> => {
  const { subscriptionLots, filters } = useLotListProfileStore();
  const isEmpty = subscriptionLots.isEmpty && filters.isEmptySideFilters && !subscriptionLots.loadingStage.isLoading;

  const emptyText = isEmpty ? emptyListPlaceholderOptions[ProfileListType.subs] : DEFAULT_EMPTY_TEXT;

  return (
    <ListWithFiltersBase
      className={className}
      SideFilters={SideFilters}
      sortComponent={<Sort />}
      listComponent={<LotsList lots={subscriptionLots} emptyText={emptyText} />}
      isListEmpty={subscriptionLots.isEmpty}
      isListTotalEmpty={subscriptionLots.totalPure.value === 0 && !subscriptionLots.isInitialLoading.value}
      isFilterOptionsEmpty={filters.isEmptySideFiltersOptions}
      isFiltersCollapsed={isFiltersCollapsed}
      isProfile
    />
  );
};

export default observer(SubscriptionsList);
