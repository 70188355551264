import { apiUrls } from 'config/apiUrls';
import { ResponseListApi } from 'entities';
import { INotification, INotificationSubscribe, INotivicationSubscribeUpdateBody } from 'entities/notification';
import { IReactionDisposer, computed, makeObservable, reaction, observable, action } from 'mobx';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { NotificationModel, NotificationSubscribeModel } from 'models/NotificationModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';

type Direction = 'asc' | 'desc';

export class ProfileNotificationSubscribeStore extends LocalStore {
  readonly notificationsSettings: ListModel<NotificationSubscribeModel, number> = new ListModel<
    NotificationSubscribeModel,
    number
  >();
  private readonly disposers: IReactionDisposer[] = [];
  readonly loadingNotificationUpdateStage: LoadingStageModel = new LoadingStageModel();
  private api = apiCustom;
  private abortController?: AbortController;

  constructor() {
    super();

    makeObservable<this>(this, {
      updateNotificationSubscripeItem: action.bound,
      putNotivicationSubscripe: action.bound,
      fetchNotificationSubscribeItems: action,
    });
  }

  updateNotificationSubscripeItem = (entity: NotificationSubscribeModel) => {
    this.notificationsSettings.updateEntity({ entity });
  };

  putNotivicationSubscripe = async (
    data: NotificationSubscribeModel,
  ): Promise<BaseResponse<INotivicationSubscribeUpdateBody, string>> => {
    try {
      this.loadingNotificationUpdateStage.loading();
      const response = await this.api<INotivicationSubscribeUpdateBody>({
        url: `${apiUrls.notificationsSettingsUpdate(data.id)}`,
        method: 'PUT',
        data,
      });

      if (response.isError) {
        throw new Error('Error api delete');
      }
      this.loadingNotificationUpdateStage.success();
      return { isError: false, data: response.data };
    } catch (e) {
      this.loadingNotificationUpdateStage.error();
      return { isError: true, data: 'Error update notification' };
    }
  };

  fetchNotificationSubscribeItems = async ({ replace = true }: { replace: boolean }) => {
    if (replace) {
      this.notificationsSettings.setIsAllLoaded(false);
      this.notificationsSettings.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.notificationsSettings.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.notificationsSettings.loadingStage.loading();

    const response = await this.api<INotificationSubscribe[]>({
      url: `${apiUrls.notificationsSettings}`,
      method: 'GET',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.notificationsSettings.loadingStage.error();

      return { isError: true };
    }

    this.notificationsSettings.fillByRawData<INotificationSubscribe>(
      response.data,
      (raw) => {
        const model = NotificationSubscribeModel.fromJson(raw);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.notificationsSettings.isInitialLoading.change(false);
    this.notificationsSettings.isReplaceLoading.change(false);
    this.notificationsSettings.total.change(response.data.length);
    this.notificationsSettings.totalPure.change(response.data.length);
    this.notificationsSettings.loadingStage.success();

    return {
      isError: false,
      data: this.notificationsSettings,
    };
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}

export class ProfileNotificationStore extends LocalStore {
  readonly notifications: ListModel<NotificationModel, number> = new ListModel<NotificationModel, number>();
  _selected: { [key: number | string]: number | string } = {};
  private api = apiCustom;
  private abortController?: AbortController;
  private readonly disposers: IReactionDisposer[] = [];
  readonly sortDirectionDate: ValueModel<Direction> = new ValueModel<Direction>('asc');
  readonly sortDirectionTime: ValueModel<Direction> = new ValueModel<Direction>('asc');
  readonly offset = new ValueModel<number>(0);
  readonly notificationsNewCount = new ValueModel<number>(0);
  readonly loadingNotificationNewCountStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingNotificationReadStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingNotificationDeleteStage: LoadingStageModel = new LoadingStageModel();

  constructor() {
    super();

    makeObservable<this>(this, {
      _selected: observable,
      toggleSelected: action,
      setAllSelected: action,
      resetSelected: action,
      isListInitialLoading: computed,
      currentTotalItems: computed,
      selected: computed,
    });

    this.disposers.push(
      reaction(
        () => this.sortDirectionDate.value,
        () => this.fetchNotificationItems({ replace: true }),
      ),
      reaction(
        () => this.sortDirectionTime.value,
        () => this.fetchNotificationItems({ replace: true }),
      ),
      reaction(
        () => this.offset.value,
        () => this.fetchNotificationItems({ replace: true }),
      ),
    );
  }

  get selected(): Array<string | number> {
    return Object.values(this._selected);
  }
  get isListInitialLoading(): boolean {
    return this.notifications.isInitialLoading.value;
  }

  get currentTotalItems(): number {
    return this.notificationsNewCount.value;
  }

  toggleSelected = (id: string | number, active: boolean): void => {
    if (active) {
      this._selected[id] = id;
    } else {
      delete this._selected[id];
    }
  };

  setAllSelected = (selected: boolean) => {
    if (selected) {
      this.notifications.keys.forEach((item) => {
        this._selected[item] = item;
      });
    } else {
      for (let key in this._selected) delete this._selected[key];
    }
  };

  resetSelected = () => {
    this._selected = {};
  };

  fetchUnReadNotification = async (ids: number[]) => {
    try {
      this.loadingNotificationReadStage.loading();
      const response = await this.api<INotification[]>({
        url: `${apiUrls.notificationsSetUnread}`,
        method: 'POST',
        data: { notifications: ids },
      });
      if (response.isError) {
        throw new Error('Error api unset_read');
      }
      this.loadingNotificationNewCountStage.success();
      this.getNotificationsNewCount();
      ids.forEach((id) => {
        const entity = this.notifications.getEntity(id);
        if (entity) {
          this.notifications.updateEntity({
            entity: new NotificationModel({
              ...entity,
              is_read: false,
            }),
          });
        }
      });
    } catch (e) {
      this.loadingNotificationNewCountStage.error();
    }
  };

  fetchReadNotification = async (ids: number[]) => {
    try {
      this.loadingNotificationReadStage.loading();
      const response = await this.api<INotification[]>({
        url: `${apiUrls.notificationsSetRead}`,
        method: 'POST',
        data: { notifications: ids },
      });
      if (response.isError) {
        throw new Error('Error apш set_read');
      }
      this.loadingNotificationNewCountStage.success();
      this.getNotificationsNewCount();
      ids.forEach((id) => {
        const entity = this.notifications.getEntity(id);
        if (entity) {
          this.notifications.updateEntity({
            entity: new NotificationModel({
              ...entity,
              is_read: true,
            }),
          });
        }
      });
    } catch (e) {
      this.loadingNotificationNewCountStage.error();
    }
  };

  getNotificationsNewCount = async () => {
    try {
      this.loadingNotificationNewCountStage.loading();
      const response = await this.api<{ new_notifications_count: number }>({
        url: `${apiUrls.notificationsCount}`,
        method: 'GET',
      });
      if (response.isError) {
        throw new Error('Error apu new_count');
      }
      this.loadingNotificationNewCountStage.success();
      this.notificationsNewCount.change(response.data.new_notifications_count);
    } catch (e) {
      this.loadingNotificationNewCountStage.error();
    }
  };

  deleteNotification = async (ids: number[]) => {
    try {
      this.loadingNotificationDeleteStage.loading();
      const response = await this.api<INotification[]>({
        url: `${apiUrls.notificationsDelete}`,
        method: 'POST',
        data: { notifications: ids },
      });

      if (response.isError) {
        throw new Error('Error api delete');
      }
      this.loadingNotificationDeleteStage.success();

      Promise.all([this.getNotificationsNewCount(), this.fetchNotificationItems({ replace: true })]);

      return { isError: false };
    } catch (e) {
      this.loadingNotificationDeleteStage.error();
      return { isError: true };
    }
  };

  fetchNotificationItems = async ({ replace = false }: { replace: boolean }) => {
    if (replace) {
      this.notifications.setIsAllLoaded(false);
      this.notifications.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.notifications.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.notifications.loadingStage.loading();
    const ordering = [];

    ordering.push(this.sortDirectionDate.value === 'asc' ? 'date' : '-date');
    ordering.push(this.sortDirectionTime.value === 'asc' ? 'time' : '-time');

    const response = await this.api<ResponseListApi<INotification>>({
      url: `${apiUrls.notifications}?limit=10&offset=${this.offset.value}&ordering=${ordering.join(',')}`,
      method: 'GET',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.notifications.loadingStage.error();

      return { isError: true };
    }

    this.notifications.fillByRawData<INotification>(
      response.data.results,
      (raw) => {
        const model = NotificationModel.fromJson(raw);

        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.notifications.isInitialLoading.change(false);
    this.notifications.isReplaceLoading.change(false);
    this.notifications.total.change(response.data.count);
    this.notifications.totalPure.change(response.data.count);
    this.notifications.loadingStage.success();

    return {
      isError: false,
      data: this.notifications,
    };
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
