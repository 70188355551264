import { observer, useLocalStore } from 'mobx-react-lite';
import * as React from 'react';

import useToggleState from 'hooks/useToggleState';
import { useRootStore } from 'stores/RootStore';
import { SupportFormStore } from 'stores/SupportFormStore';

import { FinalSuccessModal } from './FinalSuccessModal';
import { FormModal } from './FormModal';
import { MessageFormStore } from 'stores/MessageFormStore';

type SupportModalProps = {
  opened: boolean;
  close: () => void;
  className?: string;
};

const SupportModal = ({ opened, close, className }: SupportModalProps): React.ReactElement<SupportModalProps> => {
  const rootStore = useRootStore();
  const supportFormStore = useLocalStore(() => new MessageFormStore({ rootStore }));

  const { fields, submitStage, submitForm } = supportFormStore;
  const { name, email, message, hasErrors, isValidateOnChange, maxDescriptionLength } = fields;

  const {
    open: openFinalSuccessModal,
    close: closeFinalSuccessModal,
    opened: openedFinalSuccessModal,
  } = useToggleState();

  const handleFormSubmit = React.useCallback(async () => {
    const response = await submitForm();

    if (!response.isError) {
      close();
      openFinalSuccessModal();
      fields.resetAfterSubmission();
    }
  }, [close, openFinalSuccessModal, fields]);

  React.useEffect(() => {
    fields.name.change(rootStore.userStore.user?.userId.toString() || 'unset');
    fields.email.change(rootStore.userStore.user?.email?.toString() || 'unset');
  }, []);

  return (
    <div className={className} style={{ display: 'none' }}>
      <FormModal
        opened={opened}
        close={close}
        onSubmit={handleFormSubmit}
        name={name}
        email={email}
        description={message}
        maxDescriptionLength={maxDescriptionLength}
        submitStage={submitStage}
        hasErrors={hasErrors}
        isValidateOnChange={isValidateOnChange}
      />
      <FinalSuccessModal opened={openedFinalSuccessModal} close={closeFinalSuccessModal} />
    </div>
  );
};

export default observer(SupportModal);
