import { apiUrls } from 'config/apiUrls';
import { ResponseListApi } from 'entities';
import { FilterOption } from 'entities/lotFilters';
import { UserServer } from 'entities/user';
import { IReactionDisposer, computed, makeAutoObservable, reaction, action, runInAction } from 'mobx';
import { FilterModelMulti } from 'models/FilterModelMulti';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ProfileSettingsCardBankModel } from 'models/ProfileSettingsCardBankModel';
import {
  ProfileSettingsPasswordFormModel,
  ProfileSettingsPersonalDataFormModel,
} from 'models/ProfileSettingsFormModel';
import { UserModel } from 'models/UserModel';
import { ProfileNotificationSubscribeStore } from 'stores/ProfileNotificationStore';
import { IRootStore } from 'stores/RootStore';
import { ILocalStore } from 'types/ILocalStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';

type ProfileSettinsStoreParams = {
  rootStore: IRootStore;
};

export class ProfileSettingsStore implements ILocalStore {
  readonly fieldsPresonalData: ProfileSettingsPersonalDataFormModel = new ProfileSettingsPersonalDataFormModel();
  readonly fieldsPassword: ProfileSettingsPasswordFormModel = new ProfileSettingsPasswordFormModel();
  readonly submitPersonalDataStage: LoadingStageModel = new LoadingStageModel();
  readonly submitPasswordStage: LoadingStageModel = new LoadingStageModel();
  readonly submitAddCardBankStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingCitiesStage: LoadingStageModel = new LoadingStageModel();
  readonly fieldsBank: ProfileSettingsCardBankModel = new ProfileSettingsCardBankModel();
  readonly profileNotification: ProfileNotificationSubscribeStore;
  private readonly city = new FilterModelMulti();
  private readonly rootStore: IRootStore;
  private api = apiCustom;
  private readonly disposers: IReactionDisposer[] = [];

  constructor({ rootStore }: ProfileSettinsStoreParams) {
    makeAutoObservable<this>(
      this,
      {
        submitPersonalDataForm: action.bound,
        submitAddCardBankForm: action.bound,
        submitPasswordForm: action.bound,
        citySelected: computed,
        isCityOptionsLoading: computed,
        cityOptions: computed,

        changeCity: action,
      },
      { autoBind: true },
    );

    this.rootStore = rootStore;
    this.profileNotification = rootStore.profileNotificationSubscribe;

    if (this.rootStore.userStore.user?.email) {
      this.fieldsPresonalData.email.change(this.rootStore.userStore.user.email);
    }

    if (this.rootStore.userStore.user?.phone) {
      this.fieldsPresonalData.phone.change(this.rootStore.userStore.user.phone.replace('+7', ''));
    }

    if (this.rootStore.userStore.user?.firstName) {
      this.fieldsPresonalData.firstName.change(this.rootStore.userStore.user.firstName);
    }

    if (this.rootStore.userStore.user?.lastName) {
      this.fieldsPresonalData.lastName.change(this.rootStore.userStore.user.lastName);
    }

    if (this.rootStore.userStore.user?.patronymic) {
      this.fieldsPresonalData.patronymic.change(this.rootStore.userStore.user.patronymic);
    }

    if (this.rootStore.userStore.user?.region) {
      this.fieldsPresonalData.region.change(this.rootStore.userStore.user.region);
    }

    if (this.rootStore.userStore.user?.patronymic) {
      this.fieldsPresonalData.patronymic.change(this.rootStore.userStore.user.patronymic);
    }

    this.disposers.push(
      reaction(
        () => this.rootStore.userStore.user?.email,
        (email) => this.fieldsPresonalData.email.change(email ?? ''),
      ),
    );
  }

  get citySelected(): null | number {
    return this.city.selectedOptions[0] ? this.city.selectedOptions[0].id : null;
  }

  get isCityOptionsLoading(): boolean {
    return this.loadingCitiesStage.isLoading;
  }

  get cityOptions(): FilterOption[] {
    return this.city.options;
  }

  changeCity = (id: number | null): void => {
    this.city.setSelected(id ? [id] : null);
  };

  async submitAddCardBankForm(): Promise<BaseResponse> {
    const data = {
      name: this.fieldsBank.name.value,
      year: this.fieldsBank.year.value,
      month: this.fieldsBank.month.value,
      number: this.fieldsBank.number.value,
      cvs: this.fieldsBank.cvs.value,
    };

    if (this.submitAddCardBankStage.isLoading) {
      return { isError: true };
    }

    this.fieldsBank.setIsValidateOnChange(true);

    if (this.fieldsBank.validate()) {
      return { isError: true };
    }

    this.submitPersonalDataStage.loading();
    console.log(data);
    return runInAction(() => {
      //TODO: CHANGE TO REAL DATA
      if (false) {
        this.submitAddCardBankStage.error();

        return { isError: true };
      }

      this.submitAddCardBankStage.success();
      this.fieldsBank.resetFull();
      return { isError: false };
    });
  }

  async submitPasswordForm(): Promise<BaseResponse> {
    const data = {
      password: this.fieldsPassword.password.value,
      newPassword: this.fieldsPassword.newPassword.value,
    };

    if (this.submitPasswordStage.isLoading) {
      return { isError: true };
    }

    this.fieldsPassword.setIsValidateOnChange(true);

    if (this.fieldsPassword.validate()) {
      return { isError: true };
    }

    this.submitPersonalDataStage.loading();
    console.log(data);
    return runInAction(() => {
      //TODO: CHANGE TO REAL DATA
      if (false) {
        this.submitPasswordStage.error();

        return { isError: true };
      }

      this.submitPasswordStage.success();
      this.fieldsPassword.resetFull();
      return { isError: false };
    });
  }

  async submitPersonalDataForm(): Promise<BaseResponse<UserServer, string>> {
    try {
      const data = {
        first_name: this.fieldsPresonalData.firstName.value,
        email: this.fieldsPresonalData.email.value,
        phone: this.fieldsPresonalData.phone.value
          ? '+7' + this.fieldsPresonalData.phone.value.replace(/[^0-9]/g, '')
          : '',
        last_name: this.fieldsPresonalData.lastName.value,
        patronymic: this.fieldsPresonalData.patronymic.value,
      };

      if (this.submitPersonalDataStage.isLoading) {
        throw new Error('Данные еще проходят оправку');
      }

      this.fieldsPresonalData.setIsValidateOnChange(true);

      if (this.fieldsPresonalData.validate()) {
        throw new Error('Проверьте верно ли заполнены все поля');
      }

      this.submitPersonalDataStage.loading();

      const response = await apiCustom<UserServer>({
        url: apiUrls.editUser,
        method: 'POST',
        data,
      });

      return runInAction(() => {
        if (response.isError) {
          this.submitPersonalDataStage.error();
          throw new Error('Ошибка при обновлении данных. Попробуйте через время.');
        }

        this.submitPersonalDataStage.success();
        if (this.rootStore.userStore.user) {
          this.rootStore.userStore.setUser(UserModel.fromJson(response.data));
        }

        return { isError: false, data: response.data as UserServer };
      });
    } catch (e) {
      return { isError: true, data: (e as Error).message };
    }
  }

  fetchCities = async (args: { append?: boolean }): Promise<BaseResponse | void> => {
    if (this.loadingCitiesStage.isLoading) {
      return;
    }

    this.loadingCitiesStage.loading();

    const response = await this.api<ResponseListApi<{ id: number; name: string }>>({
      method: 'GET',
      url: apiUrls.lotListCityFilters + `?limit=10&offset=${this.city.options.length}`,
    });

    if (response.isError) {
      this.loadingCitiesStage.error();
    } else {
      const dataResult = response.data.results.map((item) => ({ id: item.id, title: item.name }));
      if (args.append === true) {
        this.city.addOptionsToEnd(dataResult);
      } else {
        this.city.setOptions(dataResult);
      }
      this.loadingCitiesStage.success();
    }

    return response;
  };

  destroy(): void {
    this.disposers.forEach((disposer) => disposer());
  }
}
