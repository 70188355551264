import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

export const activeButtonConfigs: Record<
  'active' | 'unactive',
  { icon: React.ComponentType; tooltipText: string; buttonText: string }
> = {
  active: {
    icon: InfoCircleFilled,
    tooltipText: 'Сообщить об ошибке',
    buttonText: 'Сообщить об ошибке',
  },
  unactive: {
    icon: InfoCircleOutlined,
    tooltipText: 'Сообщить об ошибке',
    buttonText: 'Подписаться',
  },
};
