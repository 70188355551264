import { action, computed, makeObservable, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { UserServer } from 'entities/user';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ToggleModel } from 'models/ToggleModel';
import { UserEditModel } from 'models/UserEditModel';
import { UserModel } from 'models/UserModel';
import { ValueModel } from 'models/ValueModel';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';

import { IUserStore } from './types';
import { ListModel } from 'models/ListModel';
import { TariffUserModel } from 'models/TariffModel';
import { ITariffUserData, TariffUserStatus } from 'entities/tariffs';

export class UserStore implements IUserStore {
  private _userModel: ValueModel<UserModel | null> = new ValueModel<UserModel | null>(null);
  readonly tariffsUser: ListModel<TariffUserModel, number> = new ListModel<TariffUserModel, number>();
  readonly userEditModel = new UserEditModel();
  readonly editStage: LoadingStageModel = new LoadingStageModel();
  readonly emailPopupController: ToggleModel = new ToggleModel();

  constructor() {
    makeObservable(this, {
      user: computed,
      authorized: computed,

      setUser: action.bound,
      editUser: action.bound,
      getUsersTariffs: action.bound,
    });
  }

  get user(): UserModel | null {
    return this._userModel.value;
  }

  get authorized(): boolean {
    return this._userModel.value !== null;
  }

  get activeUserTariff(): TariffUserModel | null {
    return this.tariffsUser.items.find((item) => item.status === TariffUserStatus.active) || null;
  }

  resetUser = () => {
    this.setUser(null);
    this.userEditModel.reset();
  };

  closeEmailPopup = () => {
    this.emailPopupController.close();
    this.userEditModel.reset();
  };

  setUser(user: UserModel | null) {
    this._userModel.change(user);
  }

  async editUser(): Promise<BaseResponse> {
    if (this.editStage.isLoading) {
      return { isError: true };
    }

    if (this.userEditModel.validate()) {
      return { isError: true };
    }

    this.editStage.loading();

    const response = await apiCustom<UserServer>({
      url: apiUrls.editUser,
      method: 'POST',
      data: {
        email: this.userEditModel.email.value,
      },
    });

    return runInAction(() => {
      if (response.isError) {
        this.editStage.error();

        if (response.data?.responseCode === 409) {
          this.userEditModel.email.changeError(
            'Почта с таким адресом уже занята. Введите другую или обратитесь в поддержку.',
          );
        }

        return { isError: true };
      }

      const updatedUser = UserModel.fromJson(response.data);
      this.setUser(updatedUser);

      this.closeEmailPopup();
      this.editStage.success();

      return { isError: false };
    });
  }

  async getUsersTariffs({ replace = true }): Promise<BaseResponse<ListModel<TariffUserModel, number>, string>> {
    if (replace) {
      this.tariffsUser.setIsAllLoaded(false);
      this.tariffsUser.isReplaceLoading.change(true);
    }

    this.tariffsUser.loadingStage.loading();

    const response = await apiCustom<ITariffUserData[]>({
      url: `${apiUrls.tariffsUser}?limit=100&offset=0`,
      method: 'GET',
    });

    if (response.isError) {
      this.tariffsUser.loadingStage.error();

      return { isError: true, data: response.data?.message || 'Error' };
    }

    this.tariffsUser.fillByRawData<ITariffUserData>(
      response.data,
      (raw) => {
        const model = TariffUserModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );

    this.tariffsUser.isInitialLoading.change(false);
    this.tariffsUser.isReplaceLoading.change(false);
    this.tariffsUser.total.change(response.data.length);
    this.tariffsUser.setIsAllLoaded(true);
    this.tariffsUser.loadingStage.success();

    return { isError: false, data: this.tariffsUser };
  }
}
