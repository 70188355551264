import { Typography } from 'antd';
import { PageContainer } from 'components/PageContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import s from '../StaticPages.module.scss';

const PolicyPage = observer(() => {
  return (
    <PageContainer>
      <Typography.Title level={1}>Условия предоставления услуг и тарификации</Typography.Title>

      <Typography.Title level={3}>1. Общие положения</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Настоящие Условия предоставления услуг (далее – "Условия") определяют перечень предоставления ООО "КупиЛот"
          (далее – "Компания") информационных и сопутствующих услуг на платформе «КупиЛот (далее – "Платформа") на сайте
          www.kupilot.ru. Компания является агрегатором торгов с Электронных торговых площадок и предоставляет следующие
          виды услуг:
        </Typography.Text>
        <Typography.Text>– Бесплатный поиск лотов по параметрам.</Typography.Text>
        <Typography.Text>– Платные услуги по подписке с дополнительными возможностями.</Typography.Text>
        <Typography.Text>
          – Дополнительные платные услуги для работы с лотом: онлайн-показ объекта недвижимости, юридический аудит лота
          и полное сопровождение Клиентов в процессе торгов.
        </Typography.Text>
        <Typography.Text>
          Клиентами Платформы могут стать как физические, так и юридические лица. Для использования Платформы необходимо
          пройти регистрацию, указав ФИО, адрес электронной почты, телефон и идентификационный номер в мессенджере
          Telegram (Telegram ID). Для доступа к отдельным услугам могут потребоваться дополнительные данные. Все данные
          пользователей обрабатываются в соответствии с действующей Политикой обработки персональных данных.
        </Typography.Text>
        <Typography.Text>
          Платные услуги доступны только российским юридическим лицам, а для физических лиц оплата принимается
          исключительно от владельцев российских банковских счетов и/или карт.
        </Typography.Text>
        <Typography.Text>
          Платформа "КупиЛот" агрегирует лоты с Электронных торговых площадок, таких как СБЕР А, Росэлторг, Инвест
          Москва и ГИС Торги и др. Основные функции Платформы включают:
        </Typography.Text>
        <Typography.Text>– Поиск лотов по параметрам.</Typography.Text>
        <Typography.Text>– Просмотр информации, документов, фотографий и статуса торгов.</Typography.Text>
        <Typography.Text>– Переход на исходный источник лота (ЭТП или другой ресурс).</Typography.Text>
        <Typography.Text>– Добавление лота в избранное.</Typography.Text>
        <Typography.Text>– Получение уведомлений об изменении информации в лоте.</Typography.Text>
        <Typography.Text>– Онлайн-показ объектов лота.</Typography.Text>
        <Typography.Text>– Юридический аудит документов лота.</Typography.Text>
        <Typography.Text>– Полное сопровождение Клиента в процессе торгов.</Typography.Text>
        <Typography.Text>
          Использование Платформы ООО «КупиЛот» автоматически означает согласие пользователя с настоящими Условиями
          предоставления услуг, путем выбора соответствующей запросу Клиента услуги.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>2. Описание предоставляемых услуг</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>Услуги, предоставляемые на Платформе "КупиЛот" делятся на три категории:</Typography.Text>
        <Typography.Text>1. Услуги, доступные без регистрации:</Typography.Text>
        <Typography.Text>
          – Поиск по параметрам: возможность искать лоты по следующим параметрам: Вид торгов, Тип лота, Площадь, Цена
          (начальная), Статус торгов, Форма проведения торгов, Организатор, Регион, Город, Этаж, Этажность здания,
          Кадастровый номер, Площадка торгов, Секция.
        </Typography.Text>
        <Typography.Text>
          – Также возможен поиск по ключевым словам в описаниях лотов через поисковую строку.
        </Typography.Text>
        <Typography.Text>
          – Доступ к информации об объектах: пользователи без регистрации могут просматривать общую информацию о лотах,
          включая описание, адрес, стартовую цену, площадь, шаг аукциона, регион и статус торгов.
        </Typography.Text>
        <Typography.Text>2. Услуги, доступные зарегистрированным пользователям бесплатно:</Typography.Text>
        <Typography.Text>
          – Добавление лотов в избранное: зарегистрированные пользователи могут добавлять лоты в избранное и настраивать
          получение уведомлений о любых изменениях по лоту на электронную почту или в Телеграм.
        </Typography.Text>
        <Typography.Text>
          – Доступ к более подробной информации о лотах: зарегистрированные пользователи получают доступ к расширенной
          информации о лотах, включая название Электронной торговой площадки (ЭТП), на которой размещён лот.
        </Typography.Text>
        <Typography.Text>
          – Переход на ЭТП: пользователи с любой подпиской могут нажать на название ЭТП и автоматически перейти на сайт
          ЭТП для получения дополнительной информации.
        </Typography.Text>
        <Typography.Text>3. Услуги, доступные зарегистрированным пользователям на платной основе:</Typography.Text>
        <Typography.Text>
          – Робот-помощник: функция, позволяющая настроить и сохранить параметры поиска лотов, а также получать
          ежедневные оповещения о новых лотах, удовлетворяющих условиям поиска. Доступно только на платных тарифах.
        </Typography.Text>
        <Typography.Text>
          – Просмотр и скачивание документов по лоту доступно только пользователям на платных тарифах.
        </Typography.Text>
        <Typography.Text>
          – Онлайн-показ объекта: услуга разовой оплаты, доступная всем зарегистрированным пользователям, но стоимость
          зависит от пакета услуг.
        </Typography.Text>
        <Typography.Text>
          – Юридический аудит лота: разовая услуга, доступная для всех зарегистрированных пользователей. Цена
          варьируется в зависимости от пакета услуг.
        </Typography.Text>
        <Typography.Text>
          – Сопровождение сделки: доступно для всех пользователей с подпиской, цена варьируется в зависимости от пакета
          услуг.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>3. Тарификация и платежи</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Платформа "КупиЛот" предлагает несколько тарифных планов, каждый из которых включает различные наборы услуг.
          Оплата может осуществляться ежемесячно или ежегодно, в зависимости от выбранного пакета услуг. Кроме того, для
          некоторых услуг предусмотрены разовые платежи.
        </Typography.Text>
        <Typography.Text>Тарифные планы:</Typography.Text>
        <Typography.Text>
          – Free: бесплатный доступ к основным функциям платформы, таким как поиск и просмотр информации о лотах,
          добавление в избранное.
        </Typography.Text>
        <Typography.Text>
          – Basic: 1 000 рублей в месяц или 10 000 рублей в год. Включает доступ к более подробной информации о лотах,
          возможность перехода на ЭТП и приоритетную поддержку платформы (время ожидания ответа не более 3-х часов с
          момента отправления запроса в службу поддержки).
        </Typography.Text>
        <Typography.Text>
          – Standard: 1 500 рублей в месяц или 15 000 рублей в год. Включает все функции Basic, а также доступ к
          Роботу-помощнику.
        </Typography.Text>
        <Typography.Text>
          – Premium: 6 500 рублей в месяц или 50 000 рублей в год. Включает все функции Standard, а также два бесплатных
          онлайн-показа объектов недвижимости в месяц и стоимость юридического аудита по специальной цене.
        </Typography.Text>
        <Typography.Text>
          – Corp Light: 6 000 рублей в месяц или 60 000 рублей в год. Доступен для юридических лиц с возможностью
          подключения до 5 сотрудников Пользователя. Включает полный набор функций и услуг Premium.
        </Typography.Text>
        <Typography.Text>
          – Corp Profi: 10 000 рублей в месяц или 100 000 рублей в год. Доступен для юридических лиц. Включает
          расширенные возможности и услуги, а также приоритетную поддержку и возможность подключения нескольких
          сотрудников Пользователя.
        </Typography.Text>

        <Typography.Text>Разовые услуги:</Typography.Text>
        <Typography.Text>
          – Онлайн-показ объекта недвижимости: 2 000 рублей для пакета Free, 1 000 рублей для пакетов Basic и Standard,
          два бесплатных показа для Premium и Corp Light, после чего – 500 рублей за показ.
        </Typography.Text>
        <Typography.Text>
          – Юридический аудит одного лота: 20 000 рублей для пакета Free, 10 000 рублей для пакетов Basic и Standard, 8
          000 рублей для пакетов Premium и Corp Light.
        </Typography.Text>
        <Typography.Text>
          – Сопровождение сделки: 1% от цены сделки по итогам торгов, но не более 100 000 рублей.
        </Typography.Text>

        <Typography.Text>Оплата:</Typography.Text>
        <Typography.Text>
          – Оплата может быть произведена банковской картой через эквайринг Альфа Банка или посредством оплаты
          выставленного счета для юридических лиц.
        </Typography.Text>

        <Typography.Text>Пробный период:</Typography.Text>
        <Typography.Text>
          – Каждый новый зарегистрированный пользователь получает доступ к Пакету Premium на один месяц бесплатно. В
          рамках пробного периода пользователям предоставляется полный доступ ко всем функциям пакета Premium, за
          исключением двух бесплатных онлайн-показов объектов недвижимости.
        </Typography.Text>

        <Typography.Text>Возврат средств:</Typography.Text>
        <Typography.Text>
          – Юридический аудит: Возврат 100% оплаченной суммы, если услуга отменена в течение 5 часов с момента ее
          предоплаты.
        </Typography.Text>
        <Typography.Text>
          Возврат 70% оплаченной суммы, если услуга отменена в течение 24 часов с момента ее предоплаты. При отмене
          услуги в течение 48 часов с момента ее предоплаты и при условия неполучения Пользователем заключения по
          юридическому аудиту, возврат денежных средств осуществляется в размере 30% от оплаченной суммы.
        </Typography.Text>
        <Typography.Text>
          – Онлайн-показ: полный возврат денежных средств осуществляется, если услуга отменена за 24 часа до её
          оказания.
        </Typography.Text>
      </div>
      <Typography.Title level={3}>4. Порядок предоставления услуг</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Все возможности в рамках пакетов услуг становятся доступными сразу же после оплаты через эквайринг или
          поступления средств на счёт ООО "КупиЛот". Разовые услуги, такие как юридический аудит и онлайн-показ объектов
          недвижимости, предоставляются только при 100% предоплате. Разовая услуга по юридическому сопровождению участия
          в торгах оплачивается по факту её предоставления и определяется условиями договора, заключаемого с агентом.
        </Typography.Text>
        <Typography.Text>
          Более подробные условия оказания Компанией соответствующих услуг отражены в Договоре-оферты, размещенном на
          платформе «КупиЛот» в соответствующей услуге.
        </Typography.Text>
        <Typography.Text>
          ООО «КупиЛот» предоставляет доступ Пользователям к платформе круглосуточно (24/7), служба поддержки доступна в
          будние дни с 10:00 до 18:00. Компания не несет ответственности за перебои в работе по причинам, не зависящим
          от Компании. Сведения о лотах, представленных на платформе, являются информационными и Компания не несёт
          ответственности за их точность и достоверность.
        </Typography.Text>
      </div>
      <Typography.Title level={3}>5. Ответственность сторон</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Компания не несет ответственности за любые убытки, возникающие в результате неправильного использования услуг
          или ошибочных действий клиента. Клиент обязуется использовать Платформу в строгом соответствии с
          законодательством Российской Федерации. Компания также не несет ответственности за точность или достоверность
          информации, представленной о лотах, так как эти данные предоставляются сторонними источниками.
        </Typography.Text>
      </div>
      <Typography.Title level={3}>6. Прекращение использования услуг и возвраты</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Клиент может прекратить использование платных услуг в любой момент, отвязав свою карту или выбрав другой
          тарифный план. Изменения вступят в силу с начала следующего расчётного периода. Расчетным периодом является
          календарный месяц. При отмене пакетной, годовой услуги, до момента истечения срока ее действия, возврат
          осуществляется в размере неизрасходованной части пакета. При этом фактически истекший период рассчитывается
          исходя из стоимости месячной подписки.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>7. Защита персональных данных и конфиденциальность</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Компания обязуется соблюдать конфиденциальность всех персональных данных клиентов в соответствии с Федеральным
          законом №152-ФЗ "О персональных данных". Все данные защищены с использованием шифрования и других мер
          безопасности.
        </Typography.Text>
      </div>

      <Typography.Title level={3}>8.Заключительные положения</Typography.Title>
      <div className={s.text__container}>
        <Typography.Text>
          Компания оставляет за собой право изменять Условия в одностороннем порядке. Изменения вступают в силу с
          момента их публикации на сайте. Пользователи будут уведомлены о существенных изменениях посредством
          электронной почты.
        </Typography.Text>
      </div>
    </PageContainer>
  );
});

export default PolicyPage;
