import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ListWithFilters as ListWithFiltersBase } from 'components/ListWithFilters';
import { ProfileListType } from 'entities/lotFilters';
import { DEFAULT_EMPTY_TEXT } from 'pages/LotsListPage/LotsList/LotsList';
import { LotsList } from 'pages/Profile/LotsList';
import { emptyListPlaceholderOptions } from 'stores/LotListProfileStore/LotListProfileFiltersModel/config';
import { useLotListProfileStore } from 'stores/LotListProfileStore/context';

import { SideFilters } from '../../SideFilters';
import { Sort } from '../../Sort';

type FavoritesListProps = {
  isFiltersCollapsed?: boolean;
  className?: string;
};

const FavoritesList = ({
  isFiltersCollapsed,
  className,
}: FavoritesListProps): React.ReactElement<FavoritesListProps> => {
  const { favoriteLots, filters } = useLotListProfileStore();
  const isEmpty = favoriteLots.isEmpty && filters.isEmptySideFilters && !favoriteLots.loadingStage.isLoading;

  const emptyText = isEmpty ? emptyListPlaceholderOptions[ProfileListType.favorites] : DEFAULT_EMPTY_TEXT;

  return (
    <ListWithFiltersBase
      className={className}
      SideFilters={SideFilters}
      sortComponent={<Sort />}
      listComponent={<LotsList lots={favoriteLots} emptyText={emptyText} />}
      isListEmpty={favoriteLots.isEmpty}
      isListTotalEmpty={favoriteLots.totalPure.value === 0 && !favoriteLots.isInitialLoading.value}
      isFilterOptionsEmpty={filters.isEmptySideFiltersOptions}
      isFiltersCollapsed={isFiltersCollapsed}
      isProfile
    />
  );
};

export default observer(FavoritesList);
