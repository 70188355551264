import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { LotCardIntersection } from 'components/lot/LotCard';
import { LotsListProps as BaseLotsListProps } from 'components/lot/types';
import { LotModel } from 'models/LotModel';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { LotListPagination } from 'components/lot/LotsListPagination';
import { Pagination } from 'antd';
import s from './LotsList.module.scss';
import { getCurrentPage } from 'utils/page';
import { useRootStore } from 'stores/RootStore';
import { useWidth } from 'hooks/useWidth';

type LotsListProps = {
  className?: string;
};

export const DEFAULT_EMPTY_TEXT = 'По вашему запросу ничего не найдено';

const LotsList = ({ className }: LotsListProps): React.ReactElement<LotsListProps> => {
  const store = useLotListStore();
  const { userStore, robotStore } = useRootStore();
  const refWrap = React.useRef<null | HTMLDivElement>(null);
  const timerDebounceRef = React.useRef<NodeJS.Timeout | null>(null);
  const refViews = React.useRef<number[]>([]);
  const { width = window.innerWidth } = useWidth();

  const handleAddView = (id: number | number[]) => {
    if (userStore.authorized && robotStore.robotItem?.value !== null && robotStore.robotItem.value.new_lots_count > 0) {
      if (Array.isArray(id)) {
        refViews.current = [...refViews.current, ...id];
      } else {
        refViews.current.push(id);
      }
    }
  };

  const handleDebounceScroll = React.useCallback(() => {
    if (
      userStore.authorized &&
      robotStore.robotItem?.value !== null &&
      refViews.current.length > 0 &&
      robotStore.robotItem.value.new_lots_count > 0
    ) {
      if (timerDebounceRef.current) {
        clearTimeout(timerDebounceRef.current);
      }
      timerDebounceRef.current = setTimeout(() => {
        robotStore.addViewItemsRobot(robotStore.robotItem!.value!.id, refViews.current);
      }, 500);
    }
  }, [userStore.authorized, robotStore.robotItem, refViews.current]);

  const props = React.useMemo<BaseLotsListProps<LotModel>>(() => {
    return {
      className: className,
      fetchNextItems: store.filters.incrementPage,
      lots: store.lots,
      CardComponent: (props) => (
        <LotCardIntersection {...props} onView={handleAddView} className={s.padding} mobileHover />
      ),
      emptyText: DEFAULT_EMPTY_TEXT,
    };
  }, [userStore.authorized, robotStore.robotItem, robotStore.robotItem.value?.id]);

  React.useEffect(() => {
    if (document) {
      document.addEventListener('scroll', handleDebounceScroll);
    }

    return () => {
      document?.removeEventListener('scroll', handleDebounceScroll);
    };
  }, []);

  return (
    <div ref={refWrap}>
      <LotListPagination {...props} onScroll={handleDebounceScroll} />
      {store.lots.total.value && store.lots.total.value > 10 ? (
        <div className={s.pagination}>
          <Pagination
            current={getCurrentPage(store.lots.total.value, store.filters.offset)}
            total={store.lots.total.value || 0}
            onChange={(page) => {
              store.filters.setPage(page - 1);
              refViews.current = [];
              if (refWrap && refWrap.current) window.scrollTo({ behavior: 'smooth', top: refWrap.current.offsetTop });
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(LotsList);
