import { IRobotCreateFilterModel, IRobotModel } from 'entities/robot';

export class RobotFilterModel implements IRobotCreateFilterModel {
  readonly object_type;
  readonly auction_type;
  readonly square;
  readonly price;
  readonly form_type;
  readonly auction_organizer;
  readonly city;
  readonly region;
  readonly aggregated_type;
  readonly aggregated_object_type;
  readonly aggregated_auction_format;
  readonly aggregated_auction_type;
  readonly geography;
  readonly floor;

  constructor(parms: IRobotCreateFilterModel) {
    this.object_type = parms.object_type;
    this.auction_type = parms.auction_type;
    this.square = parms.square;
    this.price = parms.price;
    this.form_type = parms.form_type;
    this.auction_organizer = parms.auction_organizer;
    this.city = parms.city;
    this.region = parms.region;
    this.aggregated_type = parms.aggregated_type;
    this.aggregated_object_type = parms.aggregated_object_type;
    this.aggregated_auction_format = parms.aggregated_auction_format;
    this.aggregated_auction_type = parms.aggregated_auction_type;
    this.geography = parms.geography;
    this.floor = parms.floor;
  }

  static fromJson(raw: IRobotCreateFilterModel): IRobotCreateFilterModel {
    return {
      object_type: raw.object_type,
      auction_type: raw.auction_type,
      square: raw.square,
      price: raw.price,
      form_type: raw.form_type,
      auction_organizer: raw.auction_organizer,
      city: raw.city,
      region: raw.region,
      aggregated_type: raw.aggregated_type,
      aggregated_object_type: raw.aggregated_object_type,
      aggregated_auction_format: raw.aggregated_auction_format,
      aggregated_auction_type: raw.aggregated_auction_type,
      geography: raw.geography,
      floor: raw.floor,
    };
  }
}

export class RobotModel implements IRobotModel {
  readonly user;
  readonly id;
  readonly name;
  readonly filters;
  readonly new_lots_count;

  constructor({ user, id, filters, name, new_lots_count }: IRobotModel) {
    this.user = user;
    this.id = id;
    this.new_lots_count = new_lots_count;
    (this.filters = filters), (this.name = name);
  }

  static fromJson(raw: IRobotModel): IRobotModel {
    return {
      id: raw.id,
      name: raw.name,
      user: raw.user,
      new_lots_count: raw.new_lots_count || 0,
      filters: RobotFilterModel.fromJson(raw.filters),
    };
  }
}
