import * as React from 'react';
import { Cookies } from './Cookies';
const COOKIES_LOCAL_STORE_KEY = 'COOKIES_LOCAL_STORE_KEY';

type CookiesType = {
  setShow: (value: boolean) => void;
  show: boolean;
  onClose: () => void;
  onOpen: () => void;
  onAgree: () => void;
};

const CookiesContext = React.createContext<CookiesType | null>(null);

export const CookiesContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const onAgree = () => {
    localStorage.setItem(COOKIES_LOCAL_STORE_KEY, 'true');
    setShow(false);
  };

  React.useEffect(() => {
    const isCookies = localStorage.getItem(COOKIES_LOCAL_STORE_KEY) !== null ? true : false;
    setShow(!isCookies);
  }, []);

  return (
    <CookiesContext.Provider
      value={{
        setShow,
        show,
        onClose: () => setShow(false),
        onOpen: () => setShow(true),
        onAgree,
      }}
    >
      {children}
      <Cookies />
    </CookiesContext.Provider>
  );
};

export const useCookiesContext = () => {
  const context = React.useContext(CookiesContext);

  if (!context) {
    throw new Error('CookiesContext was not provided');
  }

  return context;
};
