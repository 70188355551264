export class YandexMetricEvents {
  private readonly ym: any;
  private readonly YM_ID = 98767239;

  constructor() {
    this.ym = (window as any).ym;
  }

  checkYm = () => {
    if (window !== undefined && 'ym' in window) {
      console.log(window !== undefined && 'ym' in window);
      return true;
    }

    return false;
  };
  personTel = () => {
    if (this.checkYm()) {
      this.ym(this.YM_ID, 'reachGoal', 'person_tel');
    }
  };
  personChekOk = () => {
    if (this.checkYm()) {
      this.ym(this.YM_ID, 'reachGoal', 'person_chek_ok');
    }
  };
  personEmail = () => {
    if (this.checkYm()) {
      this.ym(this.YM_ID, 'reachGoal', 'person_email');
    }
  };
  personChekEmail = () => {
    if (this.checkYm()) {
      this.ym(this.YM_ID, 'reachGoal', 'person_chek_email');
    }
  };
}

export const ymEvents = new YandexMetricEvents();
