import { IReactionDisposer, action, makeObservable, reaction, runInAction } from 'mobx';

import { apiUrls } from 'config/apiUrls';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { IRootStore } from 'stores/RootStore';
import { ILocalStore } from 'types/ILocalStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';
import { BugReportFormModel } from 'models/BugReportFormModel';

type BugReportFormStoreParams = {
  rootStore: IRootStore;
};

export class BugReportFormStore implements ILocalStore {
  readonly fields: BugReportFormModel = new BugReportFormModel();
  readonly submitStage: LoadingStageModel = new LoadingStageModel();

  private readonly rootStore: IRootStore;

  private readonly disposers: IReactionDisposer[] = [];

  constructor({ rootStore }: BugReportFormStoreParams) {
    makeObservable<this>(this, {
      submitForm: action.bound,
    });

    this.rootStore = rootStore;

    if (this.rootStore.userStore.user?.email) {
      this.fields.email.change(this.rootStore.userStore.user.email);
    }

    this.disposers.push(
      reaction(
        () => this.rootStore.userStore.authorized,
        (authorized) => {
          if (!authorized) {
            this.fields.resetFull();
          }
        },
      ),
      reaction(
        () => this.rootStore.userStore.user?.email,
        (email) => this.fields.email.change(email ?? ''),
      ),
    );
  }

  async submitForm(): Promise<BaseResponse> {
    if (this.submitStage.isLoading) {
      return { isError: true };
    }

    this.fields.setIsValidateOnChange(true);

    if (this.fields.validate()) {
      return { isError: true };
    }

    this.submitStage.loading();

    const formData = new FormData();
    formData.append('file', this.fields.file.value || '');
    formData.append('email', this.fields.email.value);
    formData.append('name', this.fields.name.value);
    formData.append('text', this.fields.description.value);

    const response = await apiCustom({
      url: apiUrls.bugReport,
      method: 'POST',
      data: formData,
    });

    return runInAction(() => {
      if (response.isError) {
        this.submitStage.error();

        return { isError: true };
      }

      this.submitStage.success();

      return { isError: false };
    });
  }

  destroy(): void {
    this.disposers.forEach((disposer) => disposer());
  }
}
