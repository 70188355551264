import { FloorOptions } from 'entities/lotFilters';
import { FloorOptionsType } from 'stores/LotListCatalogStore/LotListCatalogFiltersModel/LotListCatalogFiltersModel';

export const filterSerialize = (
  items: {
    [key: string]: string | number | object | null | { from: unknown; to: unknown; options?: string[] } | boolean;
  },
  floorOptions?: FloorOptionsType,
) => {
  const _filters = { ...items };

  Object.keys(_filters).forEach((key) => {
    if (_filters[key] === null) {
      delete _filters[key];
    } else if (Array.isArray(_filters[key]) && _filters[key].length === 0) {
      delete _filters[key];
    }

    if (typeof _filters[key] === 'object' && _filters[key] && 'from' in _filters[key] && 'to' in _filters[key]) {
      if (_filters[key].to === null) {
        delete _filters[key].to;
      }
      if (_filters[key].from === null) {
        delete _filters[key].from;
      }
    }
  });

  if ('cadastral_number' in _filters && _filters['cadastral_number'] === '') {
    delete _filters['cadastral_number'];
  }

  if ('floor' in _filters && floorOptions) {
    const _floorOptions: string[] = [];
    Object.keys(floorOptions).forEach((key) => {
      if (floorOptions[key as FloorOptions] === true) {
        _floorOptions.push(key);
      }
    });

    if (_floorOptions.length > 0) {
      (_filters.floor as any)['options'] = floorOptions;
    }
  }

  return _filters;
};
