import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListProfileRobotStore } from 'stores/LotListProfileRobotStore/context';

type StatusProps = {
  className?: string;
};

const Status = ({ className }: StatusProps): React.ReactElement<StatusProps> => {
  const { filters } = useLotListProfileRobotStore();

  return (
    <BaseInput className={className} title="Статус">
      <MultiSelect
        options={filters.status.options}
        selectedOptions={filters.status.selectedOptions}
        value={filters.status.selectedValue.value}
        onChange={filters.status.toggleValue}
        onSearch={filters.status.handleSearch}
        searchValue={filters.status.searchValue.value}
        isOptionsLoading={filters.loadingStage.isLoading}
      />
    </BaseInput>
  );
};

export default observer(Status);
