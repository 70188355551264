import { Button, Input, Modal, Space, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ValueModel } from 'models/ValueModel';

import s from './FormModal.module.scss';
import { CloseOutlined } from '@ant-design/icons';

type FormModalProps = {
  opened: boolean;
  close: () => void;
  onSubmit: () => void;
  name: ValueModel<string>;
  email: ValueModel<string>;
  description: ValueModel<string>;
  maxDescriptionLength: number;
  submitStage: LoadingStageModel;
  hasErrors: boolean;
  isValidateOnChange: boolean;
  className?: string;
};

const FormModal = ({
  opened,
  close,
  onSubmit,
  name,
  email,
  description,
  maxDescriptionLength,
  submitStage,
  hasErrors,
  isValidateOnChange,
  className,
}: FormModalProps): React.ReactElement<FormModalProps> => {
  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      description.change(event.target.value);

      if (isValidateOnChange) {
        description.validate();
      }
    },
    [description, isValidateOnChange],
  );

  const handleTextareaKeydown = React.useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // отмена всплытия по Enter
    event.stopPropagation();
  }, []);

  useKeyboardEvent('Enter', onSubmit, opened);

  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={cn(s.popup, className)}
      open={opened}
      footer={null}
      closeIcon={null}
      onCancel={close}
      width={640}
      centered
    >
      <Space className={s.popup__body_inner} direction="vertical" size={0}>
        <div className={s.header}>
          <Typography.Title className={s.popup__title} level={2}>
            Сообщить об ошибке
          </Typography.Title>
          <button onClick={close}>
            <CloseOutlined />
          </button>
        </div>
        <Space className={s['popup__input-wrapper']} direction="vertical" size={0}>
          <Input.TextArea
            className={cn(s['popup__input'], s['popup__input-textarea'])}
            onChange={handleDescriptionChange}
            onKeyDown={handleTextareaKeydown}
            placeholder="Текст сообщения"
            value={description.value}
            status={description.isError ? 'error' : undefined}
          />
          <div className={s['popup__textarea-footer']}>
            <Typography.Text type="danger">{description.error}</Typography.Text>
            <Typography.Text
              className={cn(s['popup__chars-counter'], description.isError && s['popup__chars-counter_error'])}
              type="secondary"
            >
              {description.value.length}&nbsp;/&nbsp;{maxDescriptionLength}
            </Typography.Text>
          </div>
        </Space>
        <div className={s.actions}>
          <Button
            className={s['popup__submit-button']}
            type="primary"
            onClick={onSubmit}
            loading={submitStage.isLoading}
            disabled={hasErrors}
          >
            Отправить
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

export default observer(FormModal);
