import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ElementId } from 'config/ids';
import { useLotPageStore } from 'stores/LotPageStore';

import s from './MobileCarousel.module.scss';
import { useWidth } from 'hooks/useWidth';

const MobileCarousel: React.FC = ({ className }: { className?: string }) => {
  const { lot } = useLotPageStore();
  const { width = window.innerWidth } = useWidth();

  const size = React.useMemo(() => {
    if (width >= 768) {
      return CarouselImageSize.xl;
    }
    return CarouselImageSize.sm;
  }, [width]);

  return (
    <div className={s.carousel} id={ElementId.lotPageImageCarouselMobile}>
      <ImgCarousel
        className={className + ' lot-card-image'}
        images={lot.value.images || []}
        imgSize={size}
        withoutThumbnails
        showBullets={true}
      />
    </div>
  );
};

export default React.memo(MobileCarousel);
