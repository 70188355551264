import { Collapse, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import React from 'react';

import s from './SubscriptionsHistory.module.scss';

interface SubscriptionsHistoryProps {
  className?: string;
}

type DataType = {
  plan: { isActive: boolean; title: string };
  start: string;
  end: string;
  renewal: string;
};

const SubscriptionsHistory = ({ className }: SubscriptionsHistoryProps): React.ReactElement => {
  const data: DataType[] = [
    { plan: { isActive: true, title: 'Standart' }, start: '13.04.2024', end: '13.04.2025', renewal: '' },
    {
      plan: { isActive: false, title: 'Basic' },
      start: '13.04.2022',
      end: '13.04.2023',
      renewal: '13.04.2023 - 13.04.2024',
    },
    { plan: { isActive: false, title: 'Standart' }, start: '13.04.2021', end: '13.04.2022', renewal: '' },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: 'Тариф',
      dataIndex: 'plan',
      render: (item) => (
        <span className={cn(s.plan__item, { [s.plan__item_active]: item.isActive })}>{item.title}</span>
      ),
      fixed: 'left',
    },
    {
      title: 'Начало',
      dataIndex: 'start',
      render: (item) => <span className={s.plan__item}>{item}</span>,
    },
    {
      title: 'Окончание',
      dataIndex: 'end',
      render: (item) => <span className={s.plan__item}>{item}</span>,
    },
    {
      title: 'Продление',
      key: 'renewal',
      render: (item) =>
        item.renewal ? <span className={s.plan__item}>{item.renewal}</span> : <span className={s.plan__item}>-</span>,
    },
  ];

  const table = <Table scroll={{ x: 'max-content' }} columns={columns} dataSource={data} />;

  const collapseItem = {
    key: '1',
    label: 'История платежей',
    children: table,
  };

  return <Collapse className={cn(s.collapse, className)} ghost expandIconPosition="end" items={[collapseItem]} />;
};

export default SubscriptionsHistory;
