import { Input, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { convertStringToNumber } from 'utils/convertStringToNumber';
import { formatNumberWithPostfix } from 'utils/formatNumberWithPrefix';

import s from './RangeFilter.module.scss';

type RangeFilterProps = {
  from: number | null;
  to: number | null;
  valueMin: number | null;
  valueMax: number | null;
  onChangeMin: (v: number | null) => void;
  onChangeMax: (v: number | null) => void;
  placeholderPostfix?: string;
  className?: string;
  placeholderSufix?: {
    from: string;
    to: string;
  };
  placeholderTo?: number;
};

const RangeFilter = ({
  from,
  to,
  valueMin,
  valueMax,
  onChangeMin,
  onChangeMax,
  placeholderPostfix,
  className,
  placeholderSufix = {
    from: '',
    to: '',
  },
  placeholderTo,
}: RangeFilterProps): React.ReactElement<RangeFilterProps> => {
  const handleChangeMin = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMin(convertStringToNumber(e.target.value));
    },
    [onChangeMin],
  );

  const handleChangeMax = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMax(convertStringToNumber(e.target.value));
    },
    [onChangeMax],
  );

  return (
    <div className={cn(s['range-filter'], className)}>
      <Input
        className={s['range-filter__input']}
        placeholder={placeholderSufix.from + '' + formatNumberWithPostfix(from, placeholderPostfix)}
        type="number"
        value={valueMin ?? undefined}
        onChange={handleChangeMin}
        min={from || undefined}
        max={to || undefined}
      />
      <Typography.Text className={s['range-filter__dash']}>–</Typography.Text>
      <Input
        className={s['range-filter__input']}
        placeholder={placeholderSufix.to + '' + formatNumberWithPostfix(placeholderTo || to, placeholderPostfix)}
        type="number"
        value={valueMax ?? undefined}
        onChange={handleChangeMax}
        min={from || undefined}
        max={to || undefined}
      />
    </div>
  );
};

export default React.memo(RangeFilter);
