import { Button, Input, notification, Popconfirm } from 'antd';
import RobotIcon from 'components/icons/Robot';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import s from './RobotAction.module.scss';
import { useRootStore } from 'stores/RootStore';
import { filtersEmptyObject } from 'utils/function';
import { CheckOutlined } from '@ant-design/icons';
import { RobotCreateFilterModel } from 'entities/robot';

const TITLE = 'Сохранить настройки поиска для робота';

export const RobotAction = observer(
  ({
    filters,
    title = TITLE,
    iconHide = false,
  }: {
    filters: RobotCreateFilterModel;
    title?: string;
    iconHide?: boolean;
  }) => {
    const [open, setOpen] = useState(false);
    const { robotStore } = useRootStore();
    const { isLoadingRobot, robotName, isListInitialLoading, robotFilters, putRobotItemFetch } = robotStore;
    const [api, contextHolder] = notification.useNotification();

    const activeFilters = useMemo(() => {
      const data = { ...filters };
      const items = filtersEmptyObject(data);
      return { items, count: Object.keys(items).length };
    }, [filters]);

    const disabled = useMemo(() => {
      return (
        isLoadingRobot ||
        robotName.value.length === 0 ||
        activeFilters.count === 0 ||
        robotName.value.length > 16 ||
        isListInitialLoading
      );
    }, [isLoadingRobot, robotName.value, activeFilters.count]);

    const successNotification = (type: 'success' | 'update' = 'success') => {
      const msg = {
        success: 'Фильтр успешно создан',
        update: 'Готово!',
      };
      api.open({
        message: msg[type],
        duration: 4.5,
        icon: <CheckOutlined style={{ color: 'green' }} />,
      });
    };

    const searchFilter = useMemo(() => {
      const index = robotFilters.items.findIndex((item) => item.name === robotName.value);
      return {
        index: index,
        item: index != -1 ? robotFilters.items[index] : null,
      };
    }, [robotFilters.items, robotName.value]);

    const onSave = () => {
      if (searchFilter.index !== -1) {
        setOpen(true);
      } else {
        robotStore.postRobotItemFetch(activeFilters.items).then(() => {
          successNotification();
          robotName.reset();
        });
      }
    };

    const onUpdate = () => {
      if (searchFilter.item) {
        putRobotItemFetch(searchFilter.item.id, activeFilters.items, robotName.value).then(() => {
          successNotification('update');
          robotName.reset();
          setOpen(false);
        });
      }
    };

    const onCancel = () => setOpen(false);

    return (
      <div className={s.filterActions}>
        {contextHolder}
        <div className={s.robotWrap}>
          <div className={s.robotLabel}>
            {!iconHide && <RobotIcon />}
            <div>{title}</div>
          </div>
          <Input
            placeholder="Введите название"
            className={s.input}
            onChange={(e) => robotName.change(e.target.value)}
            value={robotName.value}
            status={robotName.isError ? 'error' : undefined}
          />

          <Popconfirm
            open={open}
            placement="top"
            title={'Заменить его?'}
            okText="Да"
            icon={<div></div>}
            cancelText="Нет"
            onConfirm={onUpdate}
            onCancel={onCancel}
            rootClassName={s.popup}
          >
            <Button loading={isLoadingRobot} disabled={disabled} className={s.btn} type="primary" onClick={onSave}>
              Сохранить
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  },
);
