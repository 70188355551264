import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { Status } from 'entities/lot';
import useToggleState from 'hooks/useToggleState';
import { LotModel } from 'models/LotModel';

import { ImageGallery } from '../ImageGallery';
import Layout from '../Layout';
import { Meta } from '../Meta';

import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';

import s from './LotCard.module.scss';
import { useInView } from 'react-intersection-observer';
import { CarouselImageSize } from 'components/ImgCarousel';
import { useWidth } from 'hooks/useWidth';

export type LotCardSmallProps = {
  item: LotModel;
  additionalTags?: Status[];
  style?: React.CSSProperties;
  className?: string;
  onTouchStartImageGallery?: () => void;
  onTouchEndImageGallery?: () => void;
  favoritePopup?: boolean;
  similar?: boolean;
};

export const LotCardSmallIntersection = observer(
  (props: LotCardSmallProps & { onView?: (id: number | number[]) => void }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
    });
    const refStop = React.useRef(false);

    React.useEffect(() => {
      if (inView && !refStop.current && props.onView) {
        refStop.current = true;
        props.onView(props.item.id);
      }
    }, [inView]);

    return (
      <div ref={ref}>
        <LotCardSmall {...props} />
      </div>
    );
  },
);

const LotCardSmall = ({
  item,
  className,
  style,
  additionalTags,
  onTouchEndImageGallery,
  onTouchStartImageGallery,
  favoritePopup,
  similar = false,
}: LotCardSmallProps): React.ReactElement<LotCardSmallProps> => {
  const { open, close } = useToggleState();
  const handleFavoriteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      item.startToggleFavorite();
    },
    [item],
  );

  const handleSubscribeClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      item.startToggleSubscription();
    },
    [item],
  );
  const { width = window.innerWidth } = useWidth();
  const images = item.images ? item.images.slice(0, 4) : [];
  const sizeImg = React.useMemo(() => {
    if (similar) {
      if (width < 768) {
        return CarouselImageSize.sm;
      }
      return CarouselImageSize.similar;
    }

    return undefined;
  }, [similar]);

  return (
    <Layout
      item={item}
      style={style}
      className={`${className} ${s['lot-card']}`}
      handleMouseEnter={open}
      handleMouseLeave={close}
      column
    >
      <div
        onTouchEnd={onTouchEndImageGallery}
        onTouchStart={onTouchStartImageGallery}
        onTouchMove={onTouchStartImageGallery}
      >
        <ImageGallery images={images} className={s.image + ' lot-card-image'} imgSize={sizeImg} showBullets />
      </div>
      <div className={s['lot-card__main-content']}>
        <Header
          status={item.status}
          auctionFormat={item.auctionFormat}
          auctionType={item.auctionType}
          objectType={item.objectType}
          priceMin={item.priceMinFormatted}
          totalArea={item.totalAreaFormatted}
          isFavorite={item.isInFavorite.value}
          handleFavoriteClick={handleFavoriteClick}
          isSubscribed={item.isInSubscribed.value}
          handleSubscribeClick={handleSubscribeClick}
          additionalTags={additionalTags}
          isDowngradeAuction={item.isDowngradeAuction}
          favoritePopup={favoritePopup}
          similar={similar}
        />
        <Main address={item.lotCardAddress} source={item.source} column />
        {!similar && (
          <Meta floor={item.floor} totalFloors={item.amountOfFloors} entranceCount={item.entrance} similar={similar} />
        )}
        <Footer dateAdded={item.dateAdded} viewsCount={item.viewsCount} />
      </div>
    </Layout>
  );
};

export default observer(LotCardSmall);
