import { Spin } from 'antd';
import Breadcrumb, { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';
import { ListTypeTabbar } from './ListTypeTabbar';
import { LayoutGridAside } from 'components/LayoutGridAside/LayoutGridAside';
import { ProfileAsideMenu } from 'components/profile/ProfileAsideMenu/ProfileAsideMenu';

import s from './ProfileHistory.module.scss';
import { FavoritesList } from './ListWithFilters/FavoritesList';
import { LotListProfileHistoryStoreProvider } from 'stores/LotListProfileHistoryStore/context';
import { TotalItemsCount } from './TotalItemsCount';
import { Sort } from './Sort';

type ProfileHistoryProps = {
  className?: string;
};

const ProfileHistoryPage = ({ className }: ProfileHistoryProps): React.ReactElement<ProfileHistoryProps> => {
  const rootStore = useRootStore();

  const metaPage = React.useMemo(() => {
    const title = 'История просмотров';
    const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
      { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
      { title: 'Личный кабинет', href: routerUrls.profileSettings.mask },
      { title: title },
    ];

    return {
      title,
      breadcrumbs: BREADCRUMB_ITEMS,
    };
  }, []);

  if (!rootStore.authStore.authorizingStage.isFinished) {
    return (
      <div className={s.profile__spinner}>
        <Spin size="large" />
      </div>
    );
  }

  if (!rootStore.userStore.authorized) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }

  return (
    <PageContainer withoutBottomPadding className={cn(s.profile, className)}>
      <Breadcrumb className={s.profile__breadcrumbs} itemRender={breadcrumbItemsRender} items={metaPage.breadcrumbs} />
      <PageListTitle className={s.profile__title}>{metaPage.title}</PageListTitle>
      <LotListProfileHistoryStoreProvider>
        <LayoutGridAside clxs={{ main: s.main }} aside={<ProfileAsideMenu />}>
          <div className={s.filters}>
            <ListTypeTabbar className={s.tabbar} />
            <TotalItemsCount />
            <Sort />
          </div>
          <FavoritesList />
        </LayoutGridAside>
      </LotListProfileHistoryStoreProvider>
    </PageContainer>
  );
};

export default observer(ProfileHistoryPage);
