import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import FavoriteButtonMini from 'components/favoriteButtons/FavoriteButtonMini';
import SubscribeButtonMini from 'components/subscribeButtons/SubscribeButtonMini';
import { useWidth } from 'hooks/useWidth';
import LotPageTags from 'pages/LotPage/LotPageTags';
import { useLotPageStore } from 'stores/LotPageStore';

import s from './Header.module.scss';
import ButtonIconAction from 'components/ButtonIconAction/ButtonIconAction';
import useToggleState from 'hooks/useToggleState';
import { MessageModal } from 'components/MessageModal';
import { useRootStore } from 'stores/RootStore';

type HeaderProps = {
  className?: string;
};

export const HeaderActions = observer(() => {
  const { userStore } = useRootStore();
  const { open: openModal, close: closeModal, opened: openedModal } = useToggleState();
  const { isDesktop } = useWidth();
  const { lot } = useLotPageStore();
  const { isInFavorite, startToggleFavorite, isInSubscribed, startToggleSubscription } = lot.value;

  return (
    <>
      <div className={s.header__actions}>
        <SubscribeButtonMini
          isSubscribed={isInSubscribed.value}
          onClick={startToggleSubscription}
          withoutTooltip={!isDesktop}
        />
        <FavoriteButtonMini isFavorite={isInFavorite.value} onClick={startToggleFavorite} withoutTooltip={!isDesktop} />
        {userStore.authorized && <ButtonIconAction onClick={openModal} />}
      </div>
      <MessageModal opened={openedModal} close={closeModal} />
    </>
  );
});

const Header: React.FC<HeaderProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { width = window.innerWidth } = useWidth();
  const { objectType, totalAreaFormatted } = lot.value;

  return (
    <div className={cn(s.header, className)}>
      <div className={s.header__title}>
        <Typography.Title level={1} className={s['header__title-text-wrapper']}>
          <span className={cn(s['header__title-text'], totalAreaFormatted && s['header__title_with-area'])}>
            {objectType}
          </span>
          {totalAreaFormatted && <AreaText area={totalAreaFormatted} />}
        </Typography.Title>
        {width >= 996 && <HeaderActions />}
      </div>
      <LotPageTags className={s.header__tags} />
    </div>
  );
};

export default observer(Header);
