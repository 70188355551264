import React from 'react';

import s from './PremiumBanner.module.scss';

const PremiumBanner = (): React.ReactElement => {
  return (
    <div className={s.premium__banner}>
      <div className={s.premium__banner__content}>
        <h1 className={s.premium__banner__title}>Получите максимум с Премиум подпиской</h1>
        <p className={s.premium__banner__description}>Доступ к эксклюзивным возможностям и привилегиям</p>
      </div>
      <div className={s.premium__banner__background} />
    </div>
  );
};

export default React.memo(PremiumBanner);
