import { Button } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import sortIcon from 'icons/sort.svg';

import s from './SortItem.module.scss';

type SortItemProps = {
  id: string;
  label: string;
  sortDirection?: 'asc' | 'desc';
  onClick: (id: string, value: string) => void;
  className?: string;
};

const SortItemCustom = ({
  id,
  label,
  sortDirection = 'asc',
  onClick,
  className,
}: SortItemProps): React.ReactElement<SortItemProps> => {
  const activeAsc = sortDirection === 'asc';
  const handleClick = React.useCallback(() => {
    onClick(id, activeAsc ? 'desc' : 'asc');
  }, [activeAsc]);

  return (
    <Button type="text" className={cn(s['sort-item'], s['sort-item_with-icon'], className)} onClick={handleClick}>
      {label}
      <img
        src={sortIcon}
        className={cn(s['sort-item__icon'], sortDirection === 'desc' && s['sort-item__icon_flipped'])}
      />
    </Button>
  );
};

export default React.memo(SortItemCustom);
