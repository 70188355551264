import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseInput } from 'components/BaseInput';
import { MultiSelect } from 'components/MultiSelect';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import { FilterOption } from 'entities/lotFilters';

type CityProps = {
  className?: string;
  dropdownStyle?: React.CSSProperties;
  placeholder?: string;
  placeholderSelect?: string;
};

const City = ({
  className,
  dropdownStyle,
  placeholder,
  placeholderSelect,
}: CityProps): React.ReactElement<CityProps> => {
  const { filters } = useLotListStore();

  const onSelectedAll = () => {
    filters.geography.setSelected(filters.geography.getAllOptionsIds || []);
  };
  const onClearSelected = () => {
    filters.geography.setSelected(null);
  };

  const onChange = (id: number, data?: FilterOption) => {
    const isChecked = filters.geography.selectedValue.hasItem(id);
    const ids = filters.geography.getChildrensSelectedValues(id);
    ids.push(id);
    if (isChecked) {
      const values = filters.geography.selectedValue.value.filter((e) => !~ids.indexOf(e));
      filters.geography.setSelected(values);
    } else {
      //@ts-ignore
      const values: number[] = [...new Set([...filters.geography.selectedValue.value, ...ids])];
      filters.geography.setSelected(values);
    }
  };

  return (
    <BaseInput className={className} title={placeholder}>
      <MultiSelect
        placeholder={placeholderSelect}
        options={filters.geography.optionsUnSorted}
        selectedOptions={filters.geography.selectedOptions}
        value={filters.geography.selectedValue.value}
        onChange={onChange}
        withSearch={false}
        isOptionsLoading={false}
        onSelectedAllCustom={onSelectedAll}
        onClearSelectedCustom={onClearSelected}
        dropdownStyle={dropdownStyle}
        hideSelectedList
        dropdownMulti
        withActions
      />
    </BaseInput>
  );
};

export default observer(City);
