import { LoginOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { ProfileNotificationMenu } from 'components/profile/ProfileNotification/ProfileNotification';
import { ElementId } from 'config/ids';
import { RouteFullPath } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import { useRootStore } from 'stores/RootStore';

import { ProfileMenu } from './ProfileMenu';

import s from './HeaderProfile.module.scss';
import NotificationIcon from 'components/icons/NotificationIcon';

type HeaderProfileProps = {
  className?: string;
};

const HeaderProfile = ({ className }: HeaderProfileProps): React.ReactElement<HeaderProfileProps> => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { userStore, authStore, analyticsStore, notificationStore } = useRootStore();
  const navigate = useNavigate();

  const component = React.useMemo(() => {
    return (
      <ProfileNotificationMenu
        menu
        onClick={() => {
          navigate(RouteFullPath.notification);
        }}
      />
    );
  }, [notificationStore.currentTotalItems]);

  const handleLoginButtonClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    authStore.popupController.open();
    authStore.mode.change('login');
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const handleRegisterButtonClick = React.useCallback(() => {
    setIsPopoverOpen(false);
    authStore.popupController.open();
    authStore.mode.change('register');
    analyticsStore.trackEvent({ event: AnalyticsEvent.registerButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const PopoverContent = (
    <div className={s.popover__content}>
      <div className={s.popover__content__title}>Войдите для получения полных возможностей платформы</div>
      <div className={s.popover__content__buttons}>
        <Button className={s.popover__content__button_login} onClick={handleLoginButtonClick} type="primary">
          Войти
        </Button>
        <Button className={s.popover__content__button_register} onClick={handleRegisterButtonClick} type="link">
          Зарегистрироваться
        </Button>
      </div>
    </div>
  );

  const AuthButton = (
    <div className={s.header__profile__actions}>
      <span onClick={handleLoginButtonClick} role="button" className={s.header__profile__notification}>
        <NotificationIcon />
      </span>
      <Popover
        placement="bottomRight"
        trigger="hover"
        arrow={{ pointAtCenter: true }}
        overlayClassName={s.popover}
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        content={PopoverContent}
      >
        <Button
          className={s.singin}
          loading={authStore.authorizingStage.isLoading}
          onClick={handleLoginButtonClick}
          type="primary"
          icon={<LoginOutlined />}
        >
          Войти
        </Button>
      </Popover>
    </div>
  );

  return (
    <div className={cn(s['header-profile'], className)} id={ElementId.loginButton}>
      {!userStore.authorized ? (
        AuthButton
      ) : (
        <>
          {component}
          <ProfileMenu />
        </>
      )}
    </div>
  );
};

export default observer(HeaderProfile);
