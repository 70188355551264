import { Tooltip, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { AreaText } from 'components/AreaText';
import Tags from 'components/Tags';
import { DowngradeIcon } from 'components/icons/DowngradeIcon';
import { LotStatus, Status } from 'entities/lot';

import s from './Header.module.scss';
import { FavoriteButton } from 'components/favoriteButtons/FavoriteButton';
import { ElementId } from 'config/ids';
import SubscribeButton from 'components/subscribeButtons/SubscribeButton';
import { useRootStore } from 'stores/RootStore';
import { useWidth } from 'hooks/useWidth';

type HeaderProps = {
  status: LotStatus;
  auctionFormat: string;
  additionalTags?: Status[];
  auctionType: string;
  objectType: string;
  totalArea: string | null;
  priceMin: string | null;
  isDowngradeAuction: boolean;
  className?: string;
  isFavorite: boolean;
  handleFavoriteClick: (e: React.MouseEvent) => void;
  isSubscribed: boolean;
  handleSubscribeClick: (e: React.MouseEvent) => void;
  favoritePopup?: boolean;
  similar?: boolean;
};

const TooltipActions = ({
  setOpen,
  onDelete,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: (e: React.MouseEvent) => void;
}) => {
  return (
    <div className={s.tooltip}>
      <div className={s.tooltip__header}>Подтвердить удаление?</div>
      <div className={s.tooltip__actions}>
        <button onClick={onDelete}>Да</button>
        <button onClick={() => setOpen(false)}>Нет</button>
      </div>
    </div>
  );
};

const Aside = ({
  isFavorite,
  handleFavoriteClick,
  isSubscribed,
  handleSubscribeClick,
  priceMin,
  favoritePopup,
  similar,
}: {
  isFavorite: boolean;
  handleFavoriteClick: (e: React.MouseEvent) => void;
  isSubscribed: boolean;
  handleSubscribeClick: (e: React.MouseEvent) => void;
  priceMin: string | null;
  favoritePopup?: boolean;
  similar?: boolean;
}) => {
  const { onboardingStore } = useRootStore();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={s.header__aside}>
      {onboardingStore.isOnboardingPassedModel.value && (
        <div className={s.header__buttons + ' ' + (similar === true ? s.reverse : '')}>
          <SubscribeButton
            id={ElementId.lotCardSubscribeButtonDesktop}
            className={s['footer__subscribe-button']}
            onClick={handleSubscribeClick}
            isSubscribed={isSubscribed}
            variant="default"
            hideText
          />
          {favoritePopup ? (
            <Tooltip
              color={'#fff'}
              rootClassName={s.tooltipWrap}
              open={open}
              title={<TooltipActions setOpen={setOpen} onDelete={handleFavoriteClick} />}
            >
              <FavoriteButton
                id={ElementId.lotCardFavoriteButtonDesktop}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen((prev) => !prev);
                }}
                isFavorite={isFavorite}
                variant="default"
                hideText
              />
            </Tooltip>
          ) : (
            <FavoriteButton
              id={ElementId.lotCardFavoriteButtonDesktop}
              onClick={handleFavoriteClick}
              isFavorite={isFavorite}
              variant="default"
              hideText
            />
          )}
        </div>
      )}

      {priceMin && (
        <Typography.Paragraph className={cn(s['header__price'], s['header__text-accent'])}>
          {priceMin}
        </Typography.Paragraph>
      )}
    </div>
  );
};

const Header = ({
  status,
  auctionFormat,
  auctionType,
  objectType,
  totalArea,
  priceMin,
  isDowngradeAuction,
  additionalTags,
  className,
  isFavorite,
  isSubscribed,
  handleFavoriteClick,
  handleSubscribeClick,
  favoritePopup,
  similar,
}: HeaderProps): React.ReactElement<HeaderProps> => {
  const { width } = useWidth();
  const isMobile = React.useMemo(() => {
    if (similar) {
      return true;
    }

    return width < 768;
  }, [width, similar]);

  return (
    <div className={cn(s.header, className)}>
      <div className={s.header__main}>
        <Tags
          className={s['header__tags-container']}
          status={status}
          auctionFormat={auctionFormat}
          additionalTags={additionalTags}
        />
        {isMobile && (
          <Aside
            isFavorite={isFavorite}
            isSubscribed={isSubscribed}
            handleFavoriteClick={handleFavoriteClick}
            handleSubscribeClick={handleSubscribeClick}
            priceMin={priceMin}
            favoritePopup={favoritePopup}
            similar
          />
        )}
        <Typography.Title level={3} className={s['header__title']}>
          <span className={cn(s['header__title-object-type'], s['header__text-accent'])}>{objectType}</span>
          <AreaText area={totalArea} />
          {isDowngradeAuction && <DowngradeIcon className={s['header__downgrade-icon']} />}
        </Typography.Title>
        <Typography.Text className={s['header__auction-type']}>{auctionType}</Typography.Text>
      </div>
      {!isMobile && (
        <Aside
          isFavorite={isFavorite}
          isSubscribed={isSubscribed}
          handleFavoriteClick={handleFavoriteClick}
          handleSubscribeClick={handleSubscribeClick}
          priceMin={priceMin}
          favoritePopup={favoritePopup}
          similar
        />
      )}
    </div>
  );
};

export default React.memo(Header);
