import { Checkbox, Typography } from 'antd';
import React from 'react';
import { useProfileSettingsStore } from 'stores/ProfileSettingsStore/context';
import s from './FormNotification.module.scss';
import { observer } from 'mobx-react-lite';
import { INotivicationSubscribeUpdateBody } from 'entities/notification';
import { NotificationSubscribeModel } from 'models/NotificationModel';

export const FormNotification = observer(() => {
  const { profileNotification } = useProfileSettingsStore();
  const { notificationsSettings, loadingNotificationUpdateStage, putNotivicationSubscripe } = profileNotification;
  const onChnageNotification = async (id: number, value: boolean, field: keyof INotivicationSubscribeUpdateBody) => {
    const item = notificationsSettings.getEntity(id);
    if (item) {
      const entity = new NotificationSubscribeModel({
        ...item,
        [field]: value,
      });
      notificationsSettings.updateEntity({ entity });
      putNotivicationSubscripe(entity).then((res) => {
        if (res.isError) {
          notificationsSettings.updateEntity({ entity: item });
        }
      });
    }
  };
  return (
    <div className={s.form}>
      <Typography.Title className={s.title__form} level={2}>
        Настройки уведомлений
      </Typography.Title>

      {notificationsSettings.items.map((notification) => (
        <div className={s.notif__item} key={notification.id}>
          <Typography.Title level={3} className={s.title}>
            {notification.name}
          </Typography.Title>
          {notification.description !== null && (
            <Typography.Title level={5} className={s.label}>
              {notification.description}
            </Typography.Title>
          )}

          <div className={s.checkbox__list}>
            <Checkbox id="email_notification" checked={true}>
              Email
            </Checkbox>

            {notification.site_notification !== null && notification.site_notification !== undefined && (
              <Checkbox
                id="site_notification"
                onChange={(e) => onChnageNotification(notification.id, e.target.checked, 'site_notification')}
                checked={notification.site_notification}
                // disabled={notificationsSettings.loadingStage.isLoading || loadingNotificationUpdateStage.isLoading}
              >
                Личный кабинет
              </Checkbox>
            )}
            {notification.telegram_notification !== null && notification.telegram_notification !== undefined && (
              <Checkbox
                id="telegram_notification"
                onChange={(e) => onChnageNotification(notification.id, e.target.checked, 'telegram_notification')}
                checked={notification.telegram_notification}
                // disabled={notificationsSettings.loadingStage.isLoading || loadingNotificationUpdateStage.isLoading}
              >
                Telegram
              </Checkbox>
            )}
          </div>
        </div>
      ))}
    </div>
  );
});
