import { PageContainer } from 'components/PageContainer';
import { observer } from 'mobx-react-lite';
import React from 'react';
import s from '../StaticPages.module.scss';
import { Typography } from 'antd';

const AgreePage = observer(() => {
  return (
    <PageContainer>
      <div className={s.center__text}>
        <Typography.Title level={3}>СОГЛАСИЕ</Typography.Title>
        <Typography.Title level={3} className={s.title__agree}>
          субъекта персональных данных на обработку его персональных данных
        </Typography.Title>
      </div>
      <Typography.Text className={s.city}>г. Москва</Typography.Text>
      <div className={s.text__container}>
        <Typography.Text>
          <span className={s.text__padding__left}></span>
          Я, настоящим даю свое согласие на обработку ООО «КУПИЛОТ» моих персональных данных и подтверждаю, что, давая
          такое согласие, я действую своей волей и в своих интересах.
        </Typography.Text>
        <Typography.Text>
          <span className={s.text__padding__left}></span>
          Согласие дается мною для целей: информирования субъекта персональных данных посредством отправки электронных
          писем на адреса электронной почты, сообщений и звонков рекламного характера на контактные номера телефонов и
          сообщений и звонков в мессенджеры Telegram и WhatsApp и распространяется на следующую информацию: фамилия,
          имя, отчество (последнее - при наличии), контактные телефоны (рабочий, мобильный), адреса электронной почты и
          идентификационный номер в мессенджере Telegram (Telegram ID).
        </Typography.Text>
        <Typography.Text>
          <span className={s.text__padding__left}></span>
          Настоящее согласие предоставляется на осуществление любых действий в отношении моих персональных данных,
          которые необходимы или желаемы для достижения указанных выше целей, включая (без ограничения) сбор,
          систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание,
          блокирование, уничтожение, а также осуществление любых иных действий с моими персональными данными с учетом
          федерального законодательства.
        </Typography.Text>
        <Typography.Text>
          <span className={s.text__padding__left}></span>
          Настоящее согласие предоставляется на передачу моих персональных данных в составе: фамилия, имя, отчество
          (последнее - при наличии), контактные номера телефонов (рабочий, мобильный), адреса электронной почты и
          идентификационный номер в мессенджере Telegram (Telegram ID) по мере необходимости в соответствии с
          законодательством Российской Федерации а также нормативно-правовыми актами, а также в уполномоченные
          государственные органы (суды всех инстанций, органы прокуратуры, полиция, антимонопольные органы и т.д.),
          направившие Оператору соответствующие постановления, указания, запросы на предоставление сведений, включающие
          в себя мои персональные данные.
        </Typography.Text>
        <Typography.Text>
          <span className={s.text__padding__left}></span>В случае неправомерного использования предоставленных мною
          персональных данных согласие отзывается моим письменным заявлением, а также в уполномоченные государственные
          органы, направившие Оператору соответствующие постановления или указания.
        </Typography.Text>
        <Typography.Text>
          <span className={s.text__padding__left}></span>
          Данное согласие считается подписанным и действует с момента регистрации на сайте ООО «КУПИЛОТ» (https://
          kupilot.ru).
        </Typography.Text>
      </div>
    </PageContainer>
  );
});

export default AgreePage;
