import { NavigateFunction } from 'react-router-dom';

import { AnalyticsStore, IAnalyticsStore } from 'stores/AnalyticsStore';
import { AuthStore } from 'stores/AuthStore';
import { IOnboardingStore, OnboardingStore } from 'stores/OnboardingStore';
import { ProfileNotificationStore, ProfileNotificationSubscribeStore } from 'stores/ProfileNotificationStore';
import { RobotStore } from 'stores/RobotStore';
import { ThemeStore } from 'stores/ThemeStore/ThemeStore';
import { UserStore } from 'stores/UserStore';

import { RouterStore } from './RouterStore';
import { IRootStore } from './types';
import { ProfileServicesStore } from 'stores/ProfileServicesStore';
import { TariffsStore } from 'stores/TariffsStore';

type RootStoreParams = {
  navigate: NavigateFunction;
};

export class RootStore implements IRootStore {
  readonly routerStore: RouterStore;
  readonly profileService: ProfileServicesStore = new ProfileServicesStore();
  readonly analyticsStore: IAnalyticsStore = new AnalyticsStore();
  readonly userStore: UserStore = new UserStore();
  readonly authStore: AuthStore = new AuthStore({
    userStore: this.userStore,
    analyticsStore: this.analyticsStore,
    profileServicesReset: this.profileService.reset,
  });
  readonly onboardingStore: IOnboardingStore;
  readonly notificationStore: ProfileNotificationStore = new ProfileNotificationStore();
  readonly robotStore: RobotStore = new RobotStore({ userStore: this.userStore });
  readonly themeStore: ThemeStore = new ThemeStore();
  readonly profileNotificationSubscribe: ProfileNotificationSubscribeStore = new ProfileNotificationSubscribeStore();
  readonly tariffsStore: TariffsStore = new TariffsStore();

  constructor({ navigate }: RootStoreParams) {
    this.routerStore = new RouterStore({ navigate });
    this.onboardingStore = new OnboardingStore({ routerStore: this.routerStore });
  }
}
