import { Spin } from 'antd';
import Breadcrumb, { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { routerUrls } from 'config/routes';
import useToggleState from 'hooks/useToggleState';
import { useWidth } from 'hooks/useWidth';
import { LotListProfileStoreProvider } from 'stores/LotListProfileStore/context';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';

import { ListTypeTabbar } from './ListTypeTabbar';
import { ListWithFilters } from './ListWithFilters';
import { TotalItemsCount } from './TotalItemsCount';

import s from './Profile.module.scss';

const PAGE_TITLE = 'Личный кабинет';

type ProfileProps = {
  className?: string;
};

const Profile = ({ className }: ProfileProps): React.ReactElement<ProfileProps> => {
  const rootStore = useRootStore();

  const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
    { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
    { title: PAGE_TITLE },
  ];

  const { opened, toggle: toggleFiltersCollapsed } = useToggleState();
  const { isPhone } = useWidth();

  const isFiltersCollapsed = isPhone ? opened : false;

  if (!rootStore.authStore.authorizingStage.isFinished) {
    return (
      <div className={s.profile__spinner}>
        <Spin size="large" />
      </div>
    );
  }

  if (!rootStore.userStore.authorized) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }

  return (
    <PageContainer withoutBottomPadding className={cn(s.profile, className)}>
      <Breadcrumb className={s.profile__breadcrumbs} itemRender={breadcrumbItemsRender} items={BREADCRUMB_ITEMS} />
      <PageListTitle className={s.profile__title} opened={isFiltersCollapsed} toggle={toggleFiltersCollapsed}>
        {PAGE_TITLE}
      </PageListTitle>
      <LotListProfileStoreProvider>
        {!isFiltersCollapsed && (
          <>
            <ListTypeTabbar />
            <TotalItemsCount />
          </>
        )}
        <ListWithFilters isFiltersCollapsed={isFiltersCollapsed} isProfile />
      </LotListProfileStoreProvider>
    </PageContainer>
  );
};

const ProfileRedirect = () => {
  return <Navigate to={routerUrls.profileSettings.create()} />;
};

export default observer(ProfileRedirect);
