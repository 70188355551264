import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { useLocalStore } from 'hooks/useLocalStore';
import { useRootStore } from 'stores/RootStore';

import { LotListProfileRobotStore } from './LotListProfileRobotStore';

type LotListProfileStoreContextValue = LotListProfileRobotStore;

const LotListProfileRobotStoreContext = React.createContext<LotListProfileStoreContextValue | null>(null);

export const LotListProfileRobotStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [queryParams] = useSearchParams();
  const rootStore = useRootStore();

  const store = useLocalStore(() => new LotListProfileRobotStore({ queryParams, rootStore }));

  React.useEffect(() => {
    store.fetchLotItems({ replace: true });
  }, []);

  useKeyboardEvent('Enter', store.filters.setSideFilters);

  return <LotListProfileRobotStoreContext.Provider value={store}>{children}</LotListProfileRobotStoreContext.Provider>;
};

export const useLotListProfileRobotStore = () => {
  const context = React.useContext(LotListProfileRobotStoreContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
