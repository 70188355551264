import { Button, Typography } from 'antd';
import cn from 'classnames';
import React from 'react';

import { SupportModal } from 'components/SupportModal';
import useToggleState from 'hooks/useToggleState';

import s from './Questions.module.scss';

export const Questions = ({ className = '' }: { className?: string }) => {
  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();
  return (
    <>
      <div className={cn(s.questions, className)}>
        <Typography.Title className={cn(s.questions__title)} level={3}>
          Остались вопросы?
        </Typography.Title>
        <Typography.Text className={cn(s.questions__subtitle)}>Свяжитесь с нами</Typography.Text>
        <Typography.Text className={cn(s.questions__desc)}>
          Мы всегда готовы помочь вам с вопросами по тарифам, услугам и участию в торгах.{' '}
          <span role="button" onClick={openSupportModal}>
            Оставьте заявку
          </span>
          , и наши специалисты свяжутся с вами в кратчайшие сроки. Вы можете связаться с нами через форму обратной
          связи, по телефону или по электронной почте. Мы гарантируем быстрый и профессиональный ответ.
        </Typography.Text>
        <Button onClick={openSupportModal} className={s.questions__btn} type="primary">
          Написать
        </Button>
      </div>
      <SupportModal opened={openedSupportModal} close={closeSupportModal} />
    </>
  );
};
