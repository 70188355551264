import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { TotalItemsCount as TotalItemsCountBase } from 'components/TotalItemsCount';
import { capitalize } from 'utils/capitalize';
import { pluralizePreselectedWithoutCount } from 'utils/pluralizer';
import { useLotListProfileHistoryStore } from 'stores/LotListProfileHistoryStore/context';

type TotalItemsCountProps = {
  className?: string;
};

const TotalItemsCount = ({ className }: TotalItemsCountProps): React.ReactElement<TotalItemsCountProps> => {
  const { lots } = useLotListProfileHistoryStore();
  const prefix = capitalize(pluralizePreselectedWithoutCount(lots.total.value ?? 0, 'найден'));

  return <TotalItemsCountBase className={className} itemsCount={lots.total.value} prefix={prefix} />;
};

export default observer(TotalItemsCount);
