import { Banner, BannerPlaceholder } from 'components/Banner';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLotListStore } from 'stores/LotListCatalogStore/context';
import s from './BannerLots.module.scss';

export const BannerLots = observer(() => {
  const navigate = useNavigate();
  const { banners, fetchBanners } = useLotListStore();
  const banner = useMemo(() => {
    return banners.items[0] ? banners.items[0] : null;
  }, [banners.items, banners.loadingStage.isLoading]);

  useEffect(() => {
    if (banners.items.length === 0) {
      fetchBanners();
    }
  }, []);

  return (
    <div>
      {banners.loadingStage.isLoading || banner === null ? (
        <BannerPlaceholder className={s.bannerPlaceholder} />
      ) : (
        <Banner
          title={banner.header}
          description={banner.text}
          backgroundImage={banner.image || undefined}
          className={s.banner}
          actions={
            banner.link
              ? [
                  {
                    label: 'Попробовать',
                    onClick: () => {
                      if (banner.link.includes('http')) {
                        window.location.href = banner.link;
                      } else {
                        navigate(banner.link);
                      }
                    },
                  },
                ]
              : undefined
          }
        />
      )}
    </div>
  );
});
