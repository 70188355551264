import { Button, Card, Popconfirm, Typography } from 'antd';
import cn from 'classnames';
import React, { useState } from 'react';

import s from './SubscriptionsCard.module.scss';

interface SubscriptionsCardProps {
  className?: string;
  id: number;
  title: string;
  description: string;
  advantages: string[];
  onClick: (id: number, optionId: number) => void;
  isLoading: boolean;
  isActive: boolean;
  price: string;
  hidePrice?: boolean;
  optionId: number;
  tariffId: number;
  hideAction?: boolean;
}

const SubscriptionsCard = ({
  id,
  className = '',
  title,
  description,
  advantages,
  onClick,
  isLoading,
  isActive,
  price,
  hidePrice,
  tariffId,
  optionId,
  hideAction,
}: SubscriptionsCardProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const onCancel = () => setOpen(false);
  const onConfirm = () => {
    onClick(tariffId, optionId);
    setOpen(false);
  };
  return (
    <Card className={cn(className, s.card, { [s.card_active]: isActive })} title={title}>
      <Typography.Paragraph
        className={s.card__description}
        ellipsis={{
          rows: 5,
        }}
      >
        {description}
      </Typography.Paragraph>
      {!isActive ? (
        <Popconfirm
          open={open}
          onOpenChange={setOpen}
          title="Подтвердите действие"
          description="Выбрать данный тариф?"
          okText="Да"
          cancelText="Нет"
          onCancel={onCancel}
          onConfirm={onConfirm}
          overlayClassName={s.popover}
        >
          <Button
            type="default"
            size="small"
            className={`${s.card__button} ${hideAction ? s.card__button__hide : ''}`}
            disabled={isLoading || isActive}
            onClick={() => setOpen(true)}
            loading={isLoading}
          >
            Выбрать
          </Button>
        </Popconfirm>
      ) : (
        <Button
          type="default"
          size="small"
          className={`${s.card__button} ${hideAction ? s.card__button__hide : ''}`}
          disabled={isLoading || isActive}
          loading={isLoading}
        >
          Выбран
        </Button>
      )}
      {!Boolean(hidePrice) && (
        <div className={s.card__price}>
          <Typography.Text className={s.card__price_title}>Цена: </Typography.Text>
          <Typography.Text className={s.card__price_value}>{price} ₽/мес</Typography.Text>
        </div>
      )}

      {advantages.length > 0 && <Typography.Text className={s.card__advantages_title}>Включает:</Typography.Text>}
      <ul className={s.card__advantages_list}>
        {advantages.map((item, idx) => (
          <li key={idx} className={s.card__advantages_item}>
            {item}
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default SubscriptionsCard;
