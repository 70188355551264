import { observer } from 'mobx-react-lite';
import { TableSection } from '../../Table/Table';
import React, { useMemo } from 'react';
import { Collapse, Spin, Typography } from 'antd';
import s from '../index.module.scss';
import { useWidth } from 'hooks/useWidth';
import { useRootStore } from 'stores/RootStore';

export const TableAudit = observer(() => {
  const { width = window.innerWidth } = useWidth();
  const { profileService } = useRootStore();
  const { servicesLegal, loadingStage } = profileService;
  const isMoble = useMemo(() => {
    return width < 1280;
  }, [width]);

  const collapseItem = {
    key: '1',
    label: (
      <Typography.Title className={s.title} level={2}>
        Юридический аудит
      </Typography.Title>
    ),
    children: (
      <TableSection
        data={servicesLegal.list.items}
        loading={loadingStage.isLoading}
        total={servicesLegal.currentTotalItems}
        page={servicesLegal.offset.value / 10 + 1}
        setPage={(page: number) => {
          servicesLegal.offset.change((page - 1) * 10);
        }}
        onChangeSearch={(value) => {
          servicesLegal.search.change(value);
        }}
        filters={servicesLegal.sortStatus}
        orderingDate={servicesLegal.orderingDate}
      />
    ),
  };

  if (servicesLegal.isListInitialLoading) {
    return (
      <div className={s.loading}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={s.section}>
      {isMoble ? (
        <Collapse
          className={s.collapse}
          defaultActiveKey={['1']}
          ghost
          expandIconPosition="end"
          items={[collapseItem]}
        />
      ) : (
        <TableSection
          title="Юридический аудит"
          data={servicesLegal.list.items}
          loading={loadingStage.isLoading}
          total={servicesLegal.currentTotalItems}
          page={servicesLegal.offset.value / 10 + 1}
          setPage={(page: number) => {
            servicesLegal.offset.change((page - 1) * 10);
          }}
          onChangeSearch={(value) => {
            servicesLegal.search.change(value);
          }}
          filters={servicesLegal.sortStatus}
          orderingDate={servicesLegal.orderingDate}
        />
      )}
    </div>
  );
});
