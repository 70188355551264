import { Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Slider, { SwiperInstance } from 'components/Slider';
import TimelineStage from 'pages/LotPage/LotTimeline/TimelineStage';
import { useLotPageStore } from 'stores/LotPageStore';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import s from './Timeline.module.scss';

type TimelineProps = {
  className?: string;
};

const Timeline: React.FC<TimelineProps> = ({ className }) => {
  const { lot } = useLotPageStore();
  const { timelineModel } = lot.value;

  const isMultipleStages = timelineModel.stages.length > 1;

  const [swiperParams, setSwiperParams] = React.useState<Pick<SwiperInstance, 'isBeginning' | 'isEnd'>>({
    isBeginning: false,
    isEnd: false,
  });
  const [active, setActive] = React.useState(0);
  const handleSetSwiperParams = React.useCallback(
    (swiper: SwiperInstance) => {
      setSwiperParams({
        isBeginning: swiper.isBeginning,
        isEnd: swiper.isEnd,
      });
    },
    [setSwiperParams],
  );

  const handleSlideChange = React.useCallback(
    (swiper: SwiperInstance) => {
      handleSetSwiperParams(swiper);
      setActive(swiper.activeIndex);
    },
    [handleSetSwiperParams],
  );

  const slides = timelineModel.stages.map((item, index) => (
    <div key={item.title} className={s.timeline__item}>
      <Typography.Text className={s['timeline__item-title']}>{item.title}</Typography.Text>
      <div className={`${s.timelineWrap} ${index === timelineModel.stages.length - 1 ? s.timelineWrapEnd : ''}`}>
        <TimelineStage key={item.title} model={item} active={timelineModel.activePhase === item.phase} />
      </div>
    </div>
  ));

  return (
    <div className={cn(s.timeline, className)}>
      <Slider
        onSlideChange={handleSlideChange}
        onSwiper={handleSetSwiperParams}
        slidesPerView={1.4}
        initialSlide={timelineModel.activePhaseIndex}
        slides={slides}
        className={cn(!isMultipleStages && s.timeline__slider_single)}
        modules={[Pagination]}
      ></Slider>
      <div className={s.pagination}>
        {slides.map((_, index) => (
          <div
            key={index}
            className={`${s.pagination__item} ${active === index ? s.pagination__item__active : ''}`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default observer(Timeline);
