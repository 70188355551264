import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { routerUrls } from 'config/routes';
import { AnalyticsEvent } from 'entities/analytics';
import { LotModel } from 'models/LotModel';
import { useRootStore } from 'stores/RootStore';

import s from './Layout.module.scss';

type LayoutProps = {
  item: LotModel;
  style?: React.CSSProperties;
  className?: string;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
  column?: boolean;
  mobileHover?: boolean;
};

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  item,
  className,
  style,
  children,
  handleMouseEnter,
  handleMouseLeave,
  column,
  mobileHover,
}) => {
  const { analyticsStore } = useRootStore();

  const handleCardClick = React.useCallback(() => {
    analyticsStore.trackEvent({ event: AnalyticsEvent.lotsListClickOnLot, payload: { lot_name: item.title } });
  }, [analyticsStore]);

  return (
    <Link
      className={cn(s['lot-card'], className, mobileHover ? s.hoverMobile : s.hoverOffMobile)}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to={routerUrls.lotDetail.create(item.id)}
      target="_blank"
      onClick={handleCardClick}
      rel="noreferrer"
    >
      <div className={cn(s['lot-card__layout'], column ? s.column : s.row)}>{children}</div>
    </Link>
  );
};

export default observer(Layout);
