import * as React from 'react';

import { ButtonIcon } from 'components/ButtonIcon';
import { Tooltip } from 'components/Tooltip';
import cn from 'classnames';
import { favoriteButtonConfigs } from '../configs';
import { FavoriteButtonMiniProps } from '../types';

import s from './FavoriteButton.module.scss';

const FavoriteButtonMini: React.FC<FavoriteButtonMiniProps> = ({
  className,
  isFavorite,
  onClick,
  withoutTooltip,
  id,
}) => {
  const Icon = favoriteButtonConfigs[isFavorite ? 'favorite' : 'unfavorite'].icon;
  const tooltipText = favoriteButtonConfigs[isFavorite ? 'favorite' : 'unfavorite'].tooltipText;

  const button = (
    <ButtonIcon
      Icon={Icon}
      id={id}
      className={cn('', isFavorite ? s['favorite-button-active'] : '', s['favorite-button'], className)}
      onClick={onClick}
      aria-label={tooltipText}
    />
  );

  if (withoutTooltip) {
    return button;
  }

  return (
    <Tooltip placement="topRight" title={tooltipText}>
      {button}
    </Tooltip>
  );
};

export default React.memo(FavoriteButtonMini);
