import { Button, notification } from 'antd';
import { Banner } from 'components/Banner';
import { useWidth } from 'hooks/useWidth';
import React, { useCallback } from 'react';
import s from './BannerPage.module.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { useServicesStore } from 'stores/ServicesStore/context';
import { useRootStore } from 'stores/RootStore';
import { AnalyticsEvent } from 'entities/analytics';
import { WarningOutlined, CheckOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { ServicesSlugStatic } from 'entities/services/services';
import { RouteFullPath } from 'config/routes';

const key = 'updatable-banner-tariff';

export const BannerPage = observer(
  ({
    bannerImg,
    title,
    description,
    price,
    list,
    className,
    serviceSlug,
    printLabelPrice = false,
  }: {
    bannerImg?: string;
    title: string;
    price: number | string;
    description: string;
    list?: string[];
    className?: string;
    serviceSlug?: string;
    printLabelPrice?: boolean;
  }) => {
    const { userStore, authStore, analyticsStore, profileService } = useRootStore();
    const { sendOrder, loadingStage } = useServicesStore();
    const { width = window.innerWidth } = useWidth();
    const [api, contextHolder] = notification.useNotification();
    let [searchParams] = useSearchParams();
    const lotId = searchParams.get('lot');

    const currentService = React.useMemo(() => {
      return profileService.list.items.find((item) => item.slug === serviceSlug);
    }, [
      loadingStage.isLoading,
      userStore.authorized,
      lotId,
      profileService.listServicesItemLotIds,
      profileService.list.items,
      profileService.isLodingService,
      serviceSlug,
    ]);

    const openNotification = (success: boolean, message: string, description: string | React.ReactNode) => {
      api.info({
        message: message,
        description: description,
        placement: 'topRight',
        icon: success ? <CheckOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'red' }} />,
        key,
      });
    };

    const handleLoginButtonClick = useCallback(() => {
      authStore.popupController.open();
      authStore.mode.change('login');
      analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
    }, [authStore.popupController, analyticsStore]);

    const onSend = () => {
      if (!userStore.authorized) {
        handleLoginButtonClick();
      } else if (userStore.user?.userId) {
        if (currentService) {
          const cost = ServicesSlugStatic.online === serviceSlug ? '2000' : currentService.cost || '0';
          sendOrder({
            lot: lotId ? parseInt(lotId) : null,
            cost: cost,
            user: userStore.user.userId,
            service: currentService.id,
            user_tariff: userStore.activeUserTariff?.tariff_id || 0,
            slug: currentService.slug,
          }).then((res) => {
            if (res) {
              openNotification(
                true,
                'Ваша заявка',
                <div>
                  была успешно отправлена!
                  {lotId === null && (
                    <>
                      {' '}
                      Перейдите к выбору <Link to={'/' + RouteFullPath.lots}>лота по cсылке </Link>
                    </>
                  )}
                </div>,
              );
              profileService.addLotId({
                lotId: lotId ? parseInt(lotId) : null,
                serviceId: currentService.id,
                slug: currentService.slug,
              });
            } else {
              openNotification(false, 'Ошибка!', 'Повторите через время.');
            }
          });
        } else {
          openNotification(false, 'Ошибка!', 'Во время расчета услуги произошла ошибка');
        }
      }
    };

    const isSended = React.useMemo(() => {
      if (!userStore.authorized || lotId === null || serviceSlug === undefined) {
        return false;
      }

      return !!profileService.listServicesItemLotIds.find(
        (item) => item.lotId === parseInt(lotId) && item.slug === serviceSlug,
      );
    }, [
      loadingStage.isLoading,
      userStore.authorized,
      lotId,
      profileService.listServicesItemLotIds,
      profileService.list.items,
      profileService.isLodingService,
      serviceSlug,
    ]);

    const priceLabel = React.useMemo(() => {
      if (printLabelPrice) {
        return price;
      }
      return currentService ? (currentService.cost || '0') + ' ' + price : 'Войдите в аккаунт';
    }, [currentService, printLabelPrice, price, serviceSlug]);

    return (
      <>
        {contextHolder}
        <Banner
          title={title}
          description={description}
          backgroundImage={width >= 768 ? bannerImg : undefined}
          className={s.bgBottom}
          clsx={{
            root: className,
            body: s.bannerBody,
            desc: s.bannerDesc,
            title: s.bannerTitle,
          }}
        >
          <div className={s.bannerChildren}>
            {list && (
              <ul className={s.bannerList}>
                {list?.map((item, index) => (
                  <li className={s.bannerListItem} key={index}>
                    {item} <span>,</span>
                  </li>
                ))}
              </ul>
            )}

            <div className={s.bannerActions + ' ' + (list === undefined ? s.margin : '')}>
              <Button
                onClick={onSend}
                className={s.bannerBtn}
                disabled={isSended}
                loading={loadingStage.isLoading || profileService.isLodingService}
              >
                {isSended ? `Заявка отправлена` : 'Оставить заявку'}
              </Button>
              <span className={s.price}>{priceLabel}</span>
            </div>
          </div>
        </Banner>
      </>
    );
  },
);
