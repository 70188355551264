import * as React from 'react';
import { useLocalStore } from 'hooks/useLocalStore';

import { ProfileSettingsStore } from './ProfileSettingsStore';
import { useRootStore } from 'stores/RootStore';

type ProfileSettingsStoreContextValue = ProfileSettingsStore;

const ProfileSettingsStoreContext = React.createContext<ProfileSettingsStoreContextValue | null>(null);

export const ProfileSettingsStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const rootStore = useRootStore();
  const store = useLocalStore(() => new ProfileSettingsStore({ rootStore }));
  return <ProfileSettingsStoreContext.Provider value={store}>{children}</ProfileSettingsStoreContext.Provider>;
};

export const useProfileSettingsStore = () => {
  const context = React.useContext(ProfileSettingsStoreContext);

  if (!context) {
    throw new Error('LotListProfileStoreContext was not provided');
  }

  return context;
};
