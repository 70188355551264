import { action, computed, makeObservable, observable } from 'mobx';

import { ValueModel } from 'models/ValueModel';
import { emailValidator, stringLengthValidator } from 'utils/validator';

export class MessageFormModel {
  readonly name: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 1, maxLength: 100 }),
  ]);
  readonly email: ValueModel<string> = new ValueModel<string>('', [emailValidator]);
  readonly message: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 10, maxLength: this.maxDescriptionLength }),
  ]);

  isValidateOnChange: boolean = false;

  constructor() {
    makeObservable(this, {
      isValidateOnChange: observable,

      hasErrors: computed,

      validate: action.bound,
      setIsValidateOnChange: action.bound,
    });
  }

  get maxDescriptionLength(): number {
    return 300;
  }

  get hasErrors(): boolean {
    return this.name.isError || this.email.isError || this.message.isError;
  }

  validate(): boolean {
    const nameHasError = this.name.validate();
    const emailHasError = this.email.validate();
    const descriptionHasError = this.message.validate();

    return nameHasError || emailHasError || descriptionHasError;
  }

  setIsValidateOnChange(value: boolean): void {
    this.isValidateOnChange = value;
  }

  resetAfterSubmission(): void {
    this.message.reset();
  }

  resetFull(): void {
    this.name.reset();
    this.email.reset();
    this.message.reset();
  }
}
