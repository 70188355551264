import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ValueModel } from 'models/ValueModel';
import {
  confirmhPasswordValidator,
  emailValidator,
  phoneStringValidator,
  stringLengthValidator,
} from 'utils/validator';

export class ProfileSettingsPasswordFormModel {
  readonly password: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 5, maxLength: 100 }),
  ]);
  readonly newPassword: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 5, maxLength: 100 }),
  ]);
  readonly confirmNewPassword: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 5, maxLength: 100 }),
    confirmhPasswordValidator(),
  ]);

  isValidateOnChange: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {
        isValidateOnChange: observable,

        hasErrors: computed,

        validate: action.bound,
        setIsValidateOnChange: action.bound,
      },
      { autoBind: true },
    );
  }

  get hasErrors(): boolean {
    return this.password.isError || this.newPassword.isError || this.confirmNewPassword.isError;
  }

  validate(): boolean {
    const newPasswordError = this.newPassword.validate();
    const confirmNewPasswordError = this.confirmNewPassword.validate<string>(this.newPassword.value);
    const passwordError = this.password.validate();

    return newPasswordError || confirmNewPasswordError || passwordError;
  }

  setIsValidateOnChange(value: boolean): void {
    this.isValidateOnChange = value;
  }

  resetFull(): void {
    this.password.reset();
    this.newPassword.reset();
    this.confirmNewPassword.reset();
  }
}

export class ProfileSettingsPersonalDataFormModel {
  readonly firstName: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 1, maxLength: 100 }),
  ]);
  readonly lastName: ValueModel<string> = new ValueModel<string>('', [
    stringLengthValidator({ minLength: 1, maxLength: 100 }),
  ]);
  readonly patronymic: ValueModel<string> = new ValueModel<string>('');
  readonly email: ValueModel<string> = new ValueModel<string>('', [emailValidator]);
  readonly phone: ValueModel<string | null> = new ValueModel<string | null>(null, [phoneStringValidator]);
  readonly region: ValueModel<string | null> = new ValueModel<string | null>(null);
  readonly userType: ValueModel<string | null> = new ValueModel<string | null>(null);

  isValidateOnChange: boolean = false;

  constructor() {
    makeAutoObservable(
      this,
      {
        isValidateOnChange: observable,

        hasErrors: computed,

        validate: action.bound,
        setIsValidateOnChange: action.bound,
      },
      { autoBind: true },
    );
  }

  get hasErrors(): boolean {
    return this.firstName.isError || this.email.isError || this.lastName.isError || this.phone.isError;
  }

  validate(): boolean {
    const nameHasError = this.firstName.validate();
    const emailHasError = this.email.validate();
    const phoneHasError = this.phone.validate();
    const lastnameHasError = this.lastName.validate();

    return nameHasError || emailHasError || phoneHasError || lastnameHasError;
  }

  setIsValidateOnChange(value: boolean): void {
    this.isValidateOnChange = value;
  }

  resetFull(): void {
    this.firstName.reset();
    this.email.reset();
    this.lastName.reset();
    this.phone.reset();
  }
}
