import { StarFilled } from '@ant-design/icons';

export const favoriteButtonConfigs: Record<
  'favorite' | 'unfavorite',
  { icon: React.FC; tooltipText: string; buttonText: string }
> = {
  favorite: {
    icon: StarFilled,
    tooltipText: 'Удалить из избранного',
    buttonText: 'Удалить из избранного',
  },
  unfavorite: {
    icon: StarFilled,
    tooltipText: 'Добавить в избранное',
    buttonText: 'Добавить в избранное',
  },
};
