import { computed, makeObservable } from 'mobx';
import qs from 'query-string';

import { FilterOption, FloorOptions, IFilterGeography, LotFiltersResponse } from 'entities/lotFilters';
import { FilterModel } from 'models/FilterModel';
import { FilterModelMulti, FilterModelMultiString } from 'models/FilterModelMulti';
import { FilterModelRange } from 'models/FilterModelRange';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { ValueModel } from 'models/ValueModel';
import { LotListCommonFiltersModel } from 'stores/LotListCommonFiltersModel';
import { RouterStore } from 'stores/RootStore/RouterStore';
import { BaseResponse } from 'types/meta';
import { convertToArray } from 'utils/convertToArray';

import { apiUrls } from '../../../config/apiUrls';

import { OrganizerFilterModel } from './OrganizerFilterModel';
import { DEFAULT_AGGREGATED_TYPE, FLOOR_OPTIONS_DEFAULT } from './config';
import type { LotCatalogQueryParams, ObjectTypeValue } from './types';
import { apiCustom } from 'utils/api';
import { ResponseListApi } from 'entities';
import { LotSource, lotSourceConfig } from 'entities/lot';

type LotListFiltersModelParams = {
  params: URLSearchParams;
  routerStore: RouterStore;
};

export type FloorOptionsType = {
  [key in FloorOptions]: boolean;
};

export class LotListCatalogFiltersModel extends LotListCommonFiltersModel<LotCatalogQueryParams> {
  readonly loadingFiltersStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingCitiesStage: LoadingStageModel = new LoadingStageModel();
  readonly loadingRegionsStage: LoadingStageModel = new LoadingStageModel();

  readonly search = new ValueModel<string>('');
  readonly aggregatedType = new FilterModel<FilterOption<string>, ObjectTypeValue>(DEFAULT_AGGREGATED_TYPE.id);
  readonly area = new FilterModelRange(null, null);
  readonly floor = new FilterModelRange(null, null);
  readonly amountFloors = new FilterModelRange(null, null);
  readonly price = new FilterModelRange(null, null);
  readonly organizerFilter = new OrganizerFilterModel();
  readonly region = new FilterModelMulti();
  readonly city = new FilterModelMulti();
  readonly geography = new FilterModelMulti();
  readonly floorOptions: ValueModel<FloorOptionsType> = new ValueModel<FloorOptionsType>(FLOOR_OPTIONS_DEFAULT);
  readonly cadastralNumber = new ValueModel<string>('');
  readonly sections = new FilterModelMultiString();
  readonly areas = new FilterModelMultiString();
  readonly showUnknownAmountOfFloors = new ValueModel<boolean | null>(null);
  private apiRequest = apiCustom;
  private _abortController?: AbortController;

  constructor({ params, routerStore }: LotListFiltersModelParams) {
    const initParams = LotListCatalogFiltersModel.normalizeQueryParams(params);

    super({
      params: initParams,
      routerStore,
    });

    this.search.change(initParams.search);
    this.area.valueMin.change(initParams.area_from);
    this.area.valueMax.change(initParams.area_to);
    this.price.valueMin.change(initParams.price_from);
    this.price.valueMax.change(initParams.price_to);
    this.organizerFilter.organizerInitValue.change(initParams.organizer);
    this.floor.valueMin.change(initParams.floor_from);
    this.floor.valueMax.change(initParams.floor_to);
    this.amountFloors.valueMin.change(initParams.amount_of_floors_from);
    this.amountFloors.valueMax.change(initParams.amount_of_floors_to);
    this.cadastralNumber.change(initParams.cadastral_number);
    this.areas.setSelected(initParams.source);
    this.sections.setSelected(initParams.sections);
    this.geography.setSelected(initParams.geography);

    if (initParams.show_unknown_amount_of_floors && initParams.show_unknown_amount_of_floors === true) {
      this.showUnknownAmountOfFloors.change(initParams.show_unknown_amount_of_floors);
    }

    if (initParams.floor_options) {
      const params_floor_options: FloorOptions[] = JSON.parse(initParams.floor_options);
      const floor_options = { ...this.floorOptions.value };
      params_floor_options.forEach((key) => {
        floor_options[key] = true;
      });
      this.floorOptions.change(floor_options);
    }

    makeObservable(this, {
      organizer: computed,
      isEmptySideFilters: computed,
      isAllAggregationType: computed,
    });
  }

  get organizer(): FilterModelMulti {
    return this.organizerFilter.organizer;
  }

  get isEmptySideFilters(): boolean {
    let isEmptyFloorOptions = true;

    Object.keys(this.floorOptions.value).forEach((key) => {
      if (this.floorOptions.value[key as FloorOptions] === true) {
        isEmptyFloorOptions = false;
      }
    });

    const isEmptyCadastral = this.cadastralNumber.value === '' || this.cadastralNumber.value === null;

    return (
      this.auctionType.isEmpty &&
      this.objectType.isEmpty &&
      this.area.isEmpty &&
      this.price.isEmpty &&
      this.status.isEmpty &&
      this.formType.isEmpty &&
      this.region.isEmpty &&
      this.city.isEmpty &&
      this.organizer.isEmpty &&
      this.floor.isEmpty &&
      this.amountFloors.isEmpty &&
      this.geography.isEmpty &&
      this.sections.isEmpty &&
      this.areas.isEmpty &&
      isEmptyCadastral &&
      isEmptyFloorOptions
    );
  }

  get isAllAggregationType(): boolean {
    return this.params.aggregated_type === DEFAULT_AGGREGATED_TYPE.id;
  }

  // Методы для вызова из компонентов
  setSearchValue = (value?: string): void => {
    this.setFilterParam('search', value ?? this.search.value);
  };

  setObjectType = (value: ObjectTypeValue): void => {
    this.aggregatedType.selectedValue.change(value);
    //TODO: 145 - 147 изменено для тестов
    this.setSideFilters();
    // this.clearSideFilters();
    // this.fetchCatalogFiltersOptions();
  };

  async setRegionValue(value: number) {
    this.region.toggleValue(value);
    this.fetchCities();
  }

  setSideFilters = (): void => {
    this.resetOffset();
    const _floor_options: string[] = [];
    Object.keys(this.floorOptions.value).forEach((key) => {
      if (this.floorOptions.value[key as FloorOptions] === true) {
        _floor_options.push(key);
      }
    });

    const params = {
      aggregated_type: this.aggregatedType.selectedValue.value,
      auction_type: this.auctionType.selectedValue.value,
      object_type: this.objectType.selectedValue.value,
      price_from: this.price.valueMin.value,
      price_to: this.price.valueMax.value,
      area_from: this.area.valueMin.value,
      area_to: this.area.valueMax.value,
      status: this.status.selectedValue.value,
      form_type: this.formType.selectedValue.value,
      organizer: this.organizer.selectedValue.value,
      region: this.region.selectedValue.value,
      city: this.city.selectedValue.value,
      offset: 0,
      amount_of_floors_from: this.amountFloors.valueMin.value,
      amount_of_floors_to: this.amountFloors.valueMax.value,
      floor_from: this.floor.valueMin.value,
      floor_to: this.floor.valueMax.value,
      floor_options: _floor_options.length > 0 ? JSON.stringify(_floor_options) : null,
      geography: this.geography.selectedValue.value,
      sections: this.sections.selectedValue.value,
      cadastral_number: this.cadastralNumber.value,
      source: this.areas.selectedValue.value,
      show_unknown_amount_of_floors: this.showUnknownAmountOfFloors.value === true ? true : null,
    };

    this.setParams(params);
  };

  clearSideFilters = (offSetFilters: boolean = false): void => {
    this.auctionType.selectedValue.change([]);
    this.area.reset();
    this.price.reset();
    this.status.selectedValue.change([]);
    this.formType.selectedValue.change([]);
    this.organizer.selectedValue.change([]);
    this.objectType.selectedValue.change([]);
    this.region.selectedValue.change([]);
    this.city.selectedValue.change([]);
    this.floor.reset();
    this.amountFloors.reset();
    this.geography.selectedValue.change([]);
    this.sections.selectedValue.change([]);
    this.areas.selectedValue.change([]);
    this.cadastralNumber.change('');
    this.floorOptions.change(FLOOR_OPTIONS_DEFAULT);
    this.showUnknownAmountOfFloors.change(null);
    if (!offSetFilters) {
      this.offsetModel.change(0);
      this.setSideFilters();
    }
  };

  setAllFilters = (): void => {
    this.setSearchValue();
    this.setSideFilters();
  };

  async init(): Promise<BaseResponse> {
    if (this.loadingFiltersStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.loadingFiltersStage.loading();

    const responses = await Promise.all([
      this.fetchCatalogFiltersOptions(),
      //this.fetchRegions(),
      //this.fetchCities(),
      this.organizerFilter.fetchOptions(),
    ]);

    if (responses.every((response) => !response.isError)) {
      this.loadingFiltersStage.success();

      return {
        isError: false,
      };
    }

    this.loadingFiltersStage.error();

    return {
      isError: true,
    };
  }

  private fetchCatalogFiltersOptions = async (): Promise<BaseResponse> => {
    const response = await super.fetchFiltersOptions({
      aggregated_type: this.isAllAggregationType ? null : this.params.aggregated_type,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.setFilterOptions(response.data);

    return { isError: false };
  };

  async fetchRegions(): Promise<BaseResponse> {
    if (this.loadingRegionsStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.loadingRegionsStage.loading();

    const response = await apiCustom<ResponseListApi<{ id: number; name: string }>>({
      method: 'GET',
      url: apiUrls.lotListRegionFilters + '?limit=1000',
    });

    if (response.isError) {
      this.loadingRegionsStage.error();
    } else {
      this.region.setOptions(response.data.results.map((item) => ({ id: item.id, title: item.name })));
      this.loadingRegionsStage.success();
    }

    return response;
  }

  async fetchCities(): Promise<BaseResponse> {
    let request = (this._abortController = new AbortController());
    if (this.loadingCitiesStage.isLoading && this._abortController) {
      this._abortController.abort();
    }

    this.loadingCitiesStage.loading();

    const response = await this.apiRequest<ResponseListApi<{ id: number; name: string }>>({
      method: 'GET',
      url: apiUrls.lotListCityFilters + '?limit=10000',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.loadingCitiesStage.error();
    } else {
      this.city.setOptions(response.data.results.map((item) => ({ id: item.id, title: item.name })));
      this.loadingCitiesStage.success();
    }

    return response;
  }

  protected setFilterOptions(filters: LotFiltersResponse['filters']): void {
    const aggregatedTypeOptions = [
      DEFAULT_AGGREGATED_TYPE,
      ...filters.aggregated_type.map((option) => ({
        id: option.slug || option.title,
        title: option.title,
      })),
    ];
    const sectionsOptions: FilterOption<string>[] = filters.sections.map((option) => ({
      id: option.slug || option.title,
      title: option.title,
    }));

    const areasOptions: FilterOption<string>[] = filters.source.map((option) => ({
      id: option.slug || option.title,
      title: option.title in lotSourceConfig ? lotSourceConfig[option.title as LotSource].title : option.title,
    }));

    const parseGeographyOptions = (filtersArgs: IFilterGeography[], parentID?: number) => {
      let items: FilterOption[] = [];
      filtersArgs.forEach((filterItem) => {
        const option: FilterOption = {
          id: filterItem.id,
          title: filterItem.name,
          type: filterItem.type,
          options: {
            parentID: parentID || -1,
            chidrenIds: [],
          },
        };
        if (filterItem.children && filterItem.children.length > 0) {
          const result: number[] = [];
          const getIds = (items: IFilterGeography[]) => {
            items.forEach((item) => {
              if (item.children && item.children.length > 0) {
                result.push(item.id);
                getIds(item.children);
              } else {
                result.push(item.id);
              }
            });
          };
          getIds(filterItem.children);
          option['options']!['chidrenIds'] = result;
          const itemChildren = parseGeographyOptions(filterItem.children, filterItem.id);
          option.children = itemChildren;
        } else {
          option.children = [];
        }

        items.push(option);
      });

      return items;
    };

    const geographyOptions = parseGeographyOptions(filters.geography);

    super.setFilterOptions(filters);
    this.sections.setOptions(sectionsOptions, undefined, false);
    this.areas.setOptions(areasOptions);
    this.aggregatedType.setOptions(aggregatedTypeOptions, this.params.aggregated_type ?? DEFAULT_AGGREGATED_TYPE.id);
    this.area.from.change(filters.square.from);
    this.area.to.change(filters.square.to);
    this.price.from.change(filters.price.from);
    this.price.to.change(filters.price.to);
    this.floor.to.change(filters.floor.to);
    this.floor.from.change(filters.floor.from);
    this.amountFloors.to.change(filters.amount_of_floors.to);
    this.amountFloors.from.change(filters.amount_of_floors.from);
    this.geography.setOptions(geographyOptions, undefined, false);
  }

  static normalizeQueryParams(params: URLSearchParams): LotCatalogQueryParams {
    const paramsObj = qs.parse(params.toString(), { parseNumbers: true }) as unknown as Partial<LotCatalogQueryParams>;

    const normalizedCommonParams = LotListCommonFiltersModel.normalizeCommonQueryParams(paramsObj);

    return {
      ...normalizedCommonParams,
      search: paramsObj.search ?? '',
      area_from: paramsObj.area_from ?? null,
      area_to: paramsObj.area_to ?? null,
      price_from: paramsObj.price_from ?? null,
      price_to: paramsObj.price_to ?? null,
      organizer: paramsObj.organizer ? convertToArray(paramsObj.organizer) : [],
      aggregated_type: paramsObj.aggregated_type ?? DEFAULT_AGGREGATED_TYPE.id,
      region: paramsObj.region ? convertToArray(paramsObj.region) : [],
      city: paramsObj.city ? convertToArray(paramsObj.city) : [],
      offset: paramsObj.offset ? paramsObj.offset : 0,
      floor_to: paramsObj.floor_to ?? null,
      floor_from: paramsObj.floor_from ?? null,
      amount_of_floors_to: paramsObj.amount_of_floors_to ?? null,
      amount_of_floors_from: paramsObj.amount_of_floors_from ?? null,
      floor_options: paramsObj.floor_options ?? null,
      geography: paramsObj.geography ? convertToArray(paramsObj.geography) : [],
      cadastral_number: paramsObj.cadastral_number ?? '',
      sections: paramsObj.sections ? convertToArray(paramsObj.sections) : [],
      source: paramsObj.source ? convertToArray(paramsObj.source) : [],
      show_unknown_amount_of_floors:
        (paramsObj.show_unknown_amount_of_floors as unknown as string) === 'true' ? true : false,
    };
  }
}
