import { Button, Input, Modal, Space, Typography } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { LINKS } from 'config/links';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { useRootStore } from 'stores/RootStore';

import s from './EmailPopup.module.scss';

const EmailPopup: React.FC = () => {
  const { userStore } = useRootStore();

  const { userEditModel, emailPopupController, editUser, editStage } = userStore;

  React.useEffect(() => {
    return () => {
      userEditModel.reset();
    };
  }, []);

  const email = userEditModel.email;

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      email.change(event.target.value);
    },
    [email],
  );

  useKeyboardEvent('Enter', editUser, emailPopupController.value);

  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={s.popup}
      open={emailPopupController.value}
      footer={null}
      closeIcon={null}
      onCancel={userStore.closeEmailPopup}
      width={448}
      centered
    >
      <Space direction="vertical" size={24}>
        <Typography.Title className={cn(s.popup__title, s.popup__text)} level={2}>
          Подписаться на уведомления
        </Typography.Title>
        <div>
          <Typography.Text className={s['popup__input-title']} type="secondary">
            Введите свою почту для отправки вам уведомлений
          </Typography.Text>
          <Space className={s['popup__input-wrapper']} direction="vertical" size={0}>
            <Input
              className={s['popup__input']}
              onChange={handleEmailChange}
              placeholder="Email"
              value={email.value}
              status={email.error ? 'error' : undefined}
            />
            <Typography.Text type="danger">{email.error}</Typography.Text>
          </Space>
          {/* TODO заменить кнопку */}
          <Button
            className={s['popup__submit-button']}
            type="primary"
            onClick={editUser}
            loading={editStage.isLoading}
            disabled={!email.value}
          >
            Подписаться
          </Button>
        </div>
        <Typography.Text type="secondary" className={s['popup__disclaimer']}>
          Нажимая на кнопку «Подписаться» вы соглашаетесь с{' '}
          <Typography.Link className={s['popup__link']} href={LINKS.politics} target="_blank">
            политикой обработки персональных данных
          </Typography.Link>
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default observer(EmailPopup);
