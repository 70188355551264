import * as React from 'react';
import { Outlet } from 'react-router-dom';

import AuthPopup from 'components/AuthPopup';
import EmailPopup from 'components/EmailPopup';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Onboarding } from 'components/Onboarding';
import { useScrollToTop } from 'hooks/useScrollTop';
import { WidthProvider } from 'hooks/useWidth';
import { RootStoreContextProvider } from 'stores/RootStore';

import s from './App.module.scss';
import { BugReport } from 'components/BugReport/BugReport';
import { CookiesContextProvider } from 'components/Cookies';

const App: React.FC = () => {
  useScrollToTop();

  return (
    <RootStoreContextProvider>
      <CookiesContextProvider>
        <WidthProvider>
          <div className={s.app__container}>
            <Header />
            <Outlet />
            <Footer />
          </div>
          <BugReport />
          <AuthPopup />
          <EmailPopup />
          <Onboarding />
        </WidthProvider>
      </CookiesContextProvider>
    </RootStoreContextProvider>
  );
};

export default App;
