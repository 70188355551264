import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { routerUrls } from 'config/routes';
import { useKeyboardEvent } from 'hooks/useKeyboardEvent';
import { useLocalStore } from 'hooks/useLocalStore';
import { useRootStore } from 'stores/RootStore';

import { LotListStore } from './LotListStore';

type LotListStoreContextValue = LotListStore;

const LotListStoreContext = React.createContext<LotListStoreContextValue | null>(null);

export const LotListStoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [queryParams] = useSearchParams();
  const rootStore = useRootStore();

  const store = useLocalStore(() => new LotListStore({ queryParams, rootStore }));

  React.useEffect(() => {
    const effect = async () => {
      //store.filters.setPage(1);
      const lotsResponse = await store.fetchLotItems({ replace: true, offset: 10 });

      if (!lotsResponse.isError && lotsResponse.data && lotsResponse.data.items && lotsResponse.data.items[0]) {
        // Для онбординга необходимо сохранить ссылку на страницу с первым лотом
        rootStore.onboardingStore.setLotPageUrl(routerUrls.lotDetail.create(lotsResponse.data.items[0].id));
      }
    };

    effect();
  }, []);

  const { isOnboardingPassedModel, isOnboardingStartedModel, startOnboarding, resumeOnboardingOnLotsListPage } =
    rootStore.onboardingStore;

  React.useEffect(() => {
    if (isOnboardingPassedModel.value) {
      return;
    }

    if (!isOnboardingStartedModel.value) {
      startOnboarding();
    } else {
      resumeOnboardingOnLotsListPage();
    }
  }, []);

  useKeyboardEvent('Enter', store.filters.setAllFilters);

  return <LotListStoreContext.Provider value={store}>{children}</LotListStoreContext.Provider>;
};

export const useLotListStore = () => {
  const context = React.useContext(LotListStoreContext);

  if (!context) {
    throw new Error('LotListStoreContext was not provided');
  }

  return context;
};
