import { apiUrls } from 'config/apiUrls';
import { ResponseListApi } from 'entities';
import { IAdditionalServiceData, IServicesData, ServicesSlugStatic, ServiceStatus } from 'entities/services/services';
import { IReactionDisposer, action, computed, makeObservable, reaction } from 'mobx';
import { ListModel } from 'models/ListModel';
import { LoadingStageModel } from 'models/LoadingStageModel';
import { AdditionaServiceModel, ServiceModel } from 'models/ServiceModel';
import { ValueModel } from 'models/ValueModel';
import { LocalStore } from 'stores/LocalStore';
import { ServicesStore } from 'stores/ServicesStore';
import { BaseResponse } from 'types/meta';
import { apiCustom } from 'utils/api';

export class ProfileServiceModel extends LocalStore {
  readonly list: ListModel<ServiceModel, number> = new ListModel<ServiceModel, number>();
  readonly offset: ValueModel<number> = new ValueModel(0);
  readonly search: ValueModel<string> = new ValueModel('');
  readonly serviceId: ValueModel<number> = new ValueModel(0);
  readonly sortStatus: ValueModel<{ [key in ServiceStatus]: boolean }> = new ValueModel<{
    [key in ServiceStatus]: boolean;
  }>({
    received: false,
    completed: false,
    in_progress: false,
  });
  readonly orderingDate: ValueModel<'-request_date' | 'request_date' | null> = new ValueModel<
    '-request_date' | 'request_date' | null
  >(null);
  private api = apiCustom;

  constructor() {
    super();
    makeObservable<this>(this, {
      isListInitialLoading: computed,
      currentTotalItems: computed,
      servitesList: computed,
      isLoading: computed,
      isError: computed,
    });

    this.addReactions([
      reaction(
        () => this.offset.value,
        (offset: number) => {
          this.fetchServicesItems({
            replace: true,
            offset,
            serviceId: this.serviceId.value,
            search: this.search.value,
            status: this.sortStatus.value,
          });
        },
      ),
      reaction(
        () => this.search.value,
        (search: string) => {
          this.fetchServicesItems({
            replace: true,
            offset: 0,
            serviceId: this.serviceId.value,
            search,
          });
        },
      ),
      reaction(
        () => this.sortStatus.value,
        (status: { [key in ServiceStatus]: boolean }) => {
          this.fetchServicesItems({
            replace: true,
            offset: 0,
            serviceId: this.serviceId.value,
            search: this.search.value,
            status,
          });
        },
      ),
      reaction(
        () => this.orderingDate.value,
        (ordering: '-request_date' | 'request_date' | null) => {
          this.fetchServicesItems({
            replace: true,
            offset: 0,
            serviceId: this.serviceId.value,
            search: this.search.value,
            status: this.sortStatus.value,
            ordering: ordering,
          });
        },
      ),
    ]);
  }

  get isListInitialLoading(): boolean {
    return this.list.isInitialLoading.value;
  }

  get isLoading(): boolean {
    return this.list.loadingStage.isLoading;
  }

  get isError(): boolean {
    return this.list.loadingStage.isError;
  }

  get currentTotalItems(): number {
    return this.list.total.value ?? 0;
  }

  get servitesList() {
    return this.list.items;
  }

  fetchServicesItems = async ({
    replace = true,
    offset = 0,
    serviceId = 0,
    search,
    ordering,
    status,
    limit = 10,
  }: {
    replace: boolean;
    offset?: number;
    serviceId?: number;
    search?: string;
    ordering?: '-request_date' | 'request_date' | null;
    status?: { [key in ServiceStatus]: boolean };
    limit?: number;
  }): Promise<BaseResponse<ListModel<ServiceModel, number>>> => {
    if (replace) {
      this.list.setIsAllLoaded(false);
      this.list.isReplaceLoading.change(true);
    }

    const q = search !== '' && search ? '&search=' + search : '';

    let filterStatus = '';

    if (status) {
      Object.keys(status).forEach((key) => {
        if (status[key as ServiceStatus] === true) {
          filterStatus += '&status=' + key;
        }
      });
    }

    this.list.loadingStage.loading();
    const response = await this.api<ResponseListApi<ServiceModel>>({
      url: `${apiUrls.additionalServicesRequests}?limit=${limit}&offset=${offset}&service=${serviceId}${q}${filterStatus}${ordering ? '&ordering=' + ordering : ''}`,
      method: 'GET',
    });

    if (response.isError) {
      this.list.loadingStage.error();
      return { isError: true };
    }

    this.list.fillByRawData<IServicesData>(
      response.data.results,
      (raw) => {
        const model = ServiceModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );
    this.list.isInitialLoading.change(false);
    this.list.isReplaceLoading.change(false);
    this.list.total.change(response.data.count);
    this.list.setIsAllLoaded(true);
    this.list.loadingStage.success();

    return { isError: false, data: this.list };
  };
}
export class ProfileServicesStore extends LocalStore {
  readonly servicesEscort: ProfileServiceModel = new ProfileServiceModel();
  readonly servicesOnline: ProfileServiceModel = new ProfileServiceModel();
  readonly servicesLegal: ProfileServiceModel = new ProfileServiceModel();
  readonly list: ListModel<AdditionaServiceModel, number> = new ListModel<AdditionaServiceModel, number>();
  readonly listServicesLotIds: ValueModel<{ lotId: number | null; serviceId: number; slug: string }[]> = new ValueModel<
    { lotId: number | null; serviceId: number; slug: string }[]
  >([]);

  private readonly disposers: IReactionDisposer[] = [];
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly additionaServiceBySlugloadingStage: LoadingStageModel = new LoadingStageModel();
  readonly tariffStore: ServicesStore = new ServicesStore();
  private api = apiCustom;
  private abortController?: AbortController;

  constructor() {
    super();

    makeObservable<this>(this, {
      checkLotInServices: action.bound,
      addLotId: action.bound,
      isLoading: computed,
      listServicesItemLotIds: computed,
      isLodingService: computed,
    });
  }

  get isLoading(): boolean {
    return this.loadingStage.isLoading;
  }

  get listServicesItemLotIds(): { lotId: number | null; serviceId: number; slug: string }[] {
    return this.listServicesLotIds.value;
  }

  get isLodingService(): boolean {
    return (
      this.loadingStage.isLoading ||
      this.list.loadingStage.isLoading ||
      this.servicesEscort.isLoading ||
      this.servicesOnline.isLoading ||
      this.servicesLegal.isLoading
    );
  }

  addLotId = (value: { lotId: number | null; serviceId: number; slug: string }) => {
    this.listServicesLotIds.change([...this.listServicesLotIds.value, value]);
  };

  checkLotInServices = (lotId: number, serviceSlug: string): boolean => {
    return !!this.listServicesLotIds.value.find((item) => item.lotId === lotId && item.slug === serviceSlug);
  };

  reset = () => {
    this.list.reset();
    this.servicesEscort.list.reset();
    this.servicesOnline.list.reset();
    this.servicesLegal.list.reset();
    this.listServicesLotIds.reset();
  };

  init = async (limit = 200, reset = true) => {
    this.loadingStage.loading();
    await this.fetchAdditionaServices({ replace: true });

    if (this.list.items.length > 0) {
      this.servicesEscort.serviceId.change(this.list.items[0].id);
      this.servicesOnline.serviceId.change(this.list.items[1].id);
      this.servicesLegal.serviceId.change(this.list.items[2].id);

      Promise.all([
        this.servicesEscort.fetchServicesItems({
          replace: true,
          offset: 0,
          limit: limit,
          serviceId: this.list.items[0].id,
        }),
        this.servicesOnline.fetchServicesItems({
          replace: true,
          offset: 0,
          limit: limit,
          serviceId: this.list.items[1].id,
        }),
        this.servicesLegal.fetchServicesItems({
          replace: true,
          offset: 0,
          limit: limit,
          serviceId: this.list.items[2].id,
        }),
      ])
        .then((res) => {
          const serviceLots = [
            this.servicesEscort.list.items,
            this.servicesOnline.list.items,
            this.servicesLegal.list.items,
          ]
            .flat()
            .map((item) => ({ lotId: item.lot, serviceId: item.service, slug: item.slug }));
          this.listServicesLotIds.change(serviceLots);
          if (reset) {
            this.servicesEscort.list.reset();
            this.servicesOnline.list.reset();
            this.servicesLegal.list.reset();
          }
          this.loadingStage.success();
        })
        .catch(() => {
          this.loadingStage.error();
        });
    }
  };

  fetchAdditionaServiceBySlug = async (slug: string): Promise<IAdditionalServiceData> => {
    try {
      this.additionaServiceBySlugloadingStage.loading();

      const response = await this.api<IAdditionalServiceData>({
        url: `${apiUrls.additionalServices}/${slug}/`,
        method: 'GET',
      });

      if (response.isError) {
        this.additionaServiceBySlugloadingStage.error();
        throw new Error('Resonse fetchAdditionaServiceBySlug error');
      }
      this.additionaServiceBySlugloadingStage.success();
      return response.data;
    } catch (e) {
      this.additionaServiceBySlugloadingStage.error();
      return Promise.reject('Resonse error');
    }
  };

  fetchAdditionaServices = async ({
    replace = true,
  }: {
    replace: boolean;
  }): Promise<BaseResponse<ListModel<AdditionaServiceModel, number>>> => {
    if (replace) {
      this.list.setIsAllLoaded(false);
      this.list.isReplaceLoading.change(true);
    }

    let request = (this.abortController = new AbortController());

    if (this.list.loadingStage.isLoading && this.abortController) {
      this.abortController.abort();
    }
    this.list.loadingStage.loading();
    const response = await this.api<ResponseListApi<IAdditionalServiceData>>({
      url: `${apiUrls.additionalServices}?limit=200&offset=${0}`,
      method: 'GET',
      config: {
        signal: request.signal,
      },
    });

    if (response.isError) {
      this.list.loadingStage.error();

      return { isError: true };
    }
    //TODO: фильтруем и сортирум, т.к. пока у нас может быть 3 статических услуги
    const result: IAdditionalServiceData[] = [];
    let stopCount = 0;

    for (let i = 0; i < response.data.results.length; i++) {
      if (stopCount === 3) {
        break;
      }

      if (response.data.results[i].slug === ServicesSlugStatic.online) {
        result[1] = response.data.results[i];
        stopCount++;
      } else if (ServicesSlugStatic.audit_legal === response.data.results[i].slug) {
        result[2] = response.data.results[i];
        stopCount++;
      } else if (response.data.results[i].slug === ServicesSlugStatic.escort) {
        result[0] = response.data.results[i];
        stopCount++;
      }
    }
    this.list.fillByRawData<IAdditionalServiceData>(
      result,
      (raw) => {
        const model = AdditionaServiceModel.fromJson(raw);
        return {
          entity: model,
          key: model.id,
        };
      },
      replace,
    );
    this.list.isInitialLoading.change(false);
    this.list.isReplaceLoading.change(false);
    this.list.total.change(response.data.count);
    this.list.setIsAllLoaded(true);
    this.list.loadingStage.success();

    return { isError: false, data: this.list };
  };

  destroy(): void {
    super.destroy();
    this.disposers.forEach((disposer) => disposer());
  }
}
