import * as React from 'react';

import { ButtonIcon } from 'components/ButtonIcon';
import { Tooltip } from 'components/Tooltip';

import { activeButtonConfigs } from '../configs';
import { MiniSubscribeButtonProps } from '../types';
import cn from 'classnames';
import s from './ButtonIconAction.module.scss';

const ButtonIconAction: React.FC<MiniSubscribeButtonProps> = ({
  className,
  onClick,
  isActive,
  withoutTooltip = false,
  id,
  icons = activeButtonConfigs,
}) => {
  const tooltipTitle = icons[isActive ? 'active' : 'unactive'].tooltipText;
  const Icon = icons[isActive ? 'active' : 'unactive'].icon;

  const button = (
    <ButtonIcon
      Icon={Icon}
      id={id}
      className={cn('', s['subscribe-button'], isActive ? s['subscribe-button-active'] : '', className)}
      onClick={onClick}
      aria-label={tooltipTitle}
    />
  );

  if (withoutTooltip) {
    return button;
  }

  return (
    <Tooltip placement="topRight" title={tooltipTitle} trigger={['hover']}>
      {button}
    </Tooltip>
  );
};

export default React.memo(ButtonIconAction);
