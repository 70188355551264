import cn from 'classnames';
import * as React from 'react';

import s from './ListControlsBar.module.scss';
import { ObjectTypeTabbar } from 'pages/LotsListPage/ObjectTypeTabbar';
import { TotalItemsCount } from 'pages/LotsListPage/TotalItemsCount';

type ListControlsBarProps = {
  onFiltersClick: VoidFunction;
  sortComponent: React.ReactNode;
  className?: string;
  isListEmpty?: boolean;
  isProfile?: boolean;
};

const ListControlsBar = ({
  sortComponent,
  className,
  isListEmpty,
}: ListControlsBarProps): React.ReactElement<ListControlsBarProps> | null => {
  return (
    <div className={cn(s['list-controls-bar'], className)}>
      <ObjectTypeTabbar />
      {!isListEmpty && (
        <>
          <TotalItemsCount />
          {sortComponent}
        </>
      )}
    </div>
  );
};

export default ListControlsBar;
