import { Checkbox, Tooltip } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { FilterOption } from 'entities/lotFilters';
import { useElementOverflow } from 'hooks/useElementOverflow';

import s from './MultiSelectItem.module.scss';
import { observer } from 'mobx-react-lite';
import useToggleState from 'hooks/useToggleState';
import { CaretRightOutlined } from '@ant-design/icons';

type MultiSelectItemProps<T = number> = {
  option: FilterOption<T>;
  onChange: (id: T, data?: FilterOption<T>) => void;
  checked: boolean;
  className?: string;
  dropdownMulti?: boolean;
  selected?: T[];
};

const ChildrenCollspace = observer(({ children, header }: { children: React.ReactNode; header: React.ReactNode }) => {
  const { opened: isFiltersShown, toggle } = useToggleState();
  const filtersDivRef = React.useRef<HTMLDivElement | null>(null);
  const [isFirstRender, setIsFirstRender] = React.useState(false);
  const isInit = React.useRef(false);

  React.useEffect(() => {
    isInit.current = true;
  }, []);

  React.useEffect(() => {
    if (filtersDivRef && filtersDivRef.current) {
      if (isFiltersShown && !isFirstRender) {
        setIsFirstRender(true);
      }
      const isOpen = filtersDivRef.current?.getAttribute('aria-hidden') || 'true';
      if (isOpen === 'false') {
        filtersDivRef.current?.setAttribute('aria-hidden', 'true');
      } else {
        filtersDivRef.current?.setAttribute('aria-hidden', 'false');
      }
    }
  }, [isFiltersShown]);

  return (
    <div className={s.childrenCollspace}>
      <div className={s.childrenCollspaceActions}>
        <div role="button" onClick={toggle} className={cn(s.childrenCollspaceActionsIcon, isFiltersShown && s.open)}>
          <CaretRightOutlined />
        </div>
        {header}
      </div>
      <div className={s.childrenCollspaceContent} aria-hidden={isFiltersShown} ref={filtersDivRef}>
        {isFirstRender && <div>{children}</div>}
      </div>
    </div>
  );
});

const ChildrenItem = observer(
  <T,>({
    option,
    onChange,
    checked,
    className,
    indeterminate,
  }: {
    option: FilterOption<T>;
    onChange: (id: T, data?: FilterOption<T>) => void;
    checked: boolean;
    className?: string;
    indeterminate?: boolean;
  }) => {
    return (
      <Checkbox
        className={cn(s['multi-select-item'], className)}
        checked={checked}
        onChange={() => onChange(option.id, { id: option } as any)}
        indeterminate={indeterminate}
      >
        <div className={s['multi-select-item__title']}>{option.title}</div>
      </Checkbox>
    );
  },
);

const MultiSelectItem = <T,>({
  option,
  onChange,
  checked,
  className,
  dropdownMulti = false,
  selected = [],
}: MultiSelectItemProps<T>): React.ReactElement<MultiSelectItemProps<T>> => {
  const handleChange = React.useCallback(() => {
    onChange(option.id, option);
  }, [onChange, option]);

  const [titleRef, isTooltipShown] = useElementOverflow<HTMLDivElement>();

  const contains = React.useCallback(
    (where: T[], what: T[], data?: FilterOption<T>) => {
      let count = 0;
      if (what.length === 0) {
        return false;
      }
      for (var i = 0; i < what.length; i++) {
        if (where.indexOf(what[i]) == -1) {
          return false;
        }
        count++;
      }

      if (data && count === where.length) {
        onChange(data.id, data);
      }

      return true;
    },
    [option, selected, checked, dropdownMulti],
  );

  const items = () => {
    if (dropdownMulti && option.children && option.children.length > 0) {
      const tree = (node: FilterOption<T>, lvl: number) => {
        const result: React.ReactNode[] = [];
        if (node.children && node.children.length > 0) {
          node.children.forEach((item, index) => {
            if (item.children && item.children.length === 0) {
              const indeterminate = selected.includes(item.id)
                ? false
                : contains((item.options?.chidrenIds as T[]) || [], selected, item);
              result.push(
                <div key={item.id as any} data-lvl={lvl} style={{ paddingLeft: '20px' }}>
                  <ChildrenItem<T>
                    option={item}
                    onChange={onChange}
                    checked={selected.includes(item.id as T)}
                    indeterminate={indeterminate}
                  />
                </div>,
              );
            } else {
              const treeNode = item.children && item.children.length === 0 ? [] : tree(item, index + lvl);

              const indeterminate = selected.includes(item.id as T)
                ? false
                : contains((item.options?.chidrenIds as T[]) || [], selected, item as FilterOption<T>);

              result.push(
                <div key={item.id as any} data-lvl={lvl} style={{ paddingLeft: '10px' }} data-len={treeNode.length}>
                  <ChildrenCollspace
                    header={
                      <ChildrenItem<T>
                        option={item as FilterOption<T>}
                        onChange={onChange}
                        checked={selected.includes(item.id as T)}
                        indeterminate={indeterminate}
                      />
                    }
                  >
                    <div>{treeNode}</div>
                  </ChildrenCollspace>
                </div>,
              );
            }
          });
        }
        return result;
      };

      return tree(option as FilterOption<T>, 0);
    }

    return [];
  };

  return !dropdownMulti || option?.children === undefined || option.children.length === 0 ? (
    <Checkbox className={cn(s['multi-select-item'], className)} checked={checked} onChange={handleChange}>
      <Tooltip title={isTooltipShown ? option.title : null} mouseEnterDelay={0.5}>
        <div className={s['multi-select-item__title']} ref={titleRef}>
          {option.title}
        </div>
      </Tooltip>
    </Checkbox>
  ) : (
    <div data-test={selected.includes(option.id)}>
      {option.children && option.children.length > 0 ? (
        <ChildrenCollspace
          header={
            <Checkbox
              className={cn(s['multi-select-item'], className)}
              indeterminate={
                selected.includes(option.id)
                  ? false
                  : contains((option.options?.chidrenIds as T[]) || [], selected, option)
              }
              checked={selected.includes(option.id)}
              onChange={handleChange}
            >
              <div className={s['multi-select-item__title']} ref={titleRef}>
                {option.title}
              </div>
            </Checkbox>
          }
        >
          <div style={{ paddingLeft: '10px' }}>{items()}</div>
        </ChildrenCollspace>
      ) : (
        <Checkbox
          className={cn(s['multi-select-item'], className)}
          checked={selected.includes(option.id)}
          onChange={handleChange}
        >
          <div className={s['multi-select-item__title']} ref={titleRef}>
            {option.title}
          </div>
        </Checkbox>
      )}
    </div>
  );
};

export default observer(MultiSelectItem);
