import { Button, Space } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRootStore } from 'stores/RootStore';

import s from './SuccessStep.module.scss';

const SuccessStep = () => {
  const { authStore, robotStore, notificationStore, profileService, profileNotificationSubscribe, userStore } =
    useRootStore();
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([
      robotStore.fetchRobotItems({ replace: true }),
      notificationStore.fetchNotificationItems({ replace: true }),
      notificationStore.getNotificationsNewCount(),
      profileService.init(),
      profileNotificationSubscribe.fetchNotificationSubscribeItems({ replace: true }),
      userStore.getUsersTariffs({ replace: true }),
    ]);
  }, []);

  return (
    <Space size={16} className={s.success__step} direction="vertical">
      <p>
        {authStore.isFirstVisit.value ? (
          <>
            Вы удачно зарегистрировались в КупиЛот. <br /> Вам открылись новый возможности в личном кабинете.
          </>
        ) : (
          'Вы удачно вошли в КупиЛот'
        )}
      </p>
      <Button className={s.success__step__button} type="primary" onClick={() => authStore.popupController.close()}>
        Остаться на текущей странице
      </Button>
      <Button
        className={s.success__step__button}
        type="default"
        onClick={() => {
          authStore.popupController.close();
          navigate('/profile/favorites');
        }}
      >
        Перейти в личный кабинет
      </Button>
    </Space>
  );
};

export default SuccessStep;
