import { IBannerData } from 'entities/banner';
import { apiUrls } from 'config/apiUrls';

export class BannerModel implements IBannerData {
  readonly id;
  readonly image;
  readonly header;
  readonly text;
  readonly link;
  readonly ordering;

  constructor(params: IBannerData) {
    this.id = params.id;
    this.image = params.image;
    this.header = params.header;
    this.text = params.text;
    this.link = params.link;
    this.ordering = params.ordering;
  }

  static fromJson(raw: IBannerData): IBannerData {
    return {
      id: raw.id,
      image: raw.image ?? null,
      header: raw.header,
      text: raw.text,
      link: raw.link,
      ordering: raw.ordering,
    };
  }
}
