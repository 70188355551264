import cn from 'classnames';
import React, { ReactNode } from 'react';
import s from './CardStep.module.scss';
import { Typography } from 'antd';

const Card = ({
  className,
  item,
  vertical = false,
}: {
  className?: string;
  item: { step: number; title: string | ReactNode; description?: string; image?: string };
  vertical?: boolean;
}) => {
  return (
    <div className={cn(s.card, className, vertical && s.vertical)}>
      <div className={cn(s.content, vertical && s.content__vertical)}>
        <p className={cn(s.step, !item.description && s.small)}>{item.step}</p>
        <div className={s.body}>
          <Typography.Title className={cn(s.title, !item.description && s.small)} level={3}>
            {item.title}
          </Typography.Title>
          {item.description && <p className={s.desc}>{item.description}</p>}
        </div>
      </div>
      <img src={item.image} className={s.img} loading="lazy" alt="" />
    </div>
  );
};

export const CardStep = React.memo(Card);
