import { Button } from 'antd';
import cn from 'classnames';
import React from 'react';

import s from './PremiumPriceCard.module.scss';

interface PremiumPriceCardProps {
  className?: string;
  title: string;
  description: string;
  actionText?: string;
  action?: () => void;
}

const PremiumPriceCard = ({
  className,
  title,
  description,
  actionText,
  action,
}: PremiumPriceCardProps): React.ReactElement<PremiumPriceCardProps> => {
  return (
    <div className={cn(s.price__card, className)}>
      <div className={s.price__card__content}>
        <h3 className={s.price__card__content__title}>{title}</h3>
        <p className={s.price__card__content__description}>{description}</p>
      </div>
      {action && (
        <Button className={s.price__card__button} onClick={action}>
          {actionText}
        </Button>
      )}
    </div>
  );
};

export default React.memo(PremiumPriceCard);
