import { action, makeObservable, observable } from 'mobx';

import { ValueModel } from 'models/ValueModel';

import { IThemeStore } from './types';

export class ThemeStore implements IThemeStore {
  readonly theme: ValueModel<string> = new ValueModel<string>('light');
  constructor(theme: string = 'light') {
    makeObservable(this, {
      theme: observable,
      setTheme: action,
    });

    this.theme.change(theme);
  }

  setTheme(theme: string): void {
    this.theme.change(theme);
  }

  destroy(): void {}
}
