import React from 'react';
import { Pagination } from 'swiper/modules';

import Slider from 'components/Slider';

import s from './PremiumSlider.module.scss';

interface PremiumSliderProps {
  children: JSX.Element | JSX.Element[];
  title?: string;
}

const PremiumSlider = ({ children, title }: PremiumSliderProps) => {
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className} ${s.bullet}" > </span>`;
    },
  };
  return (
    <div className={s.advantages__slider}>
      {title && <h2 className={s.advantages__slider__title}>{title}</h2>}
      <Slider
        className={s.swiper}
        spaceBetween={27}
        slidesPerView={'auto'}
        slideClass={s.slide}
        pagination={pagination}
        modules={[Pagination]}
        slides={Array.isArray(children) ? children : [children]}
        onSlideChange={() => {}}
      />
    </div>
  );
};

export default PremiumSlider;
