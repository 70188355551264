import { debounce } from '@kts-front/utils';
import { computed, makeObservable, action } from 'mobx';

import { FilterOption } from 'entities/lotFilters';

import { ArrayValueModel } from './ArrayValueModel';
import { ValueModel } from './ValueModel';

type ValueItem = FilterOption['id'];
type Value = ValueItem[];
type ValueStringItem = FilterOption<string>['id'];
type ValueString = ValueStringItem[];

export class FilterModelMultiString {
  private readonly optionsRecord: ValueModel<Record<string, FilterOption<string>>>;
  private readonly searchValueFinal: ValueModel<string> = new ValueModel<string>('');
  readonly searchValue: ValueModel<string> = new ValueModel<string>('');
  readonly selectedValue: ArrayValueModel<ValueStringItem>;

  constructor(value: ValueString = [], options: FilterOption<string>[] = []) {
    makeObservable(this, {
      options: computed,
      isEmpty: computed,
      isEmptyOptions: computed,
      selectedOptions: computed,
      selectedOptionsIds: computed,

      setSelected: action,
      setOptions: action,
      addOptionsToEnd: action,
      toggleValue: action,
    });

    this.selectedValue = new ArrayValueModel<ValueStringItem>(value);
    this.optionsRecord = new ValueModel<Record<string, FilterOption<string>>>(
      FilterModelMultiString.normalizeOptions(options),
    );
  }

  get options(): FilterOption<string>[] {
    // TODO временное решение поиска на фронте
    // в будущем засылать значение поиска на бэк и полностью обновлять опции
    if (this.searchValueFinal.value === '') {
      return Object.values(this.optionsRecord.value);
    }

    return Object.values(this.optionsRecord.value).filter((option) =>
      option.title.toLowerCase().includes(this.searchValueFinal.value.toLowerCase()),
    );
  }

  get selectedOptions(): FilterOption<string>[] {
    if (Object.keys(this.optionsRecord.value).length === 0) {
      return [];
    }
    return this.selectedValue.value.map((selectedOptionId) => this.optionsRecord.value[selectedOptionId]);
  }

  get selectedOptionsIds(): string[] {
    if (Object.keys(this.optionsRecord.value).length === 0) {
      return [];
    }
    return this.selectedValue.value.map((selectedOptionId) => this.optionsRecord.value[selectedOptionId].id);
  }

  get isEmpty(): boolean {
    return this.selectedValue.value.length === 0;
  }

  get isEmptyOptions(): boolean {
    return this.options.length === 0;
  }

  setOptions = (options: FilterOption<string>[], initValue?: ValueString, isSanitized: boolean = true): void => {
    this.optionsRecord.change(FilterModelMultiString.normalizeOptions(options));
    if (isSanitized) {
      // из выбранных значений удаляем те, которых нет в новых опциях
      const sanitizedValue = (initValue || this.selectedValue.value).filter((id) => this.optionsRecord.value[id]);
      this.selectedValue.change(sanitizedValue);
    }
  };

  addOptionsToEnd(options: FilterOption<string>[]) {
    this.optionsRecord.change({
      ...this.optionsRecord.value,
      ...FilterModelMultiString.normalizeOptions(options),
    });
  }

  setSelected = (items: null | string[]) => {
    this.selectedValue.change(items ? items : []);
  };

  toggleValue = (id: ValueStringItem): void => {
    if (this.selectedValue.hasItem(id)) {
      this.selectedValue.removeItem(id);
    } else {
      this.selectedValue.addItem(id);
    }
  };

  handleSearch = (search: string): void => {
    this.searchValue.change(search);
    this.handleSearchChange();
  };

  private handleSearchChange = debounce({
    timeout: 500,
    func: (): void => {
      this.searchValueFinal.change(this.searchValue.value);
    },
  });

  static normalizeOptions(options: FilterOption<string>[]): Record<string, FilterOption<string>> {
    if (!options) {
      return {};
    }

    return options.reduce<Record<string, FilterOption<string>>>((acc, option) => {
      acc[option.id] = option;

      return acc;
    }, {});
  }
}

export class FilterModelMulti {
  private readonly optionsRecord: ValueModel<Record<FilterOption['id'], FilterOption>>;
  private readonly searchValueFinal: ValueModel<string> = new ValueModel<string>('');
  readonly searchValue: ValueModel<string> = new ValueModel<string>('');
  readonly selectedValue: ArrayValueModel<ValueItem>;
  private readonly _optionsUnSorted: ValueModel<FilterOption<FilterOption['id']>[]>;

  constructor(value: Value = [], options: FilterOption[] = []) {
    makeObservable(this, {
      options: computed,
      isEmpty: computed,
      isEmptyOptions: computed,
      selectedOptions: computed,
      selectedOptionsIds: computed,
      getAllOptionsIds: computed,
      optionsUnSorted: computed,

      setSelected: action,
      setOptions: action,
      addOptionsToEnd: action,
      getChildrensSelectedValues: action,
      toggleValue: action,
    });

    this._optionsUnSorted = new ValueModel<FilterOption<FilterOption['id']>[]>(options);
    this.selectedValue = new ArrayValueModel<ValueItem>(value);
    this.optionsRecord = new ValueModel<Record<FilterOption['id'], FilterOption>>(
      FilterModelMulti.normalizeOptions(options),
    );
  }
  get optionsUnSorted(): FilterOption[] {
    return this._optionsUnSorted.value;
  }
  get options(): FilterOption[] {
    // TODO временное решение поиска на фронте
    // в будущем засылать значение поиска на бэк и полностью обновлять опции
    if (this.searchValueFinal.value === '') {
      return Object.values(this.optionsRecord.value);
    }

    return Object.values(this.optionsRecord.value).filter((option) =>
      option.title.toLowerCase().includes(this.searchValueFinal.value.toLowerCase()),
    );
  }

  get selectedOptions(): FilterOption[] {
    return this.selectedValue.value.map((selectedOptionId) => this.optionsRecord.value[selectedOptionId]);
  }

  get selectedOptionsIds(): number[] {
    return this.selectedValue.value.map((selectedOptionId) => this.optionsRecord.value[selectedOptionId].id);
  }

  get getAllOptionsIds(): number[] {
    const arrOptions = Object.values(this.optionsRecord.value);
    const result: number[] = [];

    const getIds = (items: FilterOption[]) => {
      items.forEach((item) => {
        if (item.children && item.children.length > 0) {
          result.push(item.id);
          getIds(item.children);
        } else {
          result.push(item.id);
        }
      });
    };

    getIds(arrOptions);

    return result;
  }

  get isEmpty(): boolean {
    return this.selectedValue.value.length === 0;
  }

  get isEmptyOptions(): boolean {
    return this.options.length === 0;
  }

  setOptions = (options: FilterOption[], initValue?: Value, isSanitized: boolean = true): void => {
    this.optionsRecord.change(FilterModelMulti.normalizeOptions(options));
    this._optionsUnSorted.change(options);
    if (isSanitized) {
      // из выбранных значений удаляем те, которых нет в новых опциях
      const sanitizedValue = (initValue || this.selectedValue.value).filter((id) => this.optionsRecord.value[id]);
      this.selectedValue.change(sanitizedValue);
    }
  };

  addOptionsToEnd(options: FilterOption[]) {
    this.optionsRecord.change({
      ...this.optionsRecord.value,
      ...FilterModelMulti.normalizeOptions(options),
    });
  }

  setSelected = (items: null | number[]) => {
    this.selectedValue.change(items ? items : []);
  };

  toggleValue = (id: ValueItem): void => {
    if (this.selectedValue.hasItem(id)) {
      this.selectedValue.removeItem(id);
    } else {
      this.selectedValue.addItem(id);
    }
  };

  handleSearch = (search: string): void => {
    this.searchValue.change(search);
    this.handleSearchChange();
  };

  getChildrensSelectedValues = (id: number) => {
    //@ts-ignore
    function findItem(id: number, arr: FilterOption[]) {
      let value: null | FilterOption = null;
      for (let elem of arr) {
        if (elem['id'] == id) {
          value = elem;
          break;
        } else if (elem['children']) {
          value = findItem(id, elem.children);
          if (value !== null) {
            break;
          }
        }
      }
      return value;
    }
    const item = findItem(id, Object.values(this.optionsRecord.value));
    const result: number[] = [];

    if (item && item.children) {
      const getIds = (items: FilterOption[]) => {
        items.forEach((_item) => {
          if (_item.children && _item.children.length > 0) {
            result.push(_item.id);
            getIds(_item.children);
          } else {
            result.push(_item.id);
          }
        });
      };
      getIds(item.children);
    }

    return result;
  };

  private handleSearchChange = debounce({
    timeout: 500,
    func: (): void => {
      this.searchValueFinal.change(this.searchValue.value);
    },
  });

  static normalizeOptions(options: FilterOption[]): Record<FilterOption['id'], FilterOption> {
    if (!options) {
      return {};
    }
    return options.reduce<Record<FilterOption['id'], FilterOption>>((acc, option) => {
      acc[option.id] = option;

      return acc;
    }, {});
  }
}
