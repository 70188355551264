import { FilterOption, FloorOptions } from 'entities/lotFilters';

export const DEFAULT_AGGREGATED_TYPE: FilterOption<string> = {
  id: 'all',
  title: 'Все типы',
};

export const FLOOR_OPTIONS_DEFAULT = {
  [FloorOptions.NO_FLOOR]: false,
  [FloorOptions.NOT_LAST]: false,
  [FloorOptions.BASEMENT]: false,
  [FloorOptions.FIRST]: false,
  [FloorOptions.NOT_FIRST]: false,
  [FloorOptions.LAST]: false,
};

export const FLOOR_OPTIONS_LABELS: { [key in FloorOptions]: string } = {
  [FloorOptions.NO_FLOOR]: 'Этаж не указан',
  [FloorOptions.NOT_LAST]: 'Не последний',
  [FloorOptions.BASEMENT]: 'Подвал/цоколь',
  [FloorOptions.FIRST]: 'Первый',
  [FloorOptions.NOT_FIRST]: 'Не первый',
  [FloorOptions.LAST]: 'Последний',
};
