import useToggleState from 'hooks/useToggleState';
import React from 'react';
import s from './BugReport.module.scss';
import { InfoCircleFilled } from '@ant-design/icons';
import BugReportModal from 'components/BugReportModal/BugReportModal';

export const BugReport = () => {
  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();
  return (
    <>
      <button className={s.btn} onClick={openSupportModal}>
        <InfoCircleFilled />
      </button>
      <BugReportModal opened={openedSupportModal} close={closeSupportModal} />
    </>
  );
};
