import { Modal, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import s from './FinalErrorModal.module.scss';

type FinalSuccessModalProps = {
  opened: boolean;
  close: () => void;
  className?: string;
};

const FinalErrorModal = ({
  opened,
  close,
  className,
}: FinalSuccessModalProps): React.ReactElement<FinalSuccessModalProps> => {
  return (
    <Modal
      classNames={{ body: s.popup__body }}
      className={cn(s.popup, className)}
      open={opened}
      footer={null}
      onCancel={close}
      width={362}
      centered
    >
      <Typography.Title className={s.popup__title} level={2}>
        Не удалось отправить сообщение
        <br />
        Попробуйте позже
      </Typography.Title>
    </Modal>
  );
};

export default FinalErrorModal;
