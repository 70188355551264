import { INotification, INotificationSubscribe } from 'entities/notification';

export class NotificationSubscribeModel implements INotificationSubscribe {
  readonly id;
  readonly notification_type;
  readonly site_notification;
  readonly telegram_notification;
  readonly email_notification;
  readonly user;
  readonly name;
  readonly description;

  constructor(params: INotificationSubscribe) {
    this.id = params.id;
    this.notification_type = params.notification_type;
    this.site_notification = params.site_notification;
    this.telegram_notification = params.telegram_notification;
    this.email_notification = params.email_notification;
    this.user = params.user;
    this.name = params.name;
    this.description = params.description;
  }

  static fromJson(raw: INotificationSubscribe): INotificationSubscribe {
    return {
      id: raw.id,
      notification_type: raw.notification_type,
      site_notification: raw.site_notification,
      telegram_notification: raw.telegram_notification,
      email_notification: raw.email_notification,
      user: raw.user,
      name: raw.name,
      description: raw.description ?? '',
    };
  }
}

export class NotificationModel implements INotification {
  readonly id;
  readonly type;
  readonly url;
  readonly text;
  readonly created_at;
  readonly is_read;
  readonly user;

  constructor(params: INotification) {
    this.id = params.id;
    this.type = params.type;
    this.url = params.url;
    this.text = params.text;
    this.created_at = params.created_at;
    this.is_read = params.is_read;
    this.user = params.user;
  }

  static fromJson(raw: INotification): INotification {
    return {
      id: raw.id,
      type: raw.type,
      url: raw.url,
      text: raw.text,
      created_at: raw.created_at,
      is_read: raw.is_read,
      user: raw.user,
    };
  }
}
