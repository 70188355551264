export const filtersEmptyObject = <T extends { [key: string | number]: any }>(obj: T) => {
  let result = { ...obj };
  const arr_obj = Object.entries(obj);
  for (let i = 0; i < arr_obj.length; i++) {
    const key = arr_obj[i][0];
    const value = arr_obj[i][1];
    if (value === null || value === undefined) {
      delete result[key];
    } else if (typeof value === 'object' && ('from' in value || 'to' in value)) {
      if (value['from'] === null && value['to'] === null) {
        delete result[key];
      } else {
        if (value['from'] === null) {
          delete result[key].from;
        }
        if (value['to'] === null) {
          delete result[key].to;
        }
      }
    } else if (Array.isArray(value) && value.length === 0) {
      delete result[key];
    }
  }

  return result;
};

export function declOfNum(n: number, titles: string[]) {
  return titles[
    n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ];
}

export function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
