import { Typography } from 'antd';
import React from 'react';
import s from './FaqItem.module.scss';

export const FaqItem = ({
  step,
  title,
  description,
  clasName,
}: {
  step?: number;
  title: string;
  description: string;
  clasName?: string;
}) => {
  return (
    <div className={`${s.container} ${clasName}`}>
      <Typography.Title className={s.title} level={2}>
        {step && <span>{step}</span>} {title}
      </Typography.Title>
      <p className={s.desc}>{description}</p>
    </div>
  );
};
