import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { LotsListProps as BaseLotsListProps } from 'components/lot/types';
import { ListModel } from 'models/ListModel';
import { LotModel } from 'models/LotModel';
import { LotListPagination } from 'components/lot/LotsListPagination';
import LotCardSmall, { LotCardSmallProps } from 'components/lot/LotCardSmall/LotCardSmall';
import { Tooltip } from 'antd';
import s from './LostList.module.scss';
import { useLotListProfileHistoryStore } from 'stores/LotListProfileHistoryStore/context';
import { LotCard } from 'components/lot/LotCard';
import { useWidth } from 'hooks/useWidth';

type LotsListProps = {
  className?: string;
  lots: ListModel<LotModel, number>;
  emptyText: string;
};

const TooltipActions = observer(
  ({
    setOpen,
    onDelete,
  }: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onDelete: (e: React.MouseEvent) => void;
  }) => {
    return (
      <div className={s.tooltip}>
        <div className={s.tooltip__header}>Подтвердить удаление?</div>
        <div className={s.tooltip__actions}>
          <button onClick={onDelete}>Да</button>
          <button onClick={() => setOpen(false)}>Нет</button>
        </div>
      </div>
    );
  },
);

const LotCardItem = observer((props: LotCardSmallProps) => {
  const { deleteFetchHistoryItem } = useLotListProfileHistoryStore();
  const [open, setOpen] = React.useState(false);
  const { width = window.innerWidth } = useWidth();
  const handleDeleteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(false);
      deleteFetchHistoryItem(props.item.id);
    },
    [props.item],
  );

  return (
    <div className={s.favorite__card}>
      <div className={s.favorite__card__actions}>
        <Tooltip
          color={'#fff'}
          rootClassName={s.tooltipWrap}
          open={open}
          title={<TooltipActions setOpen={setOpen} onDelete={handleDeleteClick} />}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpen((prev) => !prev)}
          >
            <g clip-path="url(#clip0_585_511)">
              <rect width="22" height="22" fill="white" fillOpacity="0.01" />
              <path
                d="M11 1.37512C5.68477 1.37512 1.375 5.68489 1.375 11.0001C1.375 16.3154 5.68477 20.6251 11 20.6251C16.3152 20.6251 20.625 16.3154 20.625 11.0001C20.625 5.68489 16.3152 1.37512 11 1.37512ZM14.5535 14.6568L13.1355 14.6503L11 12.1044L8.8666 14.6482L7.44648 14.6546C7.35195 14.6546 7.27461 14.5794 7.27461 14.4827C7.27461 14.4419 7.28965 14.4032 7.31543 14.371L10.1105 11.0409L7.31543 7.71301C7.28947 7.68152 7.27506 7.6421 7.27461 7.60129C7.27461 7.50676 7.35195 7.42942 7.44648 7.42942L8.8666 7.43586L11 9.98176L13.1334 7.43801L14.5514 7.43157C14.6459 7.43157 14.7232 7.50676 14.7232 7.60344C14.7232 7.64426 14.7082 7.68293 14.6824 7.71516L11.8916 11.0431L14.6846 14.3732C14.7104 14.4054 14.7254 14.4441 14.7254 14.4849C14.7254 14.5794 14.648 14.6568 14.5535 14.6568Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_585_511">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      </div>
      {width >= 1280 ? (
        <LotCard {...props} className={s.favorite__card__bg} />
      ) : (
        <LotCardSmall {...props} className={s.favorite__card__bg} />
      )}
    </div>
  );
});

const LotsList = ({ className, lots, emptyText }: LotsListProps): React.ReactElement<LotsListProps> => {
  const store = useLotListProfileHistoryStore();
  const { width = window.innerWidth } = useWidth();
  const isMobile = React.useMemo(() => {
    return width < 1280;
  }, [width]);
  const props = React.useMemo<BaseLotsListProps<LotModel>>(() => {
    return {
      className: className,
      lots: lots,
      emptyText: emptyText,
      CardComponent: LotCardItem,
    };
  }, [emptyText, store.filters.setPage, lots]);

  return <LotListPagination {...props} grid={isMobile} />;
};

export default observer(LotsList);
