import * as React from 'react';

import ImgCarousel, { CarouselImageSize } from 'components/ImgCarousel';
import { ImageModel } from 'models/ImageModel';
import { useWidth } from 'hooks/useWidth';

type ImageGalleryProps = {
  images: ImageModel[];
  className?: string;
  showBullets?: boolean;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;
  imgSize?: CarouselImageSize;
};

const ImageGallery = ({
  images,
  className,
  showBullets,
  onTouchEnd,
  onTouchStart,
  imgSize,
}: ImageGalleryProps): React.ReactElement<ImageGalleryProps> => {
  const { width = window.innerWidth } = useWidth();
  const size = React.useMemo(() => {
    if (imgSize) {
      return imgSize;
    }
    return width >= 768 ? CarouselImageSize.s : CarouselImageSize.sm;
  }, [width, imgSize]);
  return (
    <ImgCarousel
      className={className}
      images={images}
      imgSize={size}
      showBullets={showBullets}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      withoutThumbnails
      withoutFullscreen
    />
  );
};

export default React.memo(ImageGallery);
