import cn from 'classnames';
import * as React from 'react';

import door from 'icons/door.svg';
import ladder from 'icons/ladder.svg';
import stairs from 'icons/stairs.svg';

import { MetaItem } from './MetaItem';

import s from './Meta.module.scss';

type MetaProps = {
  floor: string | null;
  totalFloors: string | null;
  entranceCount: number | null;
  className?: string;
  similar?: boolean;
};

const Meta = ({
  floor,
  totalFloors,
  entranceCount,
  className,
  similar = false,
}: MetaProps): React.ReactElement<MetaProps> | null => {
  if (floor === null && totalFloors === null && entranceCount === null) {
    return null;
  }

  return (
    <div className={cn(s.meta, className)}>
      {floor && <MetaItem icon={stairs} text={floor} alt="Этаж" />}
      {totalFloors && <MetaItem icon={ladder} text={totalFloors} alt="Этажей всего" />}
      {entranceCount && <MetaItem icon={door} text={entranceCount} alt="" />}
    </div>
  );
};

export default React.memo(Meta);
