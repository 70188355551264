import { UserServer, IUser } from 'entities/user';

export class UserModel implements IUser {
  readonly phone: string;
  readonly userId: number;
  readonly email: string | null;
  readonly firstName;
  readonly lastName;
  readonly patronymic;
  readonly userType;
  readonly region;

  constructor(user: IUser) {
    this.phone = user.phone;
    this.userId = user.userId;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.patronymic = user.patronymic;
    this.userType = user.userType;
    this.region = user.region;
  }

  get hasEmail(): boolean {
    return Boolean(this.email);
  }

  static fromJson(raw: UserServer) {
    return new UserModel({
      phone: raw?.phone || '',
      userId: raw.user_id,
      email: raw?.email || '',
      firstName: raw?.first_name || '',
      lastName: raw?.last_name || '',
      patronymic: raw?.patronymic || '',
      userType: raw?.user_type || '',
      region: raw?.region || '',
    });
  }
}
