import { Button, Checkbox, Empty, Pagination } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import s from './PageNotificationList.module.scss';
import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import SortItemCustom from 'components/SortItem/SortItemCustom';
import { ProfileNotificationListItem } from '../ProfileNotificationListItem/ProfileNotificationListItem';
import { useNotificationStore } from 'stores/RootStore/context';
import { getCurrentPage } from 'utils/page';

const PageNotificationList = ({ className }: { className?: string }) => {
  const {
    sortDirectionDate,
    sortDirectionTime,
    notifications,
    selected,
    toggleSelected,
    setAllSelected,
    resetSelected,
    fetchNotificationItems,
    offset,
    fetchReadNotification,
    deleteNotification,
    loadingNotificationDeleteStage,
    loadingNotificationReadStage,
  } = useNotificationStore();
  const [checkedAll, setCheckedAll] = React.useState(false);

  const isDisabled = React.useMemo(() => {
    if (selected.length === 0) {
      return true;
    } else if (loadingNotificationDeleteStage.isLoading || loadingNotificationReadStage.isLoading) {
      return true;
    }

    return false;
  }, [selected.length, loadingNotificationDeleteStage.isLoading, loadingNotificationReadStage.isLoading]);

  const onSetRead = (id: number) => {
    fetchReadNotification([id]);
  };

  const onSetReadSelected = () => {
    fetchReadNotification(selected as number[]).then(() => {
      setCheckedAll(false);
      resetSelected();
    });
  };

  const onDeleteSelected = () => {
    deleteNotification(selected as number[]).then(() => {
      setCheckedAll(false);
      resetSelected();
    });
  };

  React.useEffect(() => {
    fetchNotificationItems({ replace: true });
  }, []);

  if (
    notifications.items.length === 0 &&
    !notifications.isInitialLoading.value &&
    !notifications.isReplaceLoading.value
  ) {
    return <Empty className={s['lots-list__empty']} description={'Уведомлений нет'} />;
  }

  return (
    <div>
      <div className={`${s.header} ${className}`}>
        <div className={s.header__left}>
          <Checkbox
            checked={checkedAll}
            onChange={(e) => {
              setCheckedAll(e.target.checked);
              setAllSelected(e.target.checked);
            }}
          >
            Отметить все
          </Checkbox>
          <Button className={s.btn__read__all} disabled={isDisabled} onClick={onSetReadSelected}>
            <MailOutlined />
            Отметить как прочитанное
          </Button>
          <SortItemCustom
            id={'sort-date'}
            label={'Дата'}
            sortDirection={sortDirectionDate.value}
            onClick={(_, value) => {
              sortDirectionDate.change(value as 'asc');
            }}
          />
          <SortItemCustom
            id={'sort-time'}
            label={'Время'}
            sortDirection={sortDirectionTime.value}
            onClick={(_, value) => {
              sortDirectionTime.change(value as 'asc');
            }}
          />
        </div>
        <div className={s.header__right}>
          <Button className={s.btn__delete} disabled={isDisabled} onClick={onDeleteSelected}>
            <DeleteOutlined />
            <span className={s.btn__delete__label}>Удалить</span>
          </Button>
        </div>
      </div>
      <ul className={s.list}>
        {notifications.items.map((item) => (
          <li key={item.id}>
            <ProfileNotificationListItem
              checked={selected.includes(item.id)}
              onToggleSelect={toggleSelected}
              notification={item}
              onSetRead={onSetRead}
            />
          </li>
        ))}
      </ul>
      {notifications.total.value && notifications.total.value > 10 ? (
        <div className={s.pagination}>
          <Pagination
            current={getCurrentPage(notifications.total.value, offset.value)}
            total={notifications.total.value || 0}
            onChange={(page) => {
              offset.change((page - 1) * 10);
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default observer(PageNotificationList);
